import React, {FunctionComponent} from "react";
import {TaxonomyPlatformItem} from "../../api/model/taxonomy/TaxonomyPlatform";

type PlatformLogoProps = {
    platform: TaxonomyPlatformItem;
    cssClass?: string;
};

const PlatformLogo: FunctionComponent<PlatformLogoProps> = ({platform, cssClass}) => {
    if (!platform) {
        return (<></>);
    } else return (
        <img src={platform.img} alt={`Logo ${platform.name}`} className={cssClass}/>
    );
};

export default PlatformLogo;
