import {Formatter} from "../../../common/utils/Formatter";

export enum SegmentStepSize {
    STEP0,
    STEP1,
    STEP2,
    STEP3,
    STEP4,
    STEP5,
    STEP6,
    STEP7,
    STEP8,
    STEP9,
    STEP10
}

export type SegmentStepSizeItem = {
    value: SegmentStepSize;
    label: string;
    minSize: number | undefined;
}

export const SEGMENT_STEP_SIZES: SegmentStepSizeItem[] = [
    {value: SegmentStepSize.STEP0, label: "", minSize: undefined},
    {value: SegmentStepSize.STEP1, label: "< 20K", minSize: 0},
    {value: SegmentStepSize.STEP2, label: "> 20K", minSize: 20000},
    {value: SegmentStepSize.STEP3, label: "> 50K", minSize: 50000},
    {value: SegmentStepSize.STEP4, label: "> 100K", minSize: 100000},
    {value: SegmentStepSize.STEP5, label: "> 250K", minSize: 250000},
    {value: SegmentStepSize.STEP6, label: "> 500K", minSize: 500000},
    {value: SegmentStepSize.STEP7, label: "> 750K", minSize: 750000},
    {value: SegmentStepSize.STEP8, label: "> 1M", minSize: 1000000},
    {value: SegmentStepSize.STEP9, label: "> 2,5M", minSize: 2500000},
    {value: SegmentStepSize.STEP10, label: "> 5M", minSize: 5000000}
];

export const defaultSegmentStepSizeItem = SEGMENT_STEP_SIZES[0];

export function getSegmentStepSize(stepSize: SegmentStepSize): SegmentStepSizeItem | undefined {
    return SEGMENT_STEP_SIZES.find(((it) => it.value === stepSize));
}

export function getSegmentStepFromValue(value: number): string {
    if (!value) return "-";
    const stepSize = getSegmentStepSize(value as SegmentStepSize);
    return stepSize?.label || Formatter.formatNumber(value);
}
