import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {TreeTaxonomyElement} from "../index";
import {Icon, Loader} from "@sirdata/ui-lib";
import {TaxonomyNode} from "../../../api/model/taxonomy/TaxonomyNode";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {scroller} from "react-scroll";
import {getTaxonomyTopTierIcon} from "../../../api/model/taxonomy/TaxonomyTopTier";

type TreeTaxonomyNodeProps = {
    item: TaxonomyNode;
    filters: TaxonomyFilters;
    onClick: (node: TaxonomyNode) => void;
};

const TreeTaxonomyNode: FunctionComponent<TreeTaxonomyNodeProps> = ({item, filters, onClick}) => {
    const [isLoadingChildren, setLoadingChildren] = useState(false);
    const mountRef = useRef(false);

    const handleClick = async () => {
        if (!item.loaded) setLoadingChildren(true);
        await onClick(item);
        setLoadingChildren(false);
    };

    useEffect(() => {
        if (!mountRef.current) return;
        if (item.expanded) {
            scroller.scrollTo(item.path_element.link, {offset: -100, smooth: true, duration: 500});
        }
    //eslint-disable-next-line
    }, [item.expanded]);

    useEffect(() => {
        mountRef.current = true;
    }, [item]);

    return (
        <div className="taxonomy-tree__item" id={item.path_element.link}>
            <div className={clsx("taxonomy-node", {"taxonomy-node--expanded": item.expanded})}>
                <div className="taxonomy-tree__item__block node__main" onClick={handleClick}>
                    <div className="node__main__content">
                        <Icon {...getTaxonomyTopTierIcon(item.top_path_element)} cssClass="node__main__icon"/>
                        <span className="node__main__name">{item.path_element?.name.split("/").pop()}</span>
                    </div>
                    {!!item.count &&
                    <div className="node__main__content">
                        {isLoadingChildren && <Loader cssClass="taxonomy-tree__loader" small={true}/>}
                        <span className="node__main__count">{item.count}</span>
                        <Icon name="keyboard_arrow_right" cssClass="node__main__arrow"/>
                    </div>
                    }
                </div>
                <div className="node__children">
                    {item.elements?.elements.map((item) =>
                        <TreeTaxonomyElement key={item.elementId} item={item} filters={filters}/>
                    )}
                    {item.children?.map((item) =>
                        <TreeTaxonomyNode key={item.path_element.name} item={item} filters={filters} onClick={onClick}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TreeTaxonomyNode;
