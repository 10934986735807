import {ApiModel} from "../../../common/api/model/ApiModel";
import {TaxonomyElement} from "./TaxonomyElement";

export class TaxonomyPagedElements extends ApiModel {
    page: number = 0;
    size: number = 0;
    total: number = 0;
    elements: TaxonomyElement[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;

        for (const [key, value] of Object.entries(o)) {
            switch (key) {
                case "elements":
                    if (!value) break;
                    const elements: TaxonomyElement[] = [];
                    (value as object[]).forEach((x: object) => {
                        const element = new TaxonomyElement();
                        element.load(x);
                        elements.push(element);
                    });
                    this.elements = elements;
                    break;
                default:
                    this[key] = value;
            }
        }
    }

    getTotalPages(): number {
        return Math.ceil(this.total / this.size);
    }
}
