import React, {FunctionComponent} from "react";
import {Color} from "@sirdata/ui-lib";

const SvgMediaPartners: FunctionComponent = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 386.63 285.74"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="-1580.63"
                    y1="-199.82"
                    x2="-1594.01"
                    y2="-19.26"
                    gradientTransform="matrix(-1, 0, 0, 1, -1467.25, 372.53)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"></stop>
                    <stop offset="0.08" stopOpacity="0.69"></stop>
                    <stop offset="0.21" stopOpacity="0.32"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="-758.73"
                    y1="223.85"
                    x2="-743.74"
                    y2="5.91"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="-746.19"
                    y1="113.84"
                    x2="-724.8"
                    y2="73.49"
                    gradientTransform="translate(-533.58 323.86) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="-748.08"
                    y1="143.53"
                    x2="-713.17"
                    y2="95.95"
                    gradientTransform="translate(-533.57 323.86) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="-788.96"
                    y1="95.66"
                    x2="-756.11"
                    y2="87.22"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="-735.6"
                    y1="250.19"
                    x2="-728.63"
                    y2="33.21"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="-804.58"
                    y1="87.76"
                    x2="-771.73"
                    y2="79.31"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="278.33"
                    y1="77.28"
                    x2="276.08"
                    y2="100.37"
                    gradientTransform="matrix(1, 0, 0, -1, -11.22, 323.86)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopOpacity="0"></stop>
                    <stop offset="0.99"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="248.56"
                    y1="151.16"
                    x2="278.59"
                    y2="62.94"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 352)"
                    xlinkHref="#linear-gradient-8"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="-819.5"
                    y1="183.63"
                    x2="-765.74"
                    y2="122.27"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-11"
                    x1="-1139.19"
                    y1="-188.8"
                    x2="-1122.21"
                    y2="-188.8"
                    gradientTransform="translate(1393.07 360.29)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#e5c5d5"></stop>
                    <stop offset="0.42" stopColor="#ead4d1"></stop>
                    <stop offset="1" stopColor="#f0e9cb"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-12"
                    x1="-853.18"
                    y1="84.81"
                    x2="-767.18"
                    y2="170.81"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-13"
                    x1="-845.08"
                    y1="131.33"
                    x2="-807.55"
                    y2="131.33"
                    gradientTransform="translate(-522.36 352) rotate(180)"
                    xlinkHref="#linear-gradient-8"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-14"
                    x1="218.57"
                    y1="194.14"
                    x2="232.83"
                    y2="194.14"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 352)"
                    xlinkHref="#linear-gradient-11"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-15"
                    x1="-914.44"
                    y1="77.73"
                    x2="-916.69"
                    y2="100.82"
                    gradientTransform="translate(-808.98 323.86) rotate(180)"
                    xlinkHref="#linear-gradient-8"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-16"
                    x1="-941.72"
                    y1="152.69"
                    x2="-911.44"
                    y2="63.76"
                    gradientTransform="translate(-797.76 352) rotate(180)"
                    xlinkHref="#linear-gradient-11"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-17"
                    x1="134.71"
                    y1="111.41"
                    x2="176.76"
                    y2="63.74"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 352)"
                    xlinkHref="#linear-gradient-11"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-18"
                    x1="107.02"
                    y1="88.69"
                    x2="162.02"
                    y2="125.1"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 352)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-19"
                    x1="126.49"
                    y1="116.53"
                    x2="102.84"
                    y2="94.85"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 352)"
                    xlinkHref="#linear-gradient-8"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-20"
                    x1="-2330.59"
                    y1="-189.28"
                    x2="-2314.98"
                    y2="-189.28"
                    gradientTransform="matrix(-1, 0, 0, 1, -2190.83, 360.29)"
                    xlinkHref="#linear-gradient-11"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-21"
                    x1="339.55"
                    y1="85.29"
                    x2="425.56"
                    y2="171.29"
                    gradientTransform="matrix(1, 0, 0, -1, -275.41, 352)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-22"
                    x1="347.66"
                    y1="131.79"
                    x2="385.15"
                    y2="131.79"
                    gradientTransform="matrix(1, 0, 0, -1, -275.41, 352)"
                    xlinkHref="#linear-gradient-8"
                >
                </linearGradient>
            </defs>
            <path
                d="M244.59,31.16c14.83,3.2,29.63,7.72,42.32,16,34.5,22.58,46.41,67.32,73.85,98.09,12,13.42,27.33,24.79,33.68,41.61,5.05,13.36,3.6,28.22,1.29,42.31a385.86,385.86,0,0,1-8.38,38.61c-2.12,7.82-4.55,15.7-9.09,22.4-13.61,20.09-41.36,23.9-65.63,23.66a408.51,408.51,0,0,1-73.91-7.49c-12.2-2.38-24.35-5.32-36.75-6.2-26.93-1.89-53.43,6-80,10.64s-55.71,5.64-78.71-8.47A68.19,68.19,0,0,1,11.53,251.5c-1.43-15.05,2.25-30.09,6.21-44.69q7.65-28.26,16.82-56.09c4.71-14.31,9.78-28.63,17.52-41.56C86.7,51.37,181.4,17.5,244.59,31.16Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M126.32,243.69q13.94,2.15,27.81,4.65c6.1,1.1,12.35,2.31,17.61,5.58,1.82,1.13,3.58,2.69,3.84,4.79.29,2.33-1.48,4.51-3.61,5.49a16.37,16.37,0,0,1-6.9,1l-60.78.37c-12.88.08-26.92-.23-36.9-8.38a10.81,10.81,0,0,1-3.76-4.89,5,5,0,0,1,1.59-5.61,7.64,7.64,0,0,1,3.74-.94l27.49-2.13"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient)"
            >
            </path>
            <path
                d="M116.87,238.85c5.13-1.16,4.12,6.1,5.47,8.8a15,15,0,0,0,3.16,3.63,6.51,6.51,0,0,1,2.31,4.12,1.56,1.56,0,0,1-.18.87,1.67,1.67,0,0,1-1,.61,11.75,11.75,0,0,1-8.76-.86,6.8,6.8,0,0,1-3-2.79,9.59,9.59,0,0,1-.78-4.13C114,246.07,112.61,239.8,116.87,238.85Z"
                transform="translate(-11.22 -28.14)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M121.4,253.8s14.57.62,8.4,5.19-15.38-1.11-16.28-2.16-.16-2.83-.16-2.83Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M114.16,255.81c1.3,1.58,8.14,3.7,11.17,3.49s5.75-1.63,5.45-3.59-5.65-6.52-6.65-6.5-1.81,2.33-3.83,2.82a5.4,5.4,0,0,1-4.93-1.77,1.36,1.36,0,0,0-1.66-.43A6.47,6.47,0,0,0,114.16,255.81Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M99.31,241.72c5.13-1.19,4.12,6.06,5.47,8.76a14.25,14.25,0,0,0,3.16,3.63,6.48,6.48,0,0,1,2.3,4.13,1.55,1.55,0,0,1-.17.87,1.67,1.67,0,0,1-1,.61,11.87,11.87,0,0,1-8.77-.86,7,7,0,0,1-3-2.79,9.59,9.59,0,0,1-.78-4.13C96.39,248.88,95.05,242.63,99.31,241.72Z"
                transform="translate(-11.22 -28.14)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M103.84,256.59s14.57.62,8.4,5.2S96.86,260.68,96,259.63s-.17-2.83-.17-2.83Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M96.6,258.58c1.3,1.59,8.14,3.71,11.17,3.49s5.75-1.62,5.45-3.58-5.65-6.52-6.65-6.51-1.81,2.34-3.83,2.82a5.41,5.41,0,0,1-4.93-1.8,1.36,1.36,0,0,0-1.66-.42A6.45,6.45,0,0,0,96.6,258.58Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M112.51,175.64l-6.84,72-9.87-1.22c-3.9-17.58-2.94-70.16-2.94-70.16Z"
                transform="translate(-11.22 -28.14)"
                fill="#473f47"
            >
            </path>
            <path
                d="M105.67,173.69l6.84,72,9.89-1.22c5.65-45.58,1-73.95,1-73.95Z"
                transform="translate(-11.22 -28.14)"
                fill="#473f47"
            >
            </path>
            <path
                d="M122.52,147.64c.13-1.32.48-2.63.62-4a29.81,29.81,0,0,0-.16-8.17,31.12,31.12,0,0,0-2.57-8.32c-1.14-2.28-3.2-3.25-5.51-4.39a19.6,19.6,0,0,0-6.21-2.11,15.72,15.72,0,0,0-4.59.25c-4.65.89-10.78,3.29-14,7-2.38,2.76-2.66,6.7-2.74,10.35-.1,5,0,10.06.31,15.09,0,.73.09,1.46.15,2.18.42,5.52.88,11.24,2.45,16.57.49,1.67,1,3.35,2.56,4.24,3.63,2.12,9.2.95,13.13.41,5.22-.74,10.57-1.52,15.2-4.05a4.37,4.37,0,0,0,2.19-2.11c.41-1.14-.08-2.36-.43-3.52-1.57-5.14-.36-10.55-.36-15.8,0-.95-.12-1.93-.12-2.88A3.89,3.89,0,0,0,122.52,147.64Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M113.18,125.49a34.76,34.76,0,0,1-.2,5.3c0,1.62.15,3.23.07,4.84a14,14,0,0,1-2.72,7.56l12.8-2.69a1,1,0,0,0,.68-.35,1,1,0,0,0,0-.73l-1.11-5a19.73,19.73,0,0,0-2.55-7.09,15.24,15.24,0,0,0-5.44-4.48c-.48-.25-2.11-1.43-2.57-.64C112,122.55,113.11,124.9,113.18,125.49Z"
                transform="translate(-11.22 -28.14)"
                fill="#020202"
                opacity="0.1"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M119.7,138a4.52,4.52,0,0,1,4.31-.26,4.14,4.14,0,0,1,2.27,3.62,5.54,5.54,0,0,1-1,2.85,4.4,4.4,0,0,1-1.26,1.42,4.32,4.32,0,0,1-2.74.57,5.78,5.78,0,0,1-2.44-.67c-1.12-.63-2.58-2.43-2.2-3.84A8.23,8.23,0,0,1,119.7,138Z"
                transform="translate(-11.22 -28.14)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M101.83,171.52a16.78,16.78,0,0,0,1.16-6.18,35.09,35.09,0,0,0-.91-5.94l-3.33-15.88c-.61-2.91-1.93-17-6.37-15.35A9.18,9.18,0,0,0,87.17,134a24,24,0,0,0-.93,8c.08,7,1.47,14.16,2.37,21.11.34,2.63.75,12.4,4.23,13.26.68.18,1.35.52,2,.64,1.17.21,4.68,1.61,6.21,1.15a.94.94,0,0,0,.6-.45c.9-1.77-.55-5.25-.55-7.24a13.53,13.53,0,0,1,3.71-8.67,38.89,38.89,0,0,1,8.28-6.5l4.09-2.65a4.29,4.29,0,0,1,2.3-.9,9,9,0,0,0,1.72.16c.89-.15,1.42-1.07,1.74-1.91a15.83,15.83,0,0,0,1.08-6.35,1,1,0,0,0-.27-.77,1,1,0,0,0-.93,0l-12.86,4a13.22,13.22,0,0,1-6,.9c-2-.33-4-1.91-4-3.94"
                transform="translate(-11.22 -28.14)"
                fill="#020202"
                opacity="0.1"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M88.84,129.31c-4.22,7-5.69,15.24-7.08,23.28a7.58,7.58,0,0,0-.12,3,5.28,5.28,0,0,0,3.8,3.63,15.54,15.54,0,0,0,5.48.29c3.94-.26,8.59-.6,11.93-2.87,1.14-.78,2.16-1.75,3.33-2.5a29.29,29.29,0,0,1,3.54-1.78,40.85,40.85,0,0,0,6.64-3.88,5.14,5.14,0,0,0,1.75-1.78c.68-1.35.15-3-.39-4.39a1.24,1.24,0,0,0-1.4-1.07,1.16,1.16,0,0,0-.51.18,129.26,129.26,0,0,1-17.16,3.84c-1.6.24-.9-1.5-.69-2.41.34-1.27.62-2.55.84-3.85a11.57,11.57,0,0,0-4-10.07,4.86,4.86,0,0,0-2.68-1.27A4.51,4.51,0,0,0,88.84,129.31Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M112.49,113.43a40.34,40.34,0,0,0-.25-4.13c-.13-1.2-.32-2.41-.56-3.59a2.4,2.4,0,0,0-.91-1.82,11.35,11.35,0,0,0-6.16-1.69A5,5,0,0,0,99,106.32a5.12,5.12,0,0,0-.06.78c-.33,3,.46,6,.9,9.05a38.13,38.13,0,0,1,.37,7,8.21,8.21,0,0,0,3.8,1.35,5.91,5.91,0,0,0,1.87-.31,1.36,1.36,0,0,0,.83-.55,1.48,1.48,0,0,0,.07-.78,6.17,6.17,0,0,1-.07-.74h0a22.89,22.89,0,0,1,.08-4.14.47.47,0,0,1,.12-.31.48.48,0,0,1,.29,0h.38a10.13,10.13,0,0,0,2.06,0,3.37,3.37,0,0,0,2.15-1.07A4.46,4.46,0,0,0,112.49,113.43Z"
                transform="translate(-11.22 -28.14)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M111,113.63c-.14-.22-.36-.47-.6-.39s-.26.26-.33.44a6,6,0,0,1-1.2,2,9.44,9.44,0,0,1-1,1l-.68.6a7.35,7.35,0,0,0,1.91.43,2.36,2.36,0,0,0,1.79-.62,3.43,3.43,0,0,0,.62-1,3.5,3.5,0,0,0,.39-1.08,1.19,1.19,0,0,0-.34-1"
                transform="translate(-11.22 -28.14)"
                fill="#de9f93"
            >
            </path>
            <path
                d="M109.73,104.42c1.13-.27,2.75-.39,3.32-1.46a2.6,2.6,0,0,0-.87-3,7,7,0,0,0-6.1-2,18.57,18.57,0,0,1-2.08.39c-.94.05-1.86-.23-2.8-.26a7.24,7.24,0,0,0-4.51,1.72,12.54,12.54,0,0,1-2.69,2,2.49,2.49,0,0,1-3-.6,4.75,4.75,0,0,0,.59,3.93,1.15,1.15,0,0,1-1.47.13,12.41,12.41,0,0,0,1.09,4.64,6.39,6.39,0,0,1-1.8-.1A6.65,6.65,0,0,0,93,113.42c2.2.91,4.65,1,6.26,2.9.2.24.4.81.74.87s.58-.3.68-.62a6.18,6.18,0,0,0-.28-4.11,4.64,4.64,0,0,1-.67-2.1c.07-.74,1.07-1.32,1.55-.88s.56,1.49,1.15,1.28c-.67-2.1.2-4.74,2.23-5.57,1.55-.62,3.26-.25,4.84-.71Z"
                transform="translate(-11.22 -28.14)"
                fill="#dd8049"
            >
            </path>
            <path
                d="M107.52,117.67a23,23,0,0,1-4.65-1.38c-.14,4.86,3.81,5.87,3.81,5.87h0a22.89,22.89,0,0,1,.08-4.14.47.47,0,0,1,.12-.31.48.48,0,0,1,.29,0h.35Z"
                transform="translate(-11.22 -28.14)"
                fill="#de9f93"
            >
            </path>
            <circle cx="179.25" cy="99.49" r="37.95" fill="#fff"></circle>
            <path
                d="M304.48,258.86c-6,2-11.79,4.81-11.63,7.67.18,3.2-24.4,3.77-26.81,3.44.45-1,.07-2.67.49-3.66A215.21,215.21,0,0,1,240,258.23c-11.41-4.25-25-4.85-37-6-26.79-2.6-54.21-2.11-80.26,5.3a79.18,79.18,0,0,0-16.35,6.33,8.3,8.3,0,0,0-3.68,3.18c-1.46,2.84.45,6.28,2.84,8.39,3.69,3.27,8.49,5,13.24,6.34a131.72,131.72,0,0,0,47.93,4.58c7-.62,14.15-1.81,21.11-.62s13.27,4.66,20.08,6.46,15,1.52,19.76-3.62c1.19-1.28,2.16-2.84,3.69-3.68,1.91-1.06,4.26-.76,6.44-.46a235.77,235.77,0,0,0,61.44.44c1.67-.21,3.51-.53,4.55-1.86.77-1,.93-2.38,1.77-3.32,1.12-1.27,3-1.34,4.73-1.33a221.55,221.55,0,0,0,30.13-1.91c2.64-.35,5.37-.78,7.59-2.26s3.76-4.31,2.92-6.84a8.43,8.43,0,0,0-3.18-3.78c-8.72-6.64-20.15-9.23-31-7.83A80.81,80.81,0,0,0,304.48,258.86Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-2)"
            >
            </path>
            <path
                d="M195.52,260.81c0,2.32,7.92,4.2,17.69,4.2s17.68-1.88,17.68-4.2a16.37,16.37,0,0,0,0-2.62c-1.26-2-8.8-1.59-17.68-1.59-8.55,0-16-.33-17.69,1.59A10.94,10.94,0,0,0,195.52,260.81Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <ellipse
                cx="201.99"
                cy="230.05"
                rx="17.68"
                ry="4.21"
                fill="#6ed216"
            >
            </ellipse>
            <ellipse
                cx="201.99"
                cy="230.05"
                rx="17.68"
                ry="4.21"
                fill="url(#linear-gradient-3)"
            >
            </ellipse>
            <ellipse
                cx="300.43"
                cy="234.89"
                rx="17.68"
                ry="4.21"
                fill="#4c506d"
            >
            </ellipse>
            <rect
                x="199.92"
                y="190.56"
                width="4.1"
                height="39.78"
                fill="#6ed216"
            >
            </rect>
            <rect
                x="199.92"
                y="190.56"
                width="4.1"
                height="13.74"
                fill="url(#linear-gradient-4)"
            >
            </rect>
            <path
                d="M264.3,256.2v6.06H242.62s1.27-4.65,9.86-6.06l2.81-2.29Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M264.3,256.2v6.06H242.62s1.27-4.65,9.86-6.06l2.81-2.29Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-5)"
            >
            </path>
            <rect
                x="230.7"
                y="234.11"
                width="23.37"
                height="1.55"
                fill="#6ed216"
            >
            </rect>
            <rect
                x="298.74"
                y="199.95"
                width="3.4"
                height="31.4"
                fill="#4c506d"
            >
            </rect>
            <path
                d="M294,263c0,2.32,7.92,4.21,17.68,4.21s17.69-1.89,17.69-4.21a19.09,19.09,0,0,0,0-2.62c-1.26-2-8.8-1.59-17.69-1.59-8.54,0-16-.33-17.68,1.59A10.44,10.44,0,0,0,294,263Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <ellipse
                cx="300.43"
                cy="232.27"
                rx="17.68"
                ry="4.21"
                fill="#4c506d"
            >
            </ellipse>
            <path
                d="M306.26,222.37h29.25l7-38a4.56,4.56,0,0,0-3.66-5.3A4.8,4.8,0,0,0,338,179H318a4.56,4.56,0,0,0-4.48,3.72Z"
                transform="translate(-11.22 -28.14)"
                fill="#4c506d"
            >
            </path>
            <path
                d="M298.47,222.37h29.25l7-38a4.56,4.56,0,0,0-3.66-5.3,4.47,4.47,0,0,0-.82-.07h-20a4.56,4.56,0,0,0-4.48,3.72Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <rect
                x="291.94"
                y="188.5"
                width="32.54"
                height="11.46"
                rx="5.73"
                fill="#4c506d"
            >
            </rect>
            <rect
                x="279.06"
                y="188.5"
                width="32.54"
                height="11.46"
                rx="5.73"
                fill="#2f3554"
            >
            </rect>
            <path
                d="M321.54,168.84a9.07,9.07,0,0,0-.67-.72c-3.42-3.28-9-2.93-12.48-2.83a62.4,62.4,0,0,1-9.27-.27l-1.33-.16h-.44c-2.31-.25-5.08-.23-5.85,2.16-.9,2.85-1,6.26-1.41,9.22-1.24,8.84-1.93,17.46-2.3,26.32-.35,7.83,10.06,8.45,15.65,8.45a33.6,33.6,0,0,0,5.67-.34c1.72-.29,3.28-1,5-1.32a4.67,4.67,0,0,0,2.44-1c.95-.92.95-2.43.95-3.75a55.6,55.6,0,0,1,.82-10.06c.31-1.65.79-3.26,1.08-4.9a21.45,21.45,0,0,1,1.36-3.9C323.13,179.75,325,173,321.54,168.84Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M279.51,169.64a6.4,6.4,0,0,1,1-2.11c1.08-1.35,3-1.64,4.72-1.77.81-.07,1.78,0,2.17.69a2.09,2.09,0,0,1,.15,1.26,27.53,27.53,0,0,0-.38,4.44,13.94,13.94,0,0,1,3.65-1.64c.34-.11,3.79-.5,3.84,0,.19,2.14.27,4.29.27,6.43a5.86,5.86,0,0,1-1.37,4.34,55.4,55.4,0,0,1-6.09,7.16,12.91,12.91,0,0,1-9,3.76,3.22,3.22,0,0,1-2.87-1,3.4,3.4,0,0,1-.41-1.9,36.78,36.78,0,0,1,.46-5.64A82.93,82.93,0,0,1,279.51,169.64Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M282.38,173.76c.17-1.24,0-4.14,1-4.91l.18-.1a1.84,1.84,0,0,1,2.46.87,3.84,3.84,0,0,1,.1,1.65,21.4,21.4,0,0,0,.11,4c.1.8.36,1.74,1.13,2A2.15,2.15,0,0,0,289,177a16.92,16.92,0,0,0,3.74-2.46c.25-.21.57-.45.88-.34a1,1,0,0,1,.44.42l8.24,12.72c.69,1.07,1.31,2.67.53,3.52s-2.22.56-3.3.09a24.81,24.81,0,0,1-8.37-6,1.38,1.38,0,0,0-.63-.46,1.3,1.3,0,0,0-.95.37,28.48,28.48,0,0,1-4.77,2.87c-1.25.57-3,1-3.78-.2a3,3,0,0,1-.21-2.09Z"
                transform="translate(-11.22 -28.14)"
                fill="#d8dae1"
            >
            </path>
            <path
                d="M312.71,177a9.54,9.54,0,0,1,2.83-3.92,4.24,4.24,0,0,1,4.61-.48c1.94,1.18,2.1,3.89,1.85,6.15a43.38,43.38,0,0,1-2.1,9.42c-.52,1.51-1.36,3-1.82,4.49-.37,1.23-.42,2.55-.8,3.84a36.94,36.94,0,0,0-1.54,9.85,3,3,0,0,1-.22,1.28.94.94,0,0,1-1.08.52,1.39,1.39,0,0,1-.6-.55c-2.1-2.71-5.77-3.6-9.17-4-4-.49-8-.58-12.05-.82-1.65-.1-3.69-.59-3.95-2.2a2.51,2.51,0,0,1,1.21-2.36,7.17,7.17,0,0,1,2.65-.89c3.58-.67,7.33-1.23,10.31-3.32a7.64,7.64,0,0,1-2.92-1.62c1.92,1.26,5-2.85,6.38-3.9a12,12,0,0,0,3-3.54C310.68,182.52,311.55,179.62,312.71,177Z"
                transform="translate(-11.22 -28.14)"
                fill="#d8dae1"
            >
            </path>
            <path
                d="M302.31,167.56a1.86,1.86,0,0,1,1.29.17,2.7,2.7,0,0,0,1.28.32.37.37,0,0,1,.05.11,2.3,2.3,0,0,1-1.11,3.06h0a3.16,3.16,0,0,0-.69.34,1.8,1.8,0,0,0-.38,1.41c.08,4.15-1.14,8.2-2.1,12.24-1.13,4.7-1.91,9.58-1.19,14.37a2,2,0,0,1,0,.77,1.67,1.67,0,0,1-.33.47L297,203.21c-.63-1-2.08-1.31-2.42-2.42a2.82,2.82,0,0,1-.07-1,75.91,75.91,0,0,1,2.31-13.22A104.35,104.35,0,0,1,301.1,172a1,1,0,0,0,.11-.56A1.27,1.27,0,0,0,301,171C300.41,170,301.37,167.92,302.31,167.56Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M313.14,165.25c-3-.27-5.95-.45-8.92-.55-2-.06-4.58-.58-6.42.19a9.86,9.86,0,0,1,1.33.16,65.22,65.22,0,0,0,9.27.27c3.49-.11,9.06-.46,12.49,2.82C319.06,166,316,165.5,313.14,165.25Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M310.56,156.29a.86.86,0,0,0,1,.1c-.42,2.27-.77,4.26.29,6.49a4,4,0,0,0,2.08,2.15,25.73,25.73,0,0,0-5.73,1.91,9.31,9.31,0,0,1-3.75.79c-.13,0-2.41-.51-2.07-1,1.81-2.66.36-7.07.36-7.07a6.28,6.28,0,0,0,7.63-3.77A.75.75,0,0,0,310.56,156.29Z"
                transform="translate(-11.22 -28.14)"
                fill="#8c6239"
            >
            </path>
            <path
                d="M308.61,139.35s-12.28-.86-12.51,7.68a22.73,22.73,0,0,0,.47,5.27c.12.61.27,1.2.44,1.77,1.58,5.21,5.07,8.73,9.34,7.47C313.8,159.36,318.55,141.58,308.61,139.35Z"
                transform="translate(-11.22 -28.14)"
                fill="#a67c52"
            >
            </path>
            <path
                d="M312.12,153.82a2.67,2.67,0,0,0,.43-3.4,1.44,1.44,0,0,0-.42-.43,1.38,1.38,0,0,0-1.36.06c-.33.18-.77.85-1.18.43a1,1,0,0,1-.19-.87,4.17,4.17,0,0,1,1-2.24A2.22,2.22,0,0,1,309,146a12.11,12.11,0,0,1-3.45.21,2,2,0,0,1-.8-.18c-.59-.29-.83-1-1.36-1.41a3.86,3.86,0,0,0-2.39-.32c-.82,0-1.83-.3-1.95-1.11a3,3,0,0,1-3.92-1.59,2.55,2.55,0,0,1-2.32-.27,2.29,2.29,0,0,1-.34-2.07c.29-1.53,1-3.17,2.46-3.72-.85-.45-.84-1.65-.71-2.6a1.8,1.8,0,0,1,.22-.79c.4-.65,1.32-.63,2-.89s1.23-.8,1.9-1.12,1.63-.29,1.95.37a7.69,7.69,0,0,1,2.86-1,2.67,2.67,0,0,1,2.59,1.24,4,4,0,0,1,1.62-.5,1.35,1.35,0,0,1,1.34.86.38.38,0,0,0,.16.33.36.36,0,0,0,.35,0,4.17,4.17,0,0,1,2.47-.16,2.13,2.13,0,0,1,1.56,1.77,6.65,6.65,0,0,1,2.62,1.46,2.53,2.53,0,0,1,1,2.61,3.72,3.72,0,0,1,.18,5,.93.93,0,0,1,.52,1,2.7,2.7,0,0,1-.49,1.11.67.67,0,0,0-.14.36c0,.16.18.28.3.39a1.63,1.63,0,0,1-.07,2.3,1.38,1.38,0,0,1-.4.28,2.3,2.3,0,0,1,.91,1.7,1.11,1.11,0,0,1-1.32,1.09,2.33,2.33,0,0,1-.33,2.25c-.61.78-1.26.52-2,.86s-1.08,1.44-1.59,2C312.38,154.9,312.26,154.36,312.12,153.82Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <path
                d="M286.17,204.53l-16.84,8.63-14,40.5,8.45,5.2,16.57-33.62s15.46-4.71,19.64-6.08,9.38-14.37,9.38-14.37C290.48,204.66,286.17,204.53,286.17,204.53Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <path
                d="M299.52,205.9l-16.83,8.63-11.77,47.29,9,2.29,13.78-37.52s15.45-4.71,19.63-6.08,3.2-13.22,3.2-13.22C309.3,202.34,299.52,205.9,299.52,205.9Z"
                transform="translate(-11.22 -28.14)"
                fill="#4c506d"
            >
            </path>
            <path
                d="M316.5,167.16c-2.63-.63-9.12,13.15-9.12,13.15l-10.47-10.52-6.24,2.35s9.36,23,17.14,20.82,15.11-18.66,15.11-18.66C323.91,171.47,319.14,167.79,316.5,167.16Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M284.86,160a7.1,7.1,0,0,0-1.76,2.31A15,15,0,0,0,282,165.2a5.83,5.83,0,0,0-.26,2.38,2.57,2.57,0,0,0,1.25,1.94,2.7,2.7,0,0,0,3.08-.81,7,7,0,0,0,1.32-3.12,10.72,10.72,0,0,1,1-3.26,3.32,3.32,0,0,0,.77-1.74C288.93,158.33,285.93,159.12,284.86,160Z"
                transform="translate(-11.22 -28.14)"
                fill="#8c6239"
            >
            </path>
            <path
                d="M295.12,168.3c.48,2.42-2.17,3.39-4.2,2.92a4,4,0,0,1-1.68-.76c-1.17-.93-1.54-2.53-2.31-3.81-.54-.9-1.29-1.65-1.82-2.55a3.1,3.1,0,0,1-.3-3c.66-1.2,2.27-1.45,3.65-1.45a4.47,4.47,0,0,1,2.08.29,4.27,4.27,0,0,1,1.66,1.66A21.41,21.41,0,0,1,295.12,168.3Z"
                transform="translate(-11.22 -28.14)"
                fill="#a67c52"
            >
            </path>
            <path
                d="M112.82,208.21c0,8.55,43.37,15.49,96.87,15.49s96.86-6.94,96.86-15.49c0-.36.15-4.65,0-5-3.38-8.06-45.58-10.49-96.86-10.49-50.16,0-91.91,2.68-96.87,10.49C112.49,203.73,112.82,207.67,112.82,208.21Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M306.55,208.21c0,8.55-43.36,15.49-96.86,15.49s-96.87-6.94-96.87-15.49c0-1-.1-2,0-3a4.43,4.43,0,0,1,.7-1.71,4.48,4.48,0,0,1,4.81-3.09q3.36,0,6.71.14a92,92,0,0,1,9.22.78c2.28.33,4.55.79,6.85.93,4.75.27,9.46-.84,14.21-1a3.71,3.71,0,0,1,1.05.06,5.31,5.31,0,0,1,1.09.48,9.34,9.34,0,0,0,5.57.94c17.23,1.17,34.41,0,51.56-2,14.33-1.67,28.65-3.7,43.07-4.07a187.6,187.6,0,0,1,44.89,4.26c2,.43,4.41,1.39,4.51,3.43C307.13,205.72,305.65,207.1,306.55,208.21Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-6)"
            >
            </path>
            <ellipse
                cx="198.47"
                cy="175.07"
                rx="96.87"
                ry="15.49"
                fill="#6ed216"
            >
            </ellipse>
            <path
                d="M279.93,264.11v6.05H258.25s1.27-4.64,9.86-6.05l2.81-2.29Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M279.93,264.11v6.05H258.25s1.27-4.64,9.86-6.05l2.81-2.29Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-7)"
            >
            </path>
            <rect
                x="246.32"
                y="242.02"
                width="23.37"
                height="1.55"
                fill="#6ed216"
            >
            </rect>
            <path
                d="M312,164a1.7,1.7,0,0,1,1,0c.51.22.73.86,1.25,1.06-1.2,1.09-2.82,1.6-4.19,2.46a18.15,18.15,0,0,0-3.78,3.54c-1.29-.71-1.21-2.72-2.34-3.67a7.94,7.94,0,0,0,2.27-1,11.2,11.2,0,0,0,1.62-.91A12.38,12.38,0,0,1,312,164Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M300.92,164.76a7.43,7.43,0,0,0-1.1,1.21l-1.28,1.63a.65.65,0,0,0-.19.49.46.46,0,0,0,.53.29,4.62,4.62,0,0,0,.62-.23,5.34,5.34,0,0,1,1.44-.08,5.75,5.75,0,0,0,1.72-.45.25.25,0,0,0,.17-.11.31.31,0,0,0,0-.19,4.2,4.2,0,0,1,.26-1.68c.14-.4.41-.86.14-1.27C302.74,163.58,301.43,164.35,300.92,164.76Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <polygon points="261.54 167.27 236.34 168.4 244.07 177.4 271.81 175.07 261.54 167.27" fill="#fff"></polygon>
            <path
                d="M168,195.41c1.41.14,24.78,6.9,24.78,6.9l-13.52,10.41-26.74-7.18Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <rect
                x="263.93"
                y="215.65"
                width="3.4"
                height="31.4"
                fill="#4c506d"
            >
            </rect>
            <rect
                x="263.93"
                y="215.65"
                width="3.4"
                height="31.4"
                fill="url(#linear-gradient-8)"
            >
            </rect>
            <path
                d="M259.16,278.73c0,2.33,7.92,4.21,17.69,4.21s17.68-1.88,17.68-4.21a19.09,19.09,0,0,0,0-2.62c-1.26-2-8.8-1.58-17.68-1.58-8.55,0-16-.33-17.69,1.58A10.44,10.44,0,0,0,259.16,278.73Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <ellipse
                cx="265.63"
                cy="247.97"
                rx="17.68"
                ry="4.21"
                fill="#4c506d"
            >
            </ellipse>
            <path
                d="M261.07,179.65c-11.43-2.65-17.17-16.51-28.33-20.14a1.55,1.55,0,0,0-1.12,0,1.65,1.65,0,0,0-.65.84l-2.55,5.6,15.31,17.75a26.57,26.57,0,0,0,3.84,3.88c3.28,2.5,7.51,3.32,11.57,4.06,2.41.45,4.92.88,7.27.2,3.07-.89,6.6-5.13,5.59-8.57C270.9,179.5,264.22,180.38,261.07,179.65Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M244.28,237.23a13.11,13.11,0,0,0,1,5.39h.1c2-.05,3.92,0,5.88.12a83.21,83.21,0,0,1,9.7,1.36c1.48.28,3,.58,4.44.88s2.82.58,4.23.85c2.68.53,5.26,1,7.76,1.34a23.57,23.57,0,0,0,8.46-6.08c.26-.29.5-.58.74-.87a14.82,14.82,0,0,0,.87-1.21,9.18,9.18,0,0,0,1.54-3.93,6.2,6.2,0,0,0-.06-1.72,8.14,8.14,0,0,0-3.43-4.73l-.53-.38a20,20,0,0,0-12.18-3.55,6.64,6.64,0,0,0-1.09.06,37,37,0,0,0-6.85,1.16c-3.56.91-7.12,2.19-10.63,3.31a28.05,28.05,0,0,0-7.39,3.15,6.36,6.36,0,0,0-1.7,1.61A6,6,0,0,0,244.28,237.23Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M244.59,242.89c.46,2.35.86,4.73,1.22,7.1a167.12,167.12,0,0,1,1.67,16.7,3.4,3.4,0,0,1-.15,1.55c-.59,1.47-2.6,1.54-4.18,1.53s-3.65.63-3.61,2.21c0,1.23,1.36,1.95,2.52,2.35A31.08,31.08,0,0,0,252.88,276a3.3,3.3,0,0,0,1.32-.21c2.49-1.1.52-4,.26-5.68-.38-2.45.57-5.15,1.16-7.49,1.39-5.45,5.07-10.38,4.91-16.18a3.26,3.26,0,0,1,.39-2.14c.69-.85,3.6.52,4.52.71,1.41.29,2.82.58,4.23.85,7.57,1.48,14.57,3.17,22.36,1.72a3.15,3.15,0,0,0,1.3-.45,3,3,0,0,0,.94-1.56,13.7,13.7,0,0,0-.81-10.34c-1.9-3.75-4.91-5.23-8.44-7-4.06-2.06-7.49-3.61-12.18-3.48a6.64,6.64,0,0,0-1.09.06,38.16,38.16,0,0,0-6.87,1.2c-5.72,1.48-18.89,3.56-21.08,10.19-.69,2.09.18,3.77.6,5.8C244.46,242.26,244.52,242.57,244.59,242.89Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M244.59,242.89c.46,2.35.86,4.73,1.22,7.1a167.12,167.12,0,0,1,1.67,16.7,3.4,3.4,0,0,1-.15,1.55c-.59,1.47-2.6,1.54-4.18,1.53s-3.65.63-3.61,2.21c0,1.23,1.36,1.95,2.52,2.35A31.08,31.08,0,0,0,252.88,276a3.3,3.3,0,0,0,1.32-.21c2.49-1.1.52-4,.26-5.68-.38-2.45.57-5.15,1.16-7.49,1.39-5.45,5.07-10.38,4.91-16.18a3.26,3.26,0,0,1,.39-2.14c.69-.85,3.6.52,4.52.71,1.41.29,2.82.58,4.23.85,7.57,1.48,14.57,3.17,22.36,1.72a3.15,3.15,0,0,0,1.3-.45,3,3,0,0,0,.94-1.56,13.7,13.7,0,0,0-.81-10.34c-1.9-3.75-4.91-5.23-8.44-7-4.06-2.06-7.49-3.61-12.18-3.48a6.64,6.64,0,0,0-1.09.06,38.16,38.16,0,0,0-6.87,1.2c-5.72,1.48-18.89,3.56-21.08,10.19-.69,2.09.18,3.77.6,5.8C244.46,242.26,244.52,242.57,244.59,242.89Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-9)"
            >
            </path>
            <path
                d="M233.81,243.51a27.27,27.27,0,0,0-4.39,7.54c-1.37,3.06-9.24,35.86-9.52,35.79l5.95,1.57a157.89,157.89,0,0,0,17.05-31.49c1.29-3.19,1.36-3.49,4.83-4.17,3.05-.59,6.1-1.24,9.12-2a122.17,122.17,0,0,0,17.33-5.44,53.41,53.41,0,0,0,9.75-5,6.93,6.93,0,0,0,1.17-1.19,4.31,4.31,0,0,0,.42-.63,4.94,4.94,0,0,0,.74-2.9,3.12,3.12,0,0,0-.14-.8,6.67,6.67,0,0,0-.89-1.64,7,7,0,0,0-2.15-1.91,14.28,14.28,0,0,0-7.21-2.66c-1.75-.1-3.49.25-5.22.16-1.55-.09-2.62-.94-4-1.15a7.66,7.66,0,0,0-3.12.28,37.2,37.2,0,0,0-6.66,3l-12.49,6.24h-.09l-2.5,1.25c-2.62,1.32-5.62,2.63-7.7,4.75Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M211.11,285.27a6.64,6.64,0,0,0-3.08,1,2.51,2.51,0,0,0-1,2.85,3.49,3.49,0,0,0,1.72,1.56,27.43,27.43,0,0,0,14,2.71,3.15,3.15,0,0,0,1.93-.52,3.29,3.29,0,0,0,.91-1.83,7.39,7.39,0,0,0,.27-3.9c-.8-2.63-3.72-2.06-5.8-2.17A49.57,49.57,0,0,0,211.11,285.27Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M183.66,213.35v3.59h145c.23-1.2.44-2.4.64-3.59Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M183.66,213.35v3.59h145c.23-1.2.44-2.4.64-3.59Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-10)"
            >
            </path>
            <path
                d="M254.94,169.33c-.5.62-1.29,1.29-1,2,.17.39.61.58.95.84a4.45,4.45,0,0,1,1.47,3,10.28,10.28,0,0,0,.8,3.32,2.46,2.46,0,0,0,2.81,1.37c.63-.25,1.22-.88,1.86-.68s.71.74,1,1.17a3.38,3.38,0,0,0,3.4,1.19,9.93,9.93,0,0,0,3.44-1.6,2.67,2.67,0,0,0,1.08-1,1.93,1.93,0,0,0,0-1.3,10.57,10.57,0,0,0-2.21-3.39,15.42,15.42,0,0,1-3.05-6.84c-.31-1.73-.39-3.68-1.61-4.93-1.67-1.7-5.29-1.33-7,.21C254.94,164.28,256.48,167.34,254.94,169.33Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-11)"
            >
            </path>
            <path
                d="M256.37,161.15a2.62,2.62,0,0,0,1.06,1c.87.42,1.63.93,2.48,1.39a3.65,3.65,0,0,1,1.7,2.06c.48,1.6-.22,3.48.44,5,.23.3.73,0,.91-.28s.25-.75.55-1c.51-.37,1.23.11,1.52.67a2.88,2.88,0,0,1-1.24,3.74c.13.5.8.64,1.31.52a5.33,5.33,0,0,1,1.52-.35c1.19.11,1.8,1.77,3,1.83.81,0,1.44-.72,1.91-1.4l1.26-1.79a6.2,6.2,0,0,1,.66-.95c.3-.29.83-.36,1.06-.69s-.13-1,.14-1.43a1.38,1.38,0,0,1,.47-.36,2.07,2.07,0,0,0,.8-2.45.57.57,0,0,1-.08-.34c0-.16.21-.25.35-.34a2.22,2.22,0,0,0,.53-2.82,6.71,6.71,0,0,0-2.19-2.21c-1.84-1.36-3.76-2.94-4.3-5.16a6.36,6.36,0,0,0-.5-1.75,1.18,1.18,0,0,0-1.54-.52,9,9,0,0,0-.24-3.47,2,2,0,0,0-.35-.76,1.47,1.47,0,0,0-1.43-.4,4.33,4.33,0,0,0-1.4.68c0-.56-.74-.78-1.3-.76a6.17,6.17,0,0,0-5.18,3.19,2,2,0,0,1-.3.46,2.88,2.88,0,0,1-.78.45,3.3,3.3,0,0,0-1.61,2.34,9.66,9.66,0,0,0,0,2.94,9.36,9.36,0,0,0,.42,2.22A3,3,0,0,0,256.37,161.15Z"
                transform="translate(-11.22 -28.14)"
                fill="#dd8049"
            >
            </path>
            <path
                d="M267.63,177.9a33.78,33.78,0,0,0-3.6,2.3,2.89,2.89,0,0,0-.87,1,1.39,1.39,0,0,0,0,1.34c.4.57,1.15.27,1.73,0a22.6,22.6,0,0,1,8.44-2,1.07,1.07,0,0,0,1-.54,1.42,1.42,0,0,0-.23-1.18C272.31,175.81,269.93,176.53,267.63,177.9Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M262.54,215.36c-2-4.15-3-8.45-4.43-12.78-3.29,2.57-3.62,7.27-6.6,10.41a6.44,6.44,0,0,1-1.5,1.25c-2,1.14-4.86.64-7.06.49a4.9,4.9,0,0,1-3-1.32,3.84,3.84,0,0,1-.8-2.3c-.18-2.85,1.44-5.46,3-7.84l10.79-16.53a5.27,5.27,0,0,1,3.86-2.81,180.35,180.35,0,0,1,20.77-4.86,10.43,10.43,0,0,1,3.2-.2c3,.44,5,3.27,6.47,5.89,7.9,13.69,13.5,29.24,12.62,45a9.4,9.4,0,0,1-.4,2.52,7.33,7.33,0,0,1-2.45,3.06c-3.52,2.78-8.16,3.82-12.64,3.86-6.7.06-15.53-2-20.53-6.77-2.82-2.72,0-4.36,1.36-7C266.89,222,264.06,218.43,262.54,215.36Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M295.92,204.27a31.94,31.94,0,0,0-2.05-6.1C290,201,287.1,206.39,285.66,211s-2.62,9.38-5.56,13.17l-3.18-1.1-.59,2c-.46,1.59.87,3.21.73,4.81a4.35,4.35,0,0,1-3.26,3.42,10.92,10.92,0,0,1-5.61-.4,8.34,8.34,0,0,1-3.27-1.43,3.11,3.11,0,0,1-1.19-3.17,2,2,0,0,0-.87,2.31,4.51,4.51,0,0,0,1.52,2.15c2.22,2,5,3,7.8,4.08a40.18,40.18,0,0,0,4,1.38,41.4,41.4,0,0,0,9.61,1c2,.05,3.13-.26,4-2.19,1.24-2.77,1.33-5.89,1.63-8.91A158.4,158.4,0,0,1,295.92,204.27Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-12)"
            >
            </path>
            <path
                d="M285.23,233.23c0,.86,0,1.74.06,2.63s.12,1.79.23,2.71a4.44,4.44,0,0,0,.08.61c.08.63.18,1.26.29,1.91a.09.09,0,0,1,0,.07l.21,1.08a10.53,10.53,0,0,0,.72,5.79h0a52.86,52.86,0,0,0,5.34-.08,45.3,45.3,0,0,0,10.2-1.82h0a40.73,40.73,0,0,0,9.32-6.56,6.84,6.84,0,0,0,1.8-2.4,8.07,8.07,0,0,0,.3-3.09A58.38,58.38,0,0,1,316,217c.35-1.21.74-2.41,1.16-3.59.48-1.35,1-2.67,1.6-4,1.37-3.09,3-6.1,3.65-9.41a15.76,15.76,0,0,0,.06-5.48,1.51,1.51,0,0,0-.35-.88,1.54,1.54,0,0,0-1.14-.31c-4.17.11-8,2.5-12.08,3.2-2.11.36-4.31.26-6.36.87a13.39,13.39,0,0,0-6.95,5.53,31,31,0,0,0-2.43,4.17c-1.07,2.18-2.07,4.26-3,6.28-.54,1.21-1.05,2.41-1.52,3.59a49.3,49.3,0,0,0-3.18,11.62v.07A35.69,35.69,0,0,0,285.23,233.23Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M285.23,233.23c0,.86,0,1.74.06,2.63s.12,1.79.23,2.71a4.44,4.44,0,0,0,.08.61c.08.63.18,1.26.29,1.91a.09.09,0,0,1,0,.07l.21,1.08a10.53,10.53,0,0,0,.72,5.79h0a52.86,52.86,0,0,0,5.34-.08,45.3,45.3,0,0,0,10.2-1.82h0a40.73,40.73,0,0,0,9.32-6.56,6.84,6.84,0,0,0,1.8-2.4,8.07,8.07,0,0,0,.3-3.09A58.38,58.38,0,0,1,316,217c.35-1.21.74-2.41,1.16-3.59.48-1.35,1-2.67,1.6-4,1.37-3.09,3-6.1,3.65-9.41a15.76,15.76,0,0,0,.06-5.48,1.51,1.51,0,0,0-.35-.88,1.54,1.54,0,0,0-1.14-.31c-4.17.11-8,2.5-12.08,3.2-2.11.36-4.31.26-6.36.87a13.39,13.39,0,0,0-6.95,5.53,31,31,0,0,0-2.43,4.17c-1.07,2.18-2.07,4.26-3,6.28-.54,1.21-1.05,2.41-1.52,3.59a49.3,49.3,0,0,0-3.18,11.62v.07A35.69,35.69,0,0,0,285.23,233.23Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-13)"
            >
            </path>
            <path
                d="M228.29,155.63c-2.24-1.9-4.56-3.85-7.36-4.77a1.27,1.27,0,0,0-1.17,0,1,1,0,0,0-.33.6,3.28,3.28,0,0,0,1,2.73,15.16,15.16,0,0,1,1.94,2.29,7.13,7.13,0,0,0-3.15.14,1,1,0,0,0-.53.29.84.84,0,0,0,.05.85,4.51,4.51,0,0,0,3.68,2,1.52,1.52,0,0,1,.77.24c.49.41.17,1.2.26,1.82a2,2,0,0,0,1.13,1.36c.54.28,1.15.42,1.72.66A8.63,8.63,0,0,0,229,165c1.89.08,3.78-2.43,3.8-4.21C232.84,158.85,229.6,156.74,228.29,155.63Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-14)"
            >
            </path>
            <rect
                x="105.24"
                y="215.21"
                width="3.4"
                height="31.4"
                fill="#4c506d"
            >
            </rect>
            <rect
                x="105.24"
                y="215.21"
                width="3.4"
                height="31.4"
                fill="url(#linear-gradient-15)"
            >
            </rect>
            <path
                d="M135.84,278.29c0,2.32-7.92,4.2-17.69,4.2s-17.68-1.88-17.68-4.2a19.09,19.09,0,0,1,0-2.62c1.26-2,8.8-1.59,17.68-1.59,8.55,0,16-.33,17.69,1.59A10.44,10.44,0,0,1,135.84,278.29Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <ellipse
                cx="106.93"
                cy="247.53"
                rx="17.68"
                ry="4.21"
                fill="#4c506d"
            >
            </ellipse>
            <path
                d="M150.72,236.78a13.11,13.11,0,0,1-1,5.39h-.1c-2,0-3.91,0-5.87.12a83.68,83.68,0,0,0-9.71,1.36c-1.48.28-3,.58-4.43.88l-4.24.86c-2.68.52-5.26,1-7.76,1.33a23.87,23.87,0,0,1-8.46-6.08l-.74-.87c-.3-.38-.59-.78-.86-1.2a9.07,9.07,0,0,1-1.55-3.93,5.84,5.84,0,0,1,.06-1.72,8.16,8.16,0,0,1,3.43-4.74l.53-.38a20,20,0,0,1,12.18-3.55,6.64,6.64,0,0,1,1.09.06,37.12,37.12,0,0,1,6.86,1.16c3.55.91,7.11,2.19,10.62,3.31a28.42,28.42,0,0,1,7.39,3.16,6.17,6.17,0,0,1,1.7,1.6A6,6,0,0,1,150.72,236.78Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M150.42,242.44c-.47,2.36-.87,4.73-1.23,7.11-.49,3.27-.7,6.6-1.24,9.85s-.42,6.47-1,9.67a18.75,18.75,0,0,0-.42,3.66,15.55,15.55,0,0,0,1.28,4.92c-.09.21-.4.19-.63.13a16.5,16.5,0,0,1-3.74-1.33,5.7,5.7,0,0,1-2.69-2.83c-.71-1.83-.08-3.76-.16-5.64a29.2,29.2,0,0,0-1.24-5.84c-1.39-5.45-5.06-10.38-4.91-16.17a3.17,3.17,0,0,0-.39-2.15c-.69-.85-3.6.53-4.51.71l-4.24.86c-7.57,1.47-14.56,3.16-22.35,1.72a3.42,3.42,0,0,1-1.31-.46,3,3,0,0,1-.94-1.56,13.84,13.84,0,0,1,.81-10.34c1.9-3.74,4.91-5.23,8.44-7,4.06-2,7.49-3.61,12.18-3.48a6.64,6.64,0,0,1,1.09.06,38.11,38.11,0,0,1,6.88,1.2c5.71,1.48,18.88,3.56,21.07,10.19.69,2.1-.17,3.77-.59,5.81C150.54,241.82,150.48,242.13,150.42,242.44Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-16)"
            >
            </path>
            <path
                d="M140.27,272.7a.84.84,0,0,1,.23-.18c.36-.15.69.26.9.6a8,8,0,0,0,3.78,3.23.89.89,0,0,0,.62.08c.23-.09.35-.35.54-.52a1.09,1.09,0,0,1,1-.18,3.52,3.52,0,0,1,.95.52,18.69,18.69,0,0,1,2.53,2c.4.4.79,1,.55,1.5a1.3,1.3,0,0,1-.71.57,5,5,0,0,1-3.35-.12c-2-.66-3.79-1.89-5.75-2.66a.91.91,0,0,0-.43-.1,2,2,0,0,0-.33.08,1.18,1.18,0,0,1-.87-.56A3.1,3.1,0,0,1,140.27,272.7Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M159.83,243.63a18.19,18.19,0,0,1,1.21,7c0,6.12-1.7,12.1-3.36,18-.7,2.48-1.29,5-2.11,7.49-.6,1.78-1.4,3.1-.25,4.95a12.32,12.32,0,0,1,.69,1.08,1.12,1.12,0,0,1-.14,1.2c-.45.41-1.16.14-1.72-.08a35.13,35.13,0,0,1-4-1.41c-2.46-1.39-1.51-3.15-1.12-5.28.48-2.57.8-5.2,1.07-7.8a53.05,53.05,0,0,0-.4-15.91,3.34,3.34,0,0,0-.71-1.7,3.18,3.18,0,0,0-1.78-.72c-9-1.59-17.8-2.07-26.39-5.55a53.32,53.32,0,0,1-9.74-5,6.33,6.33,0,0,1-1.18-1.19c-.15-.2-.29-.41-.42-.62a5.13,5.13,0,0,1-.74-2.91,4.32,4.32,0,0,1,.14-.8,7.2,7.2,0,0,1,.9-1.63,7.05,7.05,0,0,1,2.15-1.92,14.43,14.43,0,0,1,7.2-2.66c1.75-.09,3.49.26,5.22.16,1.55-.08,2.62-.94,4-1.15a7.66,7.66,0,0,1,3.12.28,37.92,37.92,0,0,1,6.66,3l7.75,3.87c3.59,1.79,8,3,11.15,5.49A9.28,9.28,0,0,1,159.83,243.63Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-17)"
            >
            </path>
            <path
                d="M151.64,230.58a23.07,23.07,0,0,1,10.93,19.2,54.39,54.39,0,0,1-21.5,9.74,4.84,4.84,0,0,1-2.72,0,5.75,5.75,0,0,1-1.89-1.54c-7.14-7.33-19.71-6.6-27.21-13.57-2.84-2.64-4.69-7.19-2.45-10.36a10.49,10.49,0,0,1,3.33-2.7c5.83-3.47,12.7-8.66,19.75-7.46S145.55,226.91,151.64,230.58Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M154.39,255.22c-3.57-3.81-4.23-9.43-4.71-14.64a12.92,12.92,0,0,1-3.41,2.15l-.61-1.62a.52.52,0,0,0-.57-.45.54.54,0,0,0-.21.08,50.71,50.71,0,0,1-15.78,2.64c-2,0-4.23-.15-5.91-1.33s-2.55-3.72-1.32-5.36a91.2,91.2,0,0,1-13.21,2.57,1.38,1.38,0,0,0-1,.38c-.34.44,0,1.08.29,1.53a23.81,23.81,0,0,0,15,9.57c1.69.31,10.65,3.65,14.67,8.9a2.32,2.32,0,0,0,2.35.75Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-18)"
            >
            </path>
            <path
                d="M100.37,240.85c-1.35,0-2.92.22-3.61,1.37a2.47,2.47,0,0,0,0,2.36,4,4,0,0,0,1.78,1.65,10.75,10.75,0,0,0,3,.86c4.29.81,8.58,1.62,12.88,2.38,2.17.38,4.36.75,6.54,1.09,1.74.27,3.34,1.12,5.11,1.46s4.12.58,5.6-.68a3.51,3.51,0,0,0,1.16-3.28,2.07,2.07,0,0,0-.54-1.12,2.92,2.92,0,0,0-.78-.51,39.7,39.7,0,0,0-9.89-2.9l-7-1.41a41.72,41.72,0,0,0-6.47-1.23C105.56,240.79,103,240.78,100.37,240.85Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M100.37,240.85c-1.35,0-2.92.22-3.61,1.37a2.47,2.47,0,0,0,0,2.36,4,4,0,0,0,1.78,1.65,10.75,10.75,0,0,0,3,.86c4.29.81,8.58,1.62,12.88,2.38,2.17.38,4.36.75,6.54,1.09,1.74.27,3.34,1.12,5.11,1.46s4.12.58,5.6-.68a3.51,3.51,0,0,0,1.16-3.28,2.07,2.07,0,0,0-.54-1.12,2.92,2.92,0,0,0-.78-.51,39.7,39.7,0,0,0-9.89-2.9l-7-1.41a41.72,41.72,0,0,0-6.47-1.23C105.56,240.79,103,240.78,100.37,240.85Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-19)"
            >
            </path>
            <path
                d="M211.34,212.9v3.6H66.3c-.23-1.2-.44-2.4-.64-3.6Z"
                transform="translate(-11.22 -28.14)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M138.16,162.16a6.22,6.22,0,0,0-1.6-.87c-1.71-.64-4.1-.68-5.46.7s-1.3,3.2-1.6,4.94a15.65,15.65,0,0,1-3.06,6.84,10.41,10.41,0,0,0-2.21,3.38,2.1,2.1,0,0,0,0,1.31,2.63,2.63,0,0,0,1.09,1,9.89,9.89,0,0,0,3.43,1.6,2.6,2.6,0,0,0,2.37-.51,2,2,0,0,1,1.84-.69,9.32,9.32,0,0,0,2.16.38,3,3,0,0,0,2.42-2.13,13.16,13.16,0,0,0,.53-3.36c.34-3,1.49-5.88,1.67-8.9A4.12,4.12,0,0,0,138.16,162.16Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-20)"
            >
            </path>
            <path
                d="M120.91,178.27a1.42,1.42,0,0,0-.23,1.18,1.08,1.08,0,0,0,1,.55,22.41,22.41,0,0,1,8.44,2.05c.58.27,1.33.56,1.73,0a1.41,1.41,0,0,0,0-1.35,2.7,2.7,0,0,0-.87-.94,32.36,32.36,0,0,0-3.6-2.31C125.07,176.09,122.69,175.37,120.91,178.27Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M129.82,225c1.35,2.7,4.18,4.34,1.35,7.05-5,4.82-13.83,6.83-20.53,6.77-4.48,0-9.12-1.07-12.64-3.85a7.27,7.27,0,0,1-2.44-3.07,8.55,8.55,0,0,1-.4-2.51c-.88-15.79,4.71-31.33,12.61-45,1.5-2.62,3.47-5.44,6.47-5.88a9.84,9.84,0,0,1,3.2.2,178.25,178.25,0,0,1,20.77,4.85,6.65,6.65,0,0,1,2.51,1.14,7.06,7.06,0,0,1,1.36,1.67l10.78,16.53c1.57,2.39,3.18,5,3,7.84a3.85,3.85,0,0,1-.8,2.31,4.89,4.89,0,0,1-3,1.31,26.2,26.2,0,0,1-11.77-.8c2.13-1.63,1.46-5,.06-7.32a18.18,18.18,0,0,0-3.49-4.13,53.43,53.43,0,0,1-4.4,12.88C131,218,128.11,221.57,129.82,225Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
            <path
                d="M140.15,162.65a5.54,5.54,0,0,0,.1-3.1A10.58,10.58,0,0,0,138,155.5c-2-2.41-4.61-4.52-7.7-5-8.36-1.37-18.08,7.16-21.26,14.21-2,4.43-2.6,9.34-3.72,14.08s-2.93,9.57-6.66,12.68c-1.52,1.27-3.37,2.27-4.28,4-1.11,2.13-.46,4.85,1,6.75,1.35,1.73,3.07,2.42,4.81,3.55a11.25,11.25,0,0,1,3.61,4.39c1.92,3.71,2.67,8,5,11.46l3.42-1.89a31.47,31.47,0,0,0,8-5.57c2.25-2.37,3.77-5.68,3.21-8.9-.33-1.95-1.38-3.71-2-5.59-1.92-5.93,2.28-8.3,5.93-11.82a11,11,0,0,0,3.14-9.54,4.44,4.44,0,0,1,1.69-5.22c1.56-1.89.33-4.33,2.17-6.08,1.43-1.35,3.61-1.51,4.95-3A3.83,3.83,0,0,0,140.15,162.65Z"
                transform="translate(-11.22 -28.14)"
                fill="#2f3554"
            >
            </path>
            <path
                d="M99.08,203.83a31.79,31.79,0,0,1,2-6.1c3.91,2.78,6.77,8.21,8.21,12.78s2.62,9.39,5.56,13.18l3.18-1.1.59,2c.46,1.58-.87,3.2-.73,4.8a4.35,4.35,0,0,0,3.26,3.42,10.92,10.92,0,0,0,5.61-.4A8.18,8.18,0,0,0,130,231a3.13,3.13,0,0,0,1.19-3.18,2,2,0,0,1,.87,2.32,4.51,4.51,0,0,1-1.52,2.15c-2.21,2-5,3-7.8,4.08a40.12,40.12,0,0,1-4,1.37,42,42,0,0,1-9.61,1c-2,0-3.13-.26-4-2.2-1.24-2.77-1.33-5.89-1.63-8.9A157.64,157.64,0,0,0,99.08,203.83Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-21)"
            >
            </path>
            <path
                d="M109.78,232.79c0,.86,0,1.73-.06,2.62s-.13,1.79-.24,2.72a1.76,1.76,0,0,1-.07.6c-.09.63-.18,1.26-.3,1.91v.07c-.06.35-.13.71-.2,1.08a10.77,10.77,0,0,1-.72,5.8h0a52.86,52.86,0,0,1-5.34-.09,44.75,44.75,0,0,1-10.2-1.82h0a41,41,0,0,1-9.32-6.56,6.79,6.79,0,0,1-1.8-2.41,8,8,0,0,1-.3-3.08A58.38,58.38,0,0,0,79,216.5c-.34-1.21-.73-2.41-1.16-3.6-.48-1.34-1-2.67-1.59-4-1.37-3.09-3-6.09-3.66-9.41A15.76,15.76,0,0,1,72.5,194a1.51,1.51,0,0,1,.35-.88,1.54,1.54,0,0,1,1.14-.31c4.17.11,8,2.51,12.08,3.2,2.11.36,4.31.27,6.36.88a13.3,13.3,0,0,1,7,5.52,31,31,0,0,1,2.43,4.17c1.07,2.18,2.07,4.26,3,6.28.54,1.22,1,2.41,1.52,3.6a48.16,48.16,0,0,1,3.18,11.61v.07A37.84,37.84,0,0,1,109.78,232.79Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <path
                d="M109.78,232.79c0,.86,0,1.73-.06,2.62s-.13,1.79-.24,2.72a1.76,1.76,0,0,1-.07.6c-.09.63-.18,1.26-.3,1.91v.07c-.06.35-.13.71-.2,1.08a10.77,10.77,0,0,1-.72,5.8h0a52.86,52.86,0,0,1-5.34-.09,44.75,44.75,0,0,1-10.2-1.82h0a41,41,0,0,1-9.32-6.56,6.79,6.79,0,0,1-1.8-2.41,8,8,0,0,1-.3-3.08A58.38,58.38,0,0,0,79,216.5c-.34-1.21-.73-2.41-1.16-3.6-.48-1.34-1-2.67-1.59-4-1.37-3.09-3-6.09-3.66-9.41A15.76,15.76,0,0,1,72.5,194a1.51,1.51,0,0,1,.35-.88,1.54,1.54,0,0,1,1.14-.31c4.17.11,8,2.51,12.08,3.2,2.11.36,4.31.27,6.36.88a13.3,13.3,0,0,1,7,5.52,31,31,0,0,1,2.43,4.17c1.07,2.18,2.07,4.26,3,6.28.54,1.22,1,2.41,1.52,3.6a48.16,48.16,0,0,1,3.18,11.61v.07A37.84,37.84,0,0,1,109.78,232.79Z"
                transform="translate(-11.22 -28.14)"
                fill="url(#linear-gradient-22)"
            >
            </path>
            <path
                d="M148.36,278.82a.55.55,0,0,1,.22-.18c.37-.15.7.26.9.6a8,8,0,0,0,3.78,3.23.89.89,0,0,0,.62.08c.24-.09.36-.35.54-.52a1.09,1.09,0,0,1,1-.18,3.09,3.09,0,0,1,.94.52,18.08,18.08,0,0,1,2.53,2c.4.4.79,1,.55,1.5a1.19,1.19,0,0,1-.7.56,5,5,0,0,1-3.36-.11c-2-.66-3.78-1.89-5.74-2.66a1,1,0,0,0-.44-.1,1.87,1.87,0,0,0-.32.08,1.18,1.18,0,0,1-.87-.56A3.1,3.1,0,0,1,148.36,278.82Z"
                transform="translate(-11.22 -28.14)"
                fill="#3e3e54"
            >
            </path>
            <polygon
                points="166.75 125.19 160.68 126.5 160.68 99.58 166.75 98.28 166.75 125.19"
                fill={Color.BLUE}
            >
            </polygon>
            <polygon
                points="174.84 123.44 168.76 124.75 168.76 104.37 174.84 103.07 174.84 123.44"
                fill={Color.PURPLE}
            >
            </polygon>
            <polygon
                points="182.94 121.7 176.87 123.01 176.87 86.05 182.94 84.74 182.94 121.7"
                fill={Color.PINK}
            >
            </polygon>
            <polygon
                points="191.03 119.95 184.96 121.26 184.96 76.29 191.03 74.98 191.03 119.95"
                fill={Color.YELLOW}
            >
            </polygon>
            <polyline
                points="156.68 127.29 195.25 119.54 195.25 75.06"
                fill="none"
                stroke="#2f3554"
                strokeMiterlimit="10"
            >
            </polyline>
            <path
                d="M111.42,119.64a5.37,5.37,0,0,1,.78.33l2.42,1.33a1.2,1.2,0,0,0,.37.15,1,1,0,0,0,.42,0c3.42-.71,3.45-5.19.67-6.74a7.73,7.73,0,0,1-1.49-.89,12.73,12.73,0,0,1-1-1.25,1.48,1.48,0,0,0-1.45-.51,1.54,1.54,0,0,0-.85,1.14,7,7,0,0,1-.36,1.43,4.35,4.35,0,0,1-.56.79,11.89,11.89,0,0,0-.86,1.24,2.06,2.06,0,0,0-.4,1.4,1.74,1.74,0,0,0,1.15,1.19C110.61,119.4,111,119.5,111.42,119.64Z"
                transform="translate(-11.22 -28.14)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M133.35,137.72a7.16,7.16,0,0,1,.87,2.54,5.69,5.69,0,0,1-2.26,4.5,11.26,11.26,0,0,1-6.8,2.75,3.89,3.89,0,0,1-1.88-.28,4.38,4.38,0,0,1-1.77-2.21c-3.1-6.38-6.49-14.27-6.8-21.46-.1-2.23,1.85-6.16,4.47-4.68,2.88,1.63,5.55,5.63,7.53,8.22A64.47,64.47,0,0,1,133.35,137.72Z"
                transform="translate(-11.22 -28.14)"
                fill="#fff"
            >
            </path>
        </svg>
    );
};

export default SvgMediaPartners;
