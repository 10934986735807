import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {TranslationPortalFile} from "../../../utils/constants";
import {Action, Icon} from "@sirdata/ui-lib";

type ProgressBarProps = {
    step: number;
};

const ProgressBar: FunctionComponent<ProgressBarProps> = ({step}) => {
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const [stepPanelVisible, setStepPanelVisible] = useState(false);

    return (
        <>
            <div className="progress-bar progress-bar-block">
                <div className="progress-bars">
                    <span className="progress-bars-back"/>
                    <span className="progress-bars-track" style={{width: (step - 1) * 33.333 + "%"}}/>
                </div>
                <span className="progress-state"><span id="step">{step - 1}</span>/3 {textConfig("progress_bar.completed")}</span>
                <Icon name="keyboard_arrow_right" cssClass="progress-next" onClick={() => setStepPanelVisible(true)}/>
            </div>
            <div className={clsx("step-panel-right", {"visible": stepPanelVisible})} onClick={() => setStepPanelVisible(false)}>
                <div className="step-panel" onClick={(e) => e.stopPropagation()}>
                    <div className="step-panel-content">
                        <span className="btn-close" onClick={() => setStepPanelVisible(false)}><Icon {...Action.CLOSE.icon}/></span>
                        <div className="step-panel-header">
                            <div className="step-panel-title">{textConfig("progress_bar.title")}</div>
                            <div className="progress-bar step-panel-progress-bar">
                                <div className="progress-bars">
                                    <span className="progress-bars-back"/>
                                    <span className="progress-bars-track" style={{width: (step - 1) * 33.333 + "%"}}/>
                                </div>
                                <span className="progress-state"><span id="step">{step - 1}</span>/3 {textConfig("progress_bar.completed")}</span>
                            </div>
                        </div>
                        <div className="step-panel-items">
                            <div className="item">
                                <input type="checkbox" disabled checked/>
                                <label>{textConfig("progress_bar.step_1")}</label>
                            </div>
                            <div className="item">
                                <input type="checkbox" disabled checked={step > 2}/>
                                <label>{textConfig("progress_bar.step_2")}</label>
                            </div>
                            <div className="item">
                                <input type="checkbox" disabled checked={step > 3}/>
                                <label>{textConfig("progress_bar.step_3")}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
