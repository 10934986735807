export enum PartnerStatsCountry {
    FRANCE = "FR",
    ITALY = "IT",
    MEXICO = "MX",
    SPAIN = "ES",
    OTHERS = "ZZ"
}

export type PartnerStatsCountryItem = {
    value: PartnerStatsCountry;
    img: string;
}

export const STATS_COUNTRIES: PartnerStatsCountryItem[] = [
    {value: PartnerStatsCountry.FRANCE, img: "/images/flags/fr.png"},
    {value: PartnerStatsCountry.ITALY, img: "/images/flags/it.png"},
    {value: PartnerStatsCountry.SPAIN, img: "/images/flags/es.png"},
    {value: PartnerStatsCountry.MEXICO, img: "/images/flags/mx.png"},
    {value: PartnerStatsCountry.OTHERS, img: "/images/flags/zz.png"}
];
