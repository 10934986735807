import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {Action, Icon} from "@sirdata/ui-lib";

type ChipProps = {
    text: string;
    outlined?: boolean;
    onRemove?: () => void;
    cssClass?: string;
};

const Chip: FunctionComponent<ChipProps> = ({text, outlined, onRemove, cssClass}) => {
    return (
        <span className={clsx("chip", {"chip--outlined": outlined}, cssClass)}>
            <span>{text}</span>
            {!!onRemove && <Icon {...Action.REMOVE.icon} onClick={onRemove}/>}
        </span>
    );
};

export default Chip;
