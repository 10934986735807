import {Model} from "../../common/api/interface/Model";
import {Rest} from "../../common/api/rest/Rest";
import {session} from "../ApiSession";
import {FolderFavorites} from "../model/FolderFavorites";
import {TaxonomyElement} from "../model/taxonomy/TaxonomyElement";

export class RestFavorite extends Rest {
    rootPath = `/audience-api/partner/${session.partnerId}/favorites`;

    list(): Promise<FolderFavorites[]> {
        return this._client.get(`${this.rootPath}`, FolderFavorites) as Promise<FolderFavorites[]>;
    }

    getFolder(id: number): Promise<FolderFavorites> {
        return this._client.get(`${this.rootPath}/${id}`, FolderFavorites) as Promise<FolderFavorites>;
    }

    getSharedFolder(id: number, partnerAccountId: number): Promise<FolderFavorites> {
        return this._client.get(`${this.rootPath}/${id}?partnerAccountId=${partnerAccountId}`, FolderFavorites) as Promise<FolderFavorites>;
    }

    createFolder(folder: FolderFavorites): Promise<FolderFavorites> {
        return this._client.post(`${this.rootPath}`, folder, FolderFavorites) as Promise<FolderFavorites>;
    }

    copyFolder(folder: FolderFavorites): Promise<FolderFavorites> {
        return this._client.post(`${this.rootPath}/${folder.id}/copy`, folder, FolderFavorites) as Promise<FolderFavorites>;
    }

    updateFolder(folder: FolderFavorites): Promise<Model> {
        return this._client.put(`${this.rootPath}/${folder.id}`, folder, FolderFavorites);
    }

    deleteFolder(folderId: number): Promise<Model> {
        return this._client.delete(`${this.rootPath}/${folderId}`);
    }

    addElement(folderId: number, element: TaxonomyElement): Promise<Model> {
        return this._client.post(`${this.rootPath}/${folderId}/${element.type}/${element.elementId}`, undefined, FolderFavorites);
    }

    removeElement(folderId: number, element: TaxonomyElement): Promise<Model> {
        return this._client.delete(`${this.rootPath}/${folderId}/${element.type}/${element.elementId}`);
    }
}
