import {ApiModel} from "../../../common/api/model/ApiModel";

export class DataSelectionContent extends ApiModel {
    name_fr: string = "";
    name_en: string = "";
    description_fr: string = "";
    description_en: string = "";
    main_image: string = "";
    preview_image: string = "";
    theme: string = "";
    tags: string[] = [];
    size: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
