import {Rest} from "../../common/api/rest/Rest";
import {PartnerStats} from "../model/partner-data/PartnerStats";
import {PartnerAdsConfig} from "../model/partner-data/PartnerAdsConfig";
import {session} from "../ApiSession";

export class RestPartnerData extends Rest {
    rootPath = "/audience-api/partner";

    getStatsCategory(categoryId: number): Promise<PartnerStats> {
        return this._client.get(`${this.rootPath}/${session.partnerId}/stats/categories/${categoryId}`, PartnerStats) as Promise<PartnerStats>;
    }

    getAdsConfigs(): Promise<PartnerAdsConfig[]> {
        return this._client.get(`${this.rootPath}/ads`, PartnerAdsConfig) as Promise<PartnerAdsConfig[]>;
    }
}
