import {CurrencyItem, defaultCurrencyItem, getCurrencyItemFromName} from "../../api/model/Currency";
import {defaultSegmentStepSizeItem, getSegmentStepSize, SegmentStepSize, SegmentStepSizeItem} from "../../api/model/segment/SegmentStepSize";
import {getSegmentDataTypeItem, SegmentDataType} from "../../api/model/segment/SegmentDataType";
import {getTaxonomyTargetingType, TaxonomyTargetingType} from "./TaxonomyTargetingType";
import {getTaxonomyModelingType, TaxonomyModelingType} from "./TaxonomyModelingType";
import {getTaxonomyActivationType, TaxonomyActivationType} from "./TaxonomyActivationType";
import {CuratedDealStepSize, CuratedDealStepSizeItem, defaultCuratedDealStepSizeItem, getCuratedDealStepSize} from "../../api/model/curated-deal/CuratedDealStepSize";
import {defaultTaxonomyViewItem, getTaxonomyViewItem, TaxonomyViewItem} from "./TaxonomyView";
import {defaultTaxonomySortTypeItem, getTaxonomySortType, TaxonomySortType, TaxonomySortTypeItem} from "../../api/model/taxonomy/TaxonomySortType";
import {TaxonomyElementType} from "../../api/model/taxonomy/TaxonomyElementType";
import {ApiModel} from "../../common/api/model/ApiModel";
import {TaxonomyQueryParams} from "../../api/model/taxonomy/TaxonomyQueryParams";
import {QueryString} from "../../common/api/model/QueryString";

export class TaxonomyFilters extends ApiModel {
    query?: string;
    page: number = 0;
    targetingType: TaxonomyTargetingType = TaxonomyTargetingType.ALL;
    modelingType: TaxonomyModelingType = TaxonomyModelingType.ALL;
    activationType: TaxonomyActivationType = TaxonomyActivationType.ALL;
    platform: string = "";
    currency: CurrencyItem = defaultCurrencyItem;
    view: TaxonomyViewItem = defaultTaxonomyViewItem;
    vertical?: string;
    sortType: TaxonomySortTypeItem = defaultTaxonomySortTypeItem;
    descendingSortOrder = true;
    segmentStep: SegmentStepSizeItem = defaultSegmentStepSizeItem;
    segmentDataTypes?: SegmentDataType[];
    dealStep: CuratedDealStepSizeItem = defaultCuratedDealStepSizeItem;
    categoryBrandSafety = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static fromQueryString(queryString: string): TaxonomyFilters {
        const getParam = (key: string) => QueryString.getParam(queryString, key);

        const filters = new TaxonomyFilters();
        filters.query = getParam("q");
        filters.targetingType = getTaxonomyTargetingType(getParam("targeting_type"));
        filters.modelingType = getTaxonomyModelingType(getParam("modeling_type"));
        filters.activationType = getTaxonomyActivationType(getParam("activation_type"));
        filters.platform = getParam("platform");
        filters.currency = getCurrencyItemFromName(getParam("currency"));
        filters.view = getTaxonomyViewItem(getParam("view"));
        filters.vertical = getParam("vertical");
        filters.sortType = getTaxonomySortType(getParam("sort_type"));
        filters.descendingSortOrder = getParam("sort_desc") !== "false";

        if (filters.targetingType === TaxonomyTargetingType.USER) {
            filters.segmentStep = getSegmentStepSize(+getParam("segment_step") as SegmentStepSize) || defaultSegmentStepSizeItem;

            const dataTypes: string[] = [];
            let segmentDataTypes = getParam("segment_data_types") || "";
            if (Array.isArray(segmentDataTypes)) {
                dataTypes.push(...segmentDataTypes);
            } else {
                dataTypes.push(segmentDataTypes);
            }
            filters.segmentDataTypes = dataTypes.reduce((acc: SegmentDataType[], item) => {
                const dataType = getSegmentDataTypeItem(item as SegmentDataType);
                if (dataType && !acc.find((it) => it === dataType?.type)) {
                    acc.push(dataType.type);
                }
                return acc;
            }, []);
        } else if (filters.targetingType === TaxonomyTargetingType.CONTEXTUAL) {
            filters.categoryBrandSafety = getParam("brand_safety") === "true";
        }
        if (filters.activationType === TaxonomyActivationType.DEAL) {
            filters.dealStep = getCuratedDealStepSize(getParam("deal_min_size") as CuratedDealStepSize) || defaultCuratedDealStepSizeItem;
        }
        filters.preventBadScenarios();
        return filters;
    }

    toQueryString(): string {
        const params: any = {
            "q": this.query,
            "targeting_type": this.targetingType !== TaxonomyTargetingType.ALL && this.targetingType,
            "modeling_type": this.modelingType !== TaxonomyModelingType.ALL && this.modelingType,
            "activation_type": this.activationType !== TaxonomyActivationType.ALL && this.activationType,
            "platform": this.platform,
            "currency": this.currency !== defaultCurrencyItem && this.currency.name,
            "view": this.view !== defaultTaxonomyViewItem && this.view.value,
            "vertical": this.vertical,
            "sort_type": this.sortType !== defaultTaxonomySortTypeItem && this.sortType.type,
            "sort_desc": !this.descendingSortOrder && "false"
        };

        if (this.activationType === TaxonomyActivationType.DEAL) {
            params["deal_min_size"] = this.dealStep.value;
        } else {
            if (this.targetingType === TaxonomyTargetingType.USER) {
                params["segment_step"] = this.segmentStep.value;
                params["segment_data_types"] = this.segmentDataTypes?.length && this.segmentDataTypes;
            } else if (this.targetingType === TaxonomyTargetingType.CONTEXTUAL) {
                params["brand_safety"] = this.categoryBrandSafety;
            }
        }

        return QueryString.build(params);
    }

    toTaxonomyQueryParams(): TaxonomyQueryParams {
        const params = new TaxonomyQueryParams();
        params.search = this.query;
        params.page = this.page;
        params.topLink = this.vertical;

        const targetingTypes: Map<TaxonomyElementType, boolean> = new Map<TaxonomyElementType, boolean>();
        targetingTypes.set(TaxonomyElementType.SEGMENT, this.activationType !== TaxonomyActivationType.DEAL && this.targetingType !== TaxonomyTargetingType.CONTEXTUAL);
        targetingTypes.set(TaxonomyElementType.CATEGORY, this.activationType !== TaxonomyActivationType.DEAL && this.targetingType !== TaxonomyTargetingType.USER);
        targetingTypes.set(TaxonomyElementType.CURATED_DEAL, this.activationType !== TaxonomyActivationType.SELF_SERVICE);
        params.types = Array.from(targetingTypes).filter(([_, value]) => value).map(([type, _]) => type);

        if (this.modelingType === TaxonomyModelingType.COOKIE) {
            params.cookieless = false;
        } else if (this.modelingType === TaxonomyModelingType.COOKIELESS) {
            params.cookieless = true;
        }

        if (this.activationType === TaxonomyActivationType.DEAL) {
            params.minVolume = this.dealStep.minSize;
        } else {
            if (this.targetingType === TaxonomyTargetingType.USER) {
                params.minVolume = this.segmentStep.minSize;
                params.segmentDataTypes = this.segmentDataTypes;
            } else if (this.targetingType === TaxonomyTargetingType.CONTEXTUAL) {
                params.categoryBrandSafety = this.categoryBrandSafety;
            }
        }

        if (this.sortType.type !== TaxonomySortType.DEFAULT) {
            params.sortBy = JSON.stringify({field: this.sortType.field, order: this.descendingSortOrder ? "desc" : "asc"});
        }

        return params;
    }

    preventBadScenarios(): void {
        if (this.targetingType === TaxonomyTargetingType.CONTEXTUAL) {
            this.modelingType = TaxonomyModelingType.COOKIELESS;
        } else if (this.targetingType === TaxonomyTargetingType.USER) {
            this.activationType = TaxonomyActivationType.SELF_SERVICE;
        }
        if (this.modelingType === TaxonomyModelingType.COOKIE) {
            this.targetingType = TaxonomyTargetingType.USER;
        }
    }
}
