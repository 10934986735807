import {Rest} from "../../common/api/rest/Rest";
import {CampaignBrief} from "../model/campaign-brief/CampaignBrief";

export class RestCampaignBrief extends Rest {
    rootPath = "/audience-api/campaign-brief";

    sendCampaignBriefRequest(request: CampaignBrief): Promise<void> {
        return this._client.post(`${this.rootPath}`, request);
    };
}
