import React, {FunctionComponent} from "react";
import {TranslationPortalFile} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import {Action, Button, ButtonSize, ButtonStyle, Icon, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {ModalCuratedDealRequestUiEvent} from "../../modal/ModalCuratedDealRequest";
import {getTaxonomyPlatformItem} from "../../../api/model/taxonomy/TaxonomyPlatform";
import {TaxonomyElementType} from "../../../api/model/taxonomy/TaxonomyElementType";
import {CuratedDeal} from "../../../api/model/curated-deal/CuratedDeal";
import clsx from "clsx";

type CuratedDealPlatformsProps = {
    curatedDeal: CuratedDeal;
    platform: string;
    isButtonStyle?: boolean;
};

const CuratedDealPlatforms: FunctionComponent<CuratedDealPlatformsProps> = ({curatedDeal, platform, isButtonStyle}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    const handleRequestDeal = () => {
        UIEventManager.emit(ModalCuratedDealRequestUiEvent, {
            curatedDeal: curatedDeal,
            platform: getTaxonomyPlatformItem(TaxonomyElementType.CURATED_DEAL, platform)
        });
    };

    return (
        <div className={clsx("activation-platforms")}>
            <Tooltip text={textTaxonomy("action.request_deal")} onClick={handleRequestDeal} cssClass="activation-platforms__button">
                {isButtonStyle ?
                    <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_MIDNIGHT} icon={{name: "ios_share"}}>{textCommon(Action.ACTIVATE.labelKey)}</Button> :
                    <Icon name="ios_share"/>
                }
            </Tooltip>
        </div>
    );
};

export default CuratedDealPlatforms;
