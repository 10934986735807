import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CuratedDeal} from "../../../api/model/curated-deal/CuratedDeal";
import {Action, Icon, IconTooltip} from "@sirdata/ui-lib";
import {Formatter} from "../../../common/utils/Formatter";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {getTaxonomyTagColor} from "../../../utils/taxonomy/TaxonomyTag";
import {CuratedDealPlatforms, FavoriteButton, TagActivationType, TagCookieless, TagTaxonomy} from "../index";
import {TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {TaxonomyActivationType} from "../../../utils/taxonomy/TaxonomyActivationType";
import {getCuratedDealStepFromValue} from "../../../api/model/curated-deal/CuratedDealStepSize";

type TreeCuratedDealProps = {
    item: CuratedDeal;
    filters: TaxonomyFilters;
};

const TreeCuratedDeal: FunctionComponent<TreeCuratedDealProps> = ({item, filters}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const isMounted = useRef(false);
    const [isPriceHighlighted, setPriceHighlighted] = useState(false);
    const [isCopiedName, setCopiedName] = useState(false);

    useEffect(() => {
        if (!isMounted.current) return;
        setTimeout(() => {
            setPriceHighlighted(false);
        }, 800);
        setPriceHighlighted(true);
    }, [filters.currency]);

    useEffect(() => {
        isMounted.current = true;
    }, [item]);

    const handleCopyName = () => {
        copy(`${item.name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    const getBorderColor = () => {
        const userColor = getTaxonomyTagColor(TaxonomyTargetingType.USER);
        const contextualColor = getTaxonomyTagColor(TaxonomyTargetingType.CONTEXTUAL);

        if (item.isUserBased() && item.isContextual()) return `linear-gradient(180deg, ${userColor} 50%, ${contextualColor} 50%)`;
        if (item.isUserBased()) return userColor;
        if (item.isContextual()) return contextualColor;
        return getTaxonomyTagColor(TaxonomyActivationType.DEAL);
    };

    return (
        <div className="taxonomy-tree__item__block taxonomy-element">
            <div className="element__border" style={{background: getBorderColor()}}/>
            <a className="element__main" href={item.getRoute()}>
                <div className="element__main__column">
                    <div className="element__main__row">
                        <div className="element__main__name">
                            <span>{item.name}</span>
                            {item.description &&
                                <IconTooltip
                                    icon={{name: "info", outlined: true}}
                                    text={item.description}
                                    cssClass={"element__main__name__icon"}
                                />
                            }
                        </div>
                    </div>
                    <div className="element__main__row">
                        <div className="element__main__tags">
                            {item.isUserBased() && <TagTaxonomy type={TaxonomyTargetingType.USER}/>}
                            {item.isContextual() && <TagTaxonomy type={TaxonomyTargetingType.CONTEXTUAL}/>}
                            {item.isContextual() && <TagCookieless/>}
                            <TagActivationType type={TaxonomyActivationType.DEAL}/>
                        </div>
                    </div>
                </div>
                <div className="element__main__column">
                    <div className="element__main__row">
                        <div className="element__main__infos">
                            <span className="element__main__info">
                                <Icon name="equalizer" cssClass="element__main__info__icon"/>
                                <span className="element__main__info__value">
                                    {getCuratedDealStepFromValue(item.getTotalImpressions())}
                                </span>
                            </span>
                            <span className="element__main__info">
                                <Icon name="sell" cssClass="element__main__info__icon"/>
                                <span className={clsx("element__main__info__value", {"element__main__info__value--highlighted": isPriceHighlighted})}>
                                    {Formatter.formatNumber(item.getFloorPrice(filters.currency.currency) / 100)} {`${filters.currency.symbol}`}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </a>
            <div className="element__actions">
                <div className="element__actions__item">
                    <IconTooltip
                        icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                        text={textTaxonomy("action.copy_deal_name")}
                        onClick={handleCopyName}
                    />
                </div>
                <div className="element__actions__item">
                    <FavoriteButton element={item.toTaxonomyElement()}/>
                </div>
                <div className="element__actions__item">
                    <CuratedDealPlatforms curatedDeal={item} platform={filters.platform}/>
                </div>
            </div>
        </div>
    );
};

export default TreeCuratedDeal;
