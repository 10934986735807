import {ApiModel} from "../../../common/api/model/ApiModel";

export class PartnerAdsConfig extends ApiModel {
    id: number = 0;
    _map_ids: Map<string, number> = new Map();

    set map_ids(map_ids: Map<string, number> | undefined) {
        if (!map_ids) return;

        const values = Array.from(Object.entries(map_ids).length ? new Map(Object.entries(map_ids)) : map_ids);
        const mapIds: Map<string, number> = new Map();
        values.forEach((it) => mapIds.set(it[0], it[1]));
        this._map_ids = mapIds;
    }

    get map_ids(): Map<string, number> | undefined {
        return this._map_ids;
    }

    getMapId(id: number): number | undefined {
        return this._map_ids.get(`${id}`);
    }
}

