import {Action, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

type IconButtonClearAllProps = {
    onClick: () => void;
};
const IconButtonClearAll: FunctionComponent<IconButtonClearAllProps> = ({onClick}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <IconTooltip
            icon={{...Action.DELETE.icon, colorIcon: ""}}
            text={textCommon(Action.REMOVE_ALL.labelKey)}
            onClick={onClick}
            cssClass="btn-clear-all"
        />
    );
};

export default IconButtonClearAll;
