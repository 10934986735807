export enum TaxonomyTargetingType {
    ALL = "all",
    USER = "user",
    CONTEXTUAL = "contextual"
}

export const TAXONOMY_TARGETING_TYPES: TaxonomyTargetingType[] = [
    TaxonomyTargetingType.ALL,
    TaxonomyTargetingType.USER,
    TaxonomyTargetingType.CONTEXTUAL
];

export function getTaxonomyTargetingType(type: string): TaxonomyTargetingType {
    return TAXONOMY_TARGETING_TYPES.find((it) => it === type) || TaxonomyTargetingType.ALL;
}
