import React, {FunctionComponent} from "react";

const SvgActivationPartners: FunctionComponent = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 660.78 511.55"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="296.85"
                    y1="197.86"
                    x2="296.21"
                    y2="285.88"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"></stop>
                    <stop offset="0.08" stopOpacity="0.69"></stop>
                    <stop offset="0.21" stopOpacity="0.32"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="536.88"
                    y1="409.9"
                    x2="567.57"
                    y2="409.9"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="467.94"
                    y1="363.87"
                    x2="468.42"
                    y2="525.67"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="538.08"
                    y1="410.25"
                    x2="412.93"
                    y2="432.65"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="3846.86"
                    y1="388.25"
                    x2="4166.28"
                    y2="374.09"
                    gradientTransform="matrix(-1, 0, 0, 1, 4404.37, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="556.84"
                    y1="398.99"
                    x2="472.83"
                    y2="449.67"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="3943.59"
                    y1="409.9"
                    x2="3974.28"
                    y2="409.9"
                    gradientTransform="matrix(-1, 0, 0, 1, 4099.55, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="3874.65"
                    y1="363.87"
                    x2="3875.13"
                    y2="525.67"
                    gradientTransform="matrix(-1, 0, 0, 1, 4099.55, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="3944.79"
                    y1="410.25"
                    x2="3819.64"
                    y2="432.65"
                    gradientTransform="matrix(-1, 0, 0, 1, 4099.55, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="3963.56"
                    y1="398.99"
                    x2="3879.54"
                    y2="449.67"
                    gradientTransform="matrix(-1, 0, 0, 1, 4099.55, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M94.42,115.5c-34.85,40.57-49.87,94.3-60,146.82A1077,1077,0,0,0,15.77,427c-1.17,32.45.73,68.86,24.32,91.16C48.91,526.51,60,532,71.07,537c19.73,8.94,40.25,16.83,61.78,19.25,35.68,4,71.13-7.19,105.28-18.3,60.16-19.57,122.71-39.55,185.48-31.63,29.11,3.67,57.15,13.27,86.08,18.18s60.26,4.68,85.66-10c17.67-10.23,31-26.64,41.52-44.14,23.61-39.27,34.76-85,38.33-130.71,1.76-22.57,1.73-45.44-2.64-67.65-5-25.38-15.52-49.29-26.14-72.87-13-28.93-26.47-57.94-45.06-83.65S558.51,67.28,529,55.67C472.83,33.6,411.41,53.12,354.65,62c-29,4.51-59,7.42-87.94.89-13.93-3.14-25.11-9.6-39.4-10.11-15.07-.53-31.22,3-45.6,7.15C148.14,69.62,117.23,88.93,94.42,115.5Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <rect
                x="272.75"
                y="212.35"
                width="47.7"
                height="38.96"
                fill="#e8ecfd"
            >
            </rect>
            <rect
                x="272.75"
                y="212.35"
                width="47.7"
                height="38.96"
                fill="url(#linear-gradient)"
            >
            </rect>
            <rect
                x="224.44"
                y="132.42"
                width="150.53"
                height="95.29"
                rx="14.38"
                fill="#fff"
            >
            </rect>
            <rect
                x="185.01"
                y="250.87"
                width="253.37"
                height="8.07"
                fill="#fff"
            >
            </rect>
            <rect
                x="185.01"
                y="255.57"
                width="253.37"
                height="3.37"
                fill="#d9dbe2"
            >
            </rect>
            <ellipse
                cx="328.02"
                cy="414.87"
                rx="270.48"
                ry="31.16"
                fill="#e8ecfd"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </ellipse>
            <path
                d="M545.07,363.91s27.82,89.74,24.8,91.94-4.93-3.78-4.93-3.78l-28.06-83.4Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M485.27,374.27l-.26.65-31.7,81.33a10.17,10.17,0,0,1-2.13,3.38,2.06,2.06,0,0,1-3,.18C445.32,457.47,475.1,374,475.1,374Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M509,368.3s-1,93.94-4.53,95.11-3.53-5.11-3.53-5.11l-1.2-88Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M540.1,365s30.28,87,27.25,89.16-2.41-2.1-2.41-2.1l-28.06-83.4Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-2)"
            >
            </path>
            <path
                d="M485,374.92l-31.7,81.33a10.17,10.17,0,0,1-2.13,3.38c.11-9.32,27.09-84.86,27.09-84.86Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-3)"
            >
            </path>
            <path
                d="M509,368.3s-1,93.94-4.53,95.11-1.35-7.08-1.35-7.08l1.25-87Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-4)"
            >
            </path>
            <path
                d="M570.52,342.44c-6.64,15.31-21.58,25.87-37.59,30.61-12.48,3.69-25.63,4.26-38.71,3.51q-5.49-.29-11-.83c-6.4-.65-12.89-1.48-18.88-3.82s-11.57-6.38-14.61-12.09a29.23,29.23,0,0,1-3-12.06c-.63-10.74,2.77-22.16,11-29,6.46-5.4,14.89-7.5,21.59-12.51,7.5-5.63,15.2-10.66,23.14-15.72s16-10.17,24.88-13.39,18.78-4.43,27.76-1.58c3.11,1,7.9,3.41,12.59,14.89C574.5,306.86,577.58,326.15,570.52,342.44Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M521.35,233.7c-3.19,4.37-12.91,8.59-12.91,8.59-1.12-4.32,1.27-5.92,1.27-5.92,2.49.22,5.76-5.85,4.69-7.76-1.32-2.38-3.15-1.53-4.37-.51a11.54,11.54,0,0,1-4.41-8.15c-.16-1.87,3.07-7.15-3.12-9.44s-14.91,0-14.91,0-2.25-5.77,5-8.53c16.27-6.22,35.61,4.39,34.53,22.62-.29,4.94-.62,10.55,2.8,14.12,1.87,1.94,4.51,2.88,7,3.86,3.25,1.26,6.55,2.79,8.83,5.44s3.25,6.71,1.44,9.7c-.86,1.43-2.35,2.8-2,4.43a4.61,4.61,0,0,0,1.25,1.91,26.46,26.46,0,0,1,6.87,14.51,18.94,18.94,0,0,1-4.72,15.07c-5,5.25-13,6.62-20.17,5.54-5.8-.88-11.54-3.28-15.48-7.63-6-6.63-6.75-16.26-8.42-25a5.21,5.21,0,0,0-1.24-2.92c-1.75-1.64-4.56-.31-6.95-.37-4.13-.09-6.9-4.62-7-8.74s1.57-8,2.41-12.09.58-8.81-2.55-11.5c-1.17-1-2.63-1.63-3.89-2.53-5.58-4-5.25-13.23-.49-18.19s12.4-6.18,19.17-5c5.44,1,17.72,4,20.24,9S524.56,229.32,521.35,233.7Z"
                transform="translate(-15.51 -45.53)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M479.94,259.75l-26.23,27-34.82-18.36a25.87,25.87,0,0,0-14.33-3.83c-.52,0-1.22.3-1.1.8s.7.54.79,1-.47.74-.93.84L395,269.05c-1.22.28-2.7.85-2.77,2.1a2.73,2.73,0,0,0,1.25,2.06c5.49,4.57,13.19,5.57,20.31,4.91a7.25,7.25,0,0,1,2.08,0,6.76,6.76,0,0,1,1.92.81,86.47,86.47,0,0,1,7.81,4.89c6.83,4.8,12.94,10.57,19.87,15.23a9.94,9.94,0,0,0,4.82,2.05,10.54,10.54,0,0,0,4.51-1.09c10-4,20.25-8.27,27.86-15.89a7,7,0,0,0,1.62-2.16,7.67,7.67,0,0,0,.39-2.57q.15-5.49-.14-11a10.58,10.58,0,0,0-1.07-4.83,3.8,3.8,0,0,0-4.2-1.95"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M463.35,288.23a14.58,14.58,0,0,1-2.75,1.66c-2.12.89-4.51.72-6.81.66a61.59,61.59,0,0,0-10.42.62,5.47,5.47,0,0,0-2.44.77,2,2,0,0,0-.84,2.24,2.94,2.94,0,0,0,1,1.11c2.79,2.25,5.79,4.59,9.35,5,4.26.56,8.34-1.7,12-3.88L470.1,292a8.21,8.21,0,0,0,2.92-2.39c1.07-1.65,1.32-6.26-1.28-6.74C469.61,282.45,465,287,463.35,288.23Z"
                transform="translate(-15.51 -45.53)"
                fill="#dea095"
            >
            </path>
            <path
                d="M483.8,253a7.25,7.25,0,0,1,1.55-.32c2.64-.25,5.64.4,8.29.52,3.73.16,7.47.25,11.21.13,7.13-.21,18.83-1,25.21,6.8,7.91,9.63,1.09,26.56-4.43,39.26-2,4.74-3.06,20.09-2,31.31,0,0-22.2,7.77-41.73,3.72l-6.7-25.14c-.37-1.3-1.53-2.68-2.1-3.92a45.27,45.27,0,0,1-3.36-10.54c-.8-4.2-2-11.19-.2-15.23.27-.62,1.2-1.1,1.28-1.75.11-.9-1.08-2.4-1.45-3.21a48.2,48.2,0,0,1-2.93-8.14,2.13,2.13,0,0,1-.06-1.34,2.24,2.24,0,0,1,.76-.86c2.93-2.35,5.87-4.7,9-6.82C478.27,256,481.1,253.82,483.8,253Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M508.53,235.21c-1.48,4.71-7.67,13-14.75,11.09a9.12,9.12,0,0,1-.56,9.71s2.3,5.87,17.84-2.81c0,0-4.2-.35-3.06-9a18.62,18.62,0,0,1,3.06-8.28A1.52,1.52,0,0,1,508.53,235.21Z"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M507.82,237.58s-3.41,14.49-9.19,13.88-5.41-9.43-5.41-9.43Z"
                transform="translate(-15.51 -45.53)"
                fill="#dea095"
            >
            </path>
            <path
                d="M538,265.38a17.4,17.4,0,0,0-3.7-12.37c-3.24-3.78-8.21-5.48-12.84-7.32-2-.78-4.08-1.79-4.91-3.73-.55-1.28-.43-2.74-.65-4.12s-1-2.88-2.42-3.07a3.26,3.26,0,0,0-2.37.83c-2.94,2.26-3.88,6.41-3.21,10.06s2.68,6.88,4.76,9.94,4.28,6.12,5.46,9.63c.89,2.67,1.15,5.51,1.8,8.25,1.44,6,6.49,9.6,12.42,6.18C536.43,277.29,537.71,269.59,538,265.38Z"
                transform="translate(-15.51 -45.53)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M492.54,202s-9.42.55-12.22,14.2,10.81,13.42,10.81,13.42l8.27-23.68Z"
                transform="translate(-15.51 -45.53)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M498.82,247c-8.53.71-13.71-7.25-14.67-17.65-.1-1.14-.14-2.3-.14-3.49a42.56,42.56,0,0,1,1.16-10.07c3.73-16,26.46-9.57,26.46-9.57C529.44,214.21,513.64,245.77,498.82,247Z"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M492.72,205.86c2.22,7.71,5.19,18.67,12.72,22.9.86.48,2.45,1.29,3.48,1.08s1-1.15,1.94-2a2,2,0,0,1,2.27-.39,2.35,2.35,0,0,1,.93,2,5.49,5.49,0,0,1-2.64,4.71,13.52,13.52,0,0,0-2.13,1.22,4.26,4.26,0,0,0-1.26,2.78,7.45,7.45,0,0,0,3.68,7.12,1.05,1.05,0,0,0,.51.16,1,1,0,0,0,.56-.33l3.37-3.08a27.93,27.93,0,0,0,4.31-4.59,14,14,0,0,0,2.4-7.75c0-1.25-.5-2.51-.48-3.71a18,18,0,0,1,1.25-4.6,16.31,16.31,0,0,0,1.14-6c-.27-4-3.6-7.18-7.19-9-5.68-2.81-12.24-3.13-18.58-3.2a9.61,9.61,0,0,0-3.73.5C494.18,204.29,493.73,205.49,492.72,205.86Z"
                transform="translate(-15.51 -45.53)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M507.82,303l6.51,14.3s14-17.46,19.33-36.31c4.66-16.66-3.6-20.85-3.6-20.85-6.32-2.32-10.61,6.69-16.82,24.89C509.82,295,507.82,303,507.82,303Z"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M514.33,317.27s.25-11.63-5.42-13.94-45.41-8.54-45.41-8.54l-3.73,4.3S498.79,321.59,514.33,317.27Z"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M449.93,348l.48-.5c-1.93,2.17-3.22,3.73-3.62,4.21Q448.26,349.77,449.93,348Z"
                transform="translate(-15.51 -45.53)"
                fill="#dc9ed5"
            >
            </path>
            <path
                d="M526,350.28c-1.16,6.77-5.78,12.59-11.41,16.54s-12.21,6.27-18.8,8.27c-3.89,1.19-6.31,6.18-9.82,8.25a8.85,8.85,0,0,0-3.23,2.7c-1.28,2.07-.64,4.75,0,7.09q4.47,15.53,9,31.05c1.49,5.14,3,10.59,1.58,15.76s-6.86,9.62-11.93,7.91q-14.87-24-29.74-48s-4.89-19.32,5.37-35.75c4.43-7.08,39.13-36.3,39.13-36.3,8.87-1.29,17.89-1.89,26.46-2.38C523.62,333.76,527.44,342,526,350.28Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M496.18,327.77s-35.41,28.82-39.13,36.3c-10.39,20.87-5.37,35.75-5.37,35.75q-13,20.91-26,41.82c-6,.13-14.09-1.5-14.39-7.52a11.76,11.76,0,0,1,1.18-4.89q9.8-24.41,19.59-48.8c3.87-9.68,7.88-19.56,14.08-27.86l.55-.72,0-.06.08-.1c.4-.48,1.69-2,3.62-4.21l0-.06c5.63-6.29,16.62-17.55,26.78-22,0,0,11.79,3.25,18.9,2.38Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M496.18,327.77s-35.41,28.82-39.13,36.3c-10.39,20.87-5.37,35.75-5.37,35.75q-13,20.91-26,41.82c-6,.13-14.09-1.5-14.39-7.52a11.76,11.76,0,0,1,1.18-4.89q9.8-24.41,19.59-48.8c3.87-9.68,7.88-19.56,14.08-27.86l.55-.72,0-.06.08-.1c.4-.48,1.69-2,3.62-4.21l0-.06c5.63-6.29,16.62-17.55,26.78-22,0,0,11.79,3.25,18.9,2.38Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-5)"
            >
            </path>
            <path
                d="M478,457c-2.32,2.14-5.41,4.28-5.3,7.44a1.49,1.49,0,0,0,.25.88,1.73,1.73,0,0,0,1.21.5c7.14.79,13.78-3.56,19.33-8.13,1.4-1.15,2.86-2.47,3.19-4.25s-.61-3.57-1.42-5.21a50,50,0,0,1-3.21-8.3c.25.85-3.34,3.87-3.95,4.4-1.28,1.12-3.17,1.93-4.17,3.29s-1.21,3-2,4.35A20.55,20.55,0,0,1,478,457Z"
                transform="translate(-15.51 -45.53)"
                fill="#eb9d80"
            >
            </path>
            <path
                d="M404.3,443.62c-2.51.89-5.42,1.37-7,3.5a2.38,2.38,0,0,0-.53,1.33c0,1.32,1.53,2,2.82,2.3,5.15,1.05,10.47.31,15.67-.44a3.24,3.24,0,0,0,3.28-2.93l2.05-6.32a.94.94,0,0,0,.07-.59,1,1,0,0,0-.42-.46,26.92,26.92,0,0,0-4.47-2.53c-2.11-1-2.25-.91-3.66.76A18.47,18.47,0,0,1,404.3,443.62Z"
                transform="translate(-15.51 -45.53)"
                fill="#eb9d80"
            >
            </path>
            <path
                d="M459.24,294c-4.11-.65-8.73-1.18-12.05,1.31a15.68,15.68,0,0,0-3.8,4.87c-.65,1.11-1.31,2.44-.78,3.6s1.83,1.52,3,1.81c3.79.92,7.87,1.78,11.5.36,2.68-1,4.85-3.27,7.66-3.94a3.73,3.73,0,0,0,1.35-.46,2.31,2.31,0,0,0,.77-1.24C468.58,294.67,463,294.53,459.24,294Z"
                transform="translate(-15.51 -45.53)"
                fill="#fdbaac"
            >
            </path>
            <path
                d="M415.21,455.11a1.79,1.79,0,0,1-.64.72,1.93,1.93,0,0,1-1.28,0,35.2,35.2,0,0,0-7-.24A95.78,95.78,0,0,1,392,454.29c-2.06-.3-4.35-.73-5.54-2.43a1.32,1.32,0,0,1-.28-.67,1.56,1.56,0,0,1,.41-1,17.43,17.43,0,0,1,7.86-5.36,9.88,9.88,0,0,1,3.58-.78,4.21,4.21,0,0,1,3.26,1.45c.85,1.08,1,2.63,1.93,3.6s2.53,1.09,3.93,1a31.6,31.6,0,0,0,4.84-.55c1.21-.23,3-1.06,4.2-.75C419,449.57,416,453.7,415.21,455.11Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M498.47,457.1a2,2,0,0,1-.14,1.09,2.23,2.23,0,0,1-1.21.83,39.08,39.08,0,0,0-6.83,4.25A108.36,108.36,0,0,1,476,471.08c-2.14,1-4.59,2.07-6.8,1.22a1.54,1.54,0,0,1-.69-.45,1.76,1.76,0,0,1-.24-1.2,19.87,19.87,0,0,1,4-10.08,11.16,11.16,0,0,1,2.91-3,4.8,4.8,0,0,1,4-.7c1.49.48,2.59,1.87,4.12,2.18s3.1-.57,4.39-1.51a36.42,36.42,0,0,0,4.24-3.61c1-1,2.15-2.9,3.5-3.38C498.53,449.44,498.36,455.24,498.47,457.1Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M536.19,265.81a47.29,47.29,0,0,1,2,9.39l.61,4.3a4.71,4.71,0,0,1-.14,2.69c-.71,1.51-3.58.89-5,1.12-2.54.41-5.07.81-7.61,1.2q-7.62,1.17-15.24,2.24A30.38,30.38,0,0,1,511,279c.47-2.44,1.52-4.73,2.12-7.14.49-2,.67-4,1.18-6a18.58,18.58,0,0,1,6.94-10.25c5.35-3.91,13.2,5.56,14.84,9.82Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M570.52,342.44c-6.64,15.31-21.58,25.87-37.59,30.61-12.48,3.69-25.63,4.26-38.71,3.51,0-.89.52-1.43,1.61-1.48,12.53-.63,17.72-17.1,21.48-25.48s6.33-18.91,30.8-28.62c23.84-9.44,20.93-27.2,22.12-23.62C575.86,314.2,577.58,326.15,570.52,342.44Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M572.23,343.14c-6.64,15.3-21.58,25.86-37.59,30.61-12.48,3.68-29.24,4.31-42.32,3.55,0-.88,2.42-2.17,3.51-2.22,12.53-.63,18-20.19,21.76-28.57s7.21-13.3,30.52-25.53c34.83-18.26,9.63-43.1,9.19-44.69,8.35,2.69,13,19.66,14.18,23.24C577.11,316.37,579.29,326.84,572.23,343.14Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M524.92,371.07c-10.59,2.7-21.8,2.8-32.11,6.45,3.27-1.16,10.12.64,13.76.53a100.8,100.8,0,0,0,14.91-1.57c8.71-1.58,20.25-4,27.43-9.37C544.23,363.13,530.1,369.75,524.92,371.07Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
                opacity="0.23"
            >
            </path>
            <path
                d="M531.27,464.48a2,2,0,0,1-.52.23,2.36,2.36,0,0,1-1,.05c-3.71-.46-21.92-87.17-21.92-87.17l2.49-1.45c.18-.1.39-.21.57-.29a5.11,5.11,0,0,1,6.77,2.91l0,.1a4.21,4.21,0,0,1,.23.81l14.32,79.41S533.06,463.26,531.27,464.48Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M531.68,457.34s-.15,5-.41,7.14c-.16,1-.31,1.4-.52.23v0c-1.79-10.49-16.66-74.88-19.87-88.84a5.11,5.11,0,0,1,6.77,2.91l0,.1Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-6)"
            >
            </path>
            <path
                d="M147.77,363.91S120,453.65,123,455.85s4.92-3.78,4.92-3.78L156,368.67Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M207.57,374.27l.26.65,31.7,81.33a10.17,10.17,0,0,0,2.13,3.38,2.06,2.06,0,0,0,3,.18c2.9-2.34-26.88-85.77-26.88-85.77Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M183.9,368.3s1,93.94,4.52,95.11S192,458.3,192,458.3l1.2-88Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M152.74,365s-30.28,87-27.25,89.16,2.41-2.1,2.41-2.1L156,368.67Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-7)"
            >
            </path>
            <path
                d="M207.83,374.92l31.7,81.33a10.17,10.17,0,0,0,2.13,3.38c-.1-9.32-27.09-84.86-27.09-84.86Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-8)"
            >
            </path>
            <path
                d="M183.9,368.3s1,93.94,4.52,95.11,1.35-7.08,1.35-7.08l-1.25-87Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-9)"
            >
            </path>
            <path
                d="M122.32,342.44c6.64,15.31,21.58,25.87,37.59,30.61,12.48,3.69,25.63,4.26,38.71,3.51q5.49-.29,10.95-.84c6.4-.64,12.89-1.47,18.88-3.81s11.57-6.38,14.61-12.09a29.12,29.12,0,0,0,3-12.06c.63-10.74-2.77-22.16-11-29-6.46-5.4-14.89-7.5-21.59-12.51-7.5-5.63-15.2-10.66-23.14-15.72s-16-10.17-24.88-13.39-18.78-4.43-27.76-1.58c-3.11,1-7.9,3.41-12.59,14.89C118.35,306.86,115.26,326.15,122.32,342.44Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M146.11,286.7a41.51,41.51,0,0,1,2.78,8c.6,3.36,1.63,6.66,2.22,10.05a113.61,113.61,0,0,1,1.69,20.59c0,2.73,0,5.81,1.95,7.7,1.32,1.27,3.21,1.68,5,2,3.53.71,6.72,2.11,10.25,2.71a69.49,69.49,0,0,0,11.62.7c11.46,0,32.78-1.28,32.07-17.26a539.93,539.93,0,0,0-4.76-53.92c-.86-6.06-1-13.05-2.87-18.88-1.54-4.91-7.26-4.95-12-4.45l-.9.1c-.92.09-1.84.21-2.73.32a128.2,128.2,0,0,1-19,.56c-7.13-.22-18.55-.93-25.56,5.79a15.89,15.89,0,0,0-1.37,1.47C137.53,260.69,141.27,274.5,146.11,286.7Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M172.69,284.93c.61,8-1.66,16.53,2.54,23.22a2.05,2.05,0,0,0,.87.88,3,3,0,0,0,1.79-.2,4.66,4.66,0,0,1,5.56,4.22,8.78,8.78,0,0,0,3.47-6.35c.09-1.39,0-3.08,1.16-3.84,1.5-1,3.44.77,3.76,2.53s-.18,3.62.32,5.34c.64,2.25,2.89,3.72,5.17,4.2a24,24,0,0,0,7,0,10,10,0,0,1,5,.53c1.54.73,2.73,2.48,2.26,4.12-.56,1.95-2.92,2.63-4.92,3l-27.46,5.12-14.13,2.64c-3.09.57-8.55,3.3-8.65-1.27a75.76,75.76,0,0,0-3.18-20.17c-.79-2.64-.88-5.34-1.65-7.9-.94-3.11-2.67-6.09-3.73-9.2a89.7,89.7,0,0,1-4.29-19.29c-.5-4.62-.18-10.19,3.78-12.6,2.86-1.73,6.71-1,9.4,1,3.28,2.43,4.79,6.25,7.82,8.81,3.52,3,6.26,6.38,7.44,10.95A25,25,0,0,1,172.69,284.93Z"
                transform="translate(-15.51 -45.53)"
                fill="#d9dbe2"
                opacity="0.43"
            >
            </path>
            <path
                d="M186.69,256.42a3.26,3.26,0,0,0-.51,1,2.29,2.29,0,0,0,.23,1.14c3.86,9.68,6.28,19.86,8.68,30,2.12,8.92,4.25,17.91,4.69,27.07a5.83,5.83,0,0,1-.15,2c-.7,2.26-3.65,3-4.94,4.95l-4.29-4.9a3.21,3.21,0,0,1-.66-1,3.25,3.25,0,0,1,0-1.58c1.45-9.79-.16-19.78-2.45-29.42-2-8.28-4.47-16.57-4.32-25.08,0-1,0-2.22-.77-2.89a5.32,5.32,0,0,0-1.42-.68,4.8,4.8,0,0,1-2.16-6.5,5.82,5.82,0,0,0,2.61-.67,3.92,3.92,0,0,1,2.65-.35C185.86,250.19,187.82,254.56,186.69,256.42Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M145.88,250.73c7-6.72,18.43-6,25.56-5.79a128.2,128.2,0,0,0,19-.56c.89-.11,1.81-.23,2.73-.32-3.77-1.54-9.12-.51-13.14-.38q-9.14.3-18.27,1.11C156,245.32,149.62,246.31,145.88,250.73Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M167.5,225.59c1.6,5,8.06,9.82,15.62,7.74,0,0-3,9,.75,14.49,0,0-2.45,6.27-19.07-3,0,0,4.49-.37,3.27-9.59a19.78,19.78,0,0,0-3.27-8.85A1.62,1.62,0,0,0,167.5,225.59Z"
                transform="translate(-15.51 -45.53)"
                fill="#d5846c"
            >
            </path>
            <path
                d="M175.65,237.22c8.76,2.58,15.9-4.64,19.15-15.32.35-1.16.65-2.38.9-3.62a46.38,46.38,0,0,0,1-10.79C196.19,190,171,191.78,171,191.78,150.66,196.31,160.4,232.74,175.65,237.22Z"
                transform="translate(-15.51 -45.53)"
                fill="#eb9d80"
            >
            </path>
            <path
                d="M230.13,346.1l-.52-.54c2.06,2.32,3.45,4,3.87,4.5C232.44,348.7,231.31,347.37,230.13,346.1Z"
                transform="translate(-15.51 -45.53)"
                fill="#dc9ed5"
            >
            </path>
            <path
                d="M155.11,193a15.73,15.73,0,0,1,7.19-7.29c2.88-1.52,7.08-1.22,9.44-3.59,1.27-1.27,1.52-3.2,2.25-4.84a8.54,8.54,0,0,1,3.58-3.91,7.75,7.75,0,0,1,3.36-1c3.65-.17,6.94,2.65,8.35,6,1-1.65,3.07-2.29,5-2.25,4.44.09,8.51,3.23,10.34,7.28a10.52,10.52,0,0,1,1,5.08,7,7,0,0,1-2.14,4.65,1.84,1.84,0,0,1-1,.54.8.8,0,0,1-.89-.52,37.52,37.52,0,0,1-4.67,7.44,6.42,6.42,0,0,1-2.39,2,5.24,5.24,0,0,1-2.94.17,11,11,0,0,1-3.78-1.46,1,1,0,0,0-.73-.22c-.26.07-.4.33-.53.57a5.7,5.7,0,0,1-3.55,2.9,11.71,11.71,0,0,1-4-.15,21.93,21.93,0,0,0-5.43,0,7.36,7.36,0,0,0-3.12,1,4.65,4.65,0,0,0-1.86,3.72c0,.57.56,5.27-.13,5.38-1.11.19-1.33-1.65-2.3-2.22s-1.91.17-2.41,1a4,4,0,0,0,.45,4.6,2.71,2.71,0,0,1,.76,1.06,2.07,2.07,0,0,1-.2,1.21l-1.71,4.3a103.55,103.55,0,0,0-8.21-15.56c-1.35-2-2.92-4.23-2.32-6.54s3.1-4,2.58-6.25c-.1-.43-.31-.83-.37-1.27A3.39,3.39,0,0,1,155.11,193Z"
                transform="translate(-15.51 -45.53)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M169.55,325.75s10.67,26.63,19.23,29.42S250.48,374,250.48,374l22.59,76.54,5.84-3.58c2.21-1.36,7.49-3.32,8.83-5.53,1.78-2.91-.9-10.55-1.41-13.86l-2.31-15q-4.66-30.42-9.35-60.85a4.87,4.87,0,0,0-3.22-4.59A341.73,341.73,0,0,0,239.28,332c-5.38-2.2-10.81-4.24-16.28-6.18-1.08-.38-9.09-4.76-9.31-4.59C213.62,321.26,207.94,325.53,169.55,325.75Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M266.73,453.35c-2.46-5.44-2.67-11.57-3.39-17.49-.53-4.46-1.38-8.87-2.23-13.28L249.49,361.9a6.22,6.22,0,0,0-4.39-5.72c-18.66-9.11-37-18.67-55.5-28.14,0,0-20-7.26-34.85,2.88,0,0-2,24.29,6.56,27.08q28.37,9.25,57.19,16.9A6.42,6.42,0,0,1,221,376a6.23,6.23,0,0,1,1.68,3.06c7,21.31,14,42.63,20.39,64.11q1.14,3.8,2.25,7.59c.57,1.93,1,7,2.41,8.34,1.87,1.89,4.34,0,6.84-1l13.64-5.5L224.09,345.72l-6.42-3.3"
                transform="translate(-15.51 -45.53)"
                fill="#4c516c"
            >
            </path>
            <path
                d="M297.46,443.07a44.87,44.87,0,0,1,6.74.67,11.3,11.3,0,0,1,5.71,2.32,5.41,5.41,0,0,1,1.77,3.16,4.12,4.12,0,0,1-1.05,3.41,7.3,7.3,0,0,1-2.77,1.49q-3.57,1.26-7.15,2.49c-5.11,1.76-10.3,3.54-15.7,3.83a2,2,0,0,1-.95-.1,2.16,2.16,0,0,1-.62-.47c-3.24-3.17-6.3-6.89-7.08-11.36a.9.9,0,0,1,0-.46,1,1,0,0,1,.38-.4,38.08,38.08,0,0,1,10.62-5C290.78,441.66,294,443,297.46,443.07Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M271.61,453.33a44.87,44.87,0,0,1,6.74.67,11.3,11.3,0,0,1,5.71,2.32,5.41,5.41,0,0,1,1.77,3.16,4.12,4.12,0,0,1-1.05,3.41,7.3,7.3,0,0,1-2.77,1.49q-3.57,1.26-7.15,2.49c-5.11,1.76-10.3,3.54-15.7,3.83a2,2,0,0,1-1-.1,2.16,2.16,0,0,1-.62-.47c-3.24-3.17-6.3-6.89-7.08-11.36a.9.9,0,0,1,0-.46,1,1,0,0,1,.38-.4,38.08,38.08,0,0,1,10.62-5C264.93,451.92,268.1,453.25,271.61,453.33Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M122.32,342.44c6.64,15.31,21.58,25.87,37.59,30.61,12.48,3.69,25.63,4.26,38.71,3.51,0-.89-.52-1.43-1.61-1.48-12.53-.63-17.72-17.1-21.48-25.48s-6.33-18.91-30.8-28.62c-23.84-9.44-20.93-27.2-22.12-23.62C117,314.2,115.26,326.15,122.32,342.44Z"
                transform="translate(-15.51 -45.53)"
                fill="#2f3653"
            >
            </path>
            <path
                d="M120.61,343.14c6.64,15.3,21.58,25.86,37.59,30.61,12.48,3.68,29.24,4.31,42.32,3.55,0-.88-2.42-2.17-3.51-2.22-12.53-.63-18-20.19-21.76-28.57S168,333.21,144.73,321c-34.83-18.26-9.63-43.1-9.19-44.69-8.35,2.69-13,19.66-14.18,23.24C115.73,316.37,113.55,326.84,120.61,343.14Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M167.92,371.07c10.59,2.7,21.8,2.8,32.11,6.45-3.27-1.16-10.12.64-13.76.53a100.53,100.53,0,0,1-14.9-1.57c-8.72-1.58-20.25-4-27.44-9.37C148.61,363.13,162.74,369.75,167.92,371.07Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
                opacity="0.23"
            >
            </path>
            <path
                d="M161.57,464.48a2,2,0,0,0,.52.23,2.37,2.37,0,0,0,1,.05c3.71-.46,21.92-87.17,21.92-87.17l-2.49-1.45c-.18-.1-.39-.21-.57-.29a5.11,5.11,0,0,0-6.77,2.91l0,.1a3.61,3.61,0,0,0-.24.81l-14.32,79.41S159.78,463.26,161.57,464.48Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
            >
            </path>
            <path
                d="M161.16,457.34s.15,5,.41,7.14c.16,1,.31,1.4.52.23v0c1.79-10.49,16.66-74.88,19.87-88.84a5.11,5.11,0,0,0-6.77,2.91l0,.1Z"
                transform="translate(-15.51 -45.53)"
                fill="url(#linear-gradient-10)"
            >
            </path>
            <path
                d="M181.54,335.35a5.46,5.46,0,0,1-1.94,3.33,8.17,8.17,0,0,1-3.86,1.76c-2.71.43-5.48-.32-8.1-1.14a157.53,157.53,0,0,1-24.39-9.86c-2.58-1.3-5.23-2.76-6.78-5.2-1.71-2.74-1.73-6.19-1.64-9.41a350.78,350.78,0,0,1,3.49-40.29c.84-5.91.77-15.06,4.31-20.7a10.11,10.11,0,0,1,8.44-5c5.62-.34,10.27,5,11.3,10.52s-.57,11.18-2,16.62c-2,7.84-3.74,15.79-5,23.79-.59,3.56-1.05,7.42.66,10.61a15.88,15.88,0,0,0,3,3.7,92.56,92.56,0,0,0,15.63,12.25C178.07,328.48,182.13,331.38,181.54,335.35Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M260.76,288.62a9.8,9.8,0,0,1-4.88,6.7,29.39,29.39,0,0,1-8,2.95l-11.48,3c-6,1.58-13.23,2.83-17.68-1.5a6.47,6.47,0,0,1-2-5.84,4,4,0,0,1,.69-1.49,9.69,9.69,0,0,1,3-2.23,28.62,28.62,0,0,0,8.47-7.67C222.08,280,215,277.41,209.31,273a28.42,28.42,0,0,1-3-2.6A30,30,0,0,1,198.15,255c-.5-2.44-1.35-6.52-.46-8.94,1.21-3.31,3.49-1.78,6.73-.85,12.39,3.56,23.85,9.91,34.09,17.64a161.1,161.1,0,0,1,16.71,14.72C258.23,280.58,261.35,284.37,260.76,288.62Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M233.47,286.33c-4,2.77-8.12,5.44-12.28,8-1.21.75-2.89,1.48-3.92.48a1.71,1.71,0,0,1-.53-1,4,4,0,0,1,.69-1.49,9.69,9.69,0,0,1,3-2.23,28.62,28.62,0,0,0,8.47-7.67C222.08,280,215,277.41,209.31,273c0-.27,0-.54,0-.84,0-1-.21-8.28-.78-8.63l25.56,15.82a4.72,4.72,0,0,1,2.26,2.26C237,283.46,235.14,285.19,233.47,286.33Z"
                transform="translate(-15.51 -45.53)"
                fill="#d9dbe2"
                opacity="0.38"
            >
            </path>
            <path
                d="M208.7,301.21a16.75,16.75,0,0,0-1.15,5.87,15.55,15.55,0,0,0,1.27,8c1.26,2.43,3.87,4.32,6.59,4a6.43,6.43,0,0,0,4.54-3,10.46,10.46,0,0,0,1.51-5.35c1.25-.32,1.94-1.66,2.25-2.92a10.56,10.56,0,0,1,1.18-3.66c.76-1.07,2.07-1.67,2.71-2.82a3.74,3.74,0,0,0-.11-3.47c-1.64-3.17-6.77-5.91-10.13-4.44S210.26,297.64,208.7,301.21Z"
                transform="translate(-15.51 -45.53)"
                fill="#eb9d80"
            >
            </path>
            <path
                d="M176.46,338.35c2.11,3.14,6.57,3.56,10.31,3a32.86,32.86,0,0,0,10.9-3.6,4.09,4.09,0,0,0,2.08-1.93,3.17,3.17,0,0,0,0-1.88,7.81,7.81,0,0,0-4-4.74,23.48,23.48,0,0,0-6.05-2c-3.39-.77-7-1.54-10.27-.4s-3.88,1-4.24,4.28C175,333.46,175.09,336.34,176.46,338.35Z"
                transform="translate(-15.51 -45.53)"
                fill="#eb9d80"
            >
            </path>
            <path
                d="M166.21,240.64l6.79,3.52a31.45,31.45,0,0,1,6.68,4.2,29.34,29.34,0,0,0-2.39,8.67,32.1,32.1,0,0,0-6.17-5.88c-2.15-1.42-4.65-2.3-6.66-3.93C162.09,245.3,165,242.6,166.21,240.64Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M187.51,247.32l3.17,4.22a1.93,1.93,0,0,1,.44.85,32.87,32.87,0,0,0-7.66-2.5,1,1,0,0,1-.75-.38,1,1,0,0,1-.05-.67,8.23,8.23,0,0,0-.77-4.63,3.7,3.7,0,0,1-.55-1.48c-.13-2.34,2.36.12,2.94.69A34.46,34.46,0,0,1,187.51,247.32Z"
                transform="translate(-15.51 -45.53)"
                fill="#fff"
            >
            </path>
            <path
                d="M390.48,249.89v9a14.37,14.37,0,0,1-14.37,14.38H254.33A14.38,14.38,0,0,1,240,258.86v-9Z"
                transform="translate(-15.51 -45.53)"
                fill="#e8ecfd"
                opacity="0.41"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M179.6,338.68a8.17,8.17,0,0,1-3.86,1.76c-2.71.43-5.48-.32-8.1-1.14a157.53,157.53,0,0,1-24.39-9.86c-2.58-1.3-5.23-2.76-6.78-5.2-1.71-2.74-1.73-6.19-1.64-9.41a350.78,350.78,0,0,1,3.49-40.29c.84-5.91.77-15.06,4.31-20.7a25.47,25.47,0,0,1,3.06,1.1,6.4,6.4,0,0,1,3.31,2.55c.86,1.58.5,3.54.09,5.32a384.28,384.28,0,0,0-7.69,51.55,4.07,4.07,0,0,0,1.73,4.31A90.9,90.9,0,0,0,154,327.09c6,4,13.28,8.58,20.42,9.9C176.45,337.38,178.11,337.4,179.6,338.68Z"
                transform="translate(-15.51 -45.53)"
                fill="#d9dbe2"
                opacity="0.43"
            >
            </path>
            <rect
                x="343.64"
                y="60.17"
                width="160.39"
                height="55.78"
                rx="23.07"
                fill="#fff"
            >
            </rect>
            <polygon points="441.37 134.37 426.91 113.84 441.37 113.84 441.37 134.37" fill="#fff"></polygon>
        </svg>
    );
};

export default SvgActivationPartners;
