import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Action, Icon, IconTooltip} from "@sirdata/ui-lib";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {getTaxonomyTagColor, getTaxonomyTagIcon} from "../../../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {TaxonomyActivationType} from "../../../utils/taxonomy/TaxonomyActivationType";
import TagCookieless from "../taxonomy/TagCookieless";
import {CuratedDeal} from "../../../api/model/curated-deal/CuratedDeal";
import {getCuratedDealStepFromValue} from "../../../api/model/curated-deal/CuratedDealStepSize";
import {Formatter} from "../../../common/utils/Formatter";
import {getTaxonomyTopTierIcon} from "../../../api/model/taxonomy/TaxonomyTopTier";
import clsx from "clsx";
import {CuratedDealPlatforms, FavoriteButton} from "../index";

type SearchTableCuratedDealProps = {
    item: CuratedDeal;
    filters: TaxonomyFilters;
};

const SearchCuratedDeal: FunctionComponent<SearchTableCuratedDealProps> = ({item, filters}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const isMounted = useRef(false);
    const [isPriceHighlighted, setPriceHighlighted] = useState(false);
    const [isCopiedName, setCopiedName] = useState(false);

    useEffect(() => {
        if (!isMounted.current) return;
        setTimeout(() => {
            setPriceHighlighted(false);
        }, 800);
        setPriceHighlighted(true);
    }, [filters.currency]);

    useEffect(() => {
        isMounted.current = true;
    }, [item]);

    const handleCopyName = () => {
        copy(`${item.name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    const getTypeColor = () => {
        if (item.isUserBased()) return getTaxonomyTagColor(TaxonomyTargetingType.USER);
        if (item.isContextual()) return getTaxonomyTagColor(TaxonomyTargetingType.CONTEXTUAL);
        return getTaxonomyTagColor(TaxonomyActivationType.DEAL);
    };

    return (
        <div className="search-element">
            <a href={item.getRoute()} className="element-column" style={{background: getTypeColor()}}>
                <div className="element-column__content">
                    {item.isContextual() ?
                        <Icon {...getTaxonomyTagIcon(TaxonomyTargetingType.CONTEXTUAL) || {name: ""}} cssClass="element__type__icon"/> :
                        item.isUserBased() ?
                            <Icon {...getTaxonomyTagIcon(TaxonomyTargetingType.USER) || {name: ""}} cssClass="element__type__icon"/> :
                            <></>
                    }
                </div>
            </a>
            <a href={item.getRoute()} className="element-column"> </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content">
                    <div className="element__text">
                        <Icon {...getTaxonomyTopTierIcon(item.top_path_element)}/>
                        <span>{item.top_path_element?.name}</span>
                    </div>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content">
                    <div className="element__text element__text--bold">
                        <span>{item.name}</span>
                        {item.description &&
                        <IconTooltip
                            icon={{name: "info", outlined: true}}
                            text={item.description}
                            cssClass={"element__text__icon-tooltip"}
                        />
                        }
                        {item.isContextual() && <TagCookieless/>}
                    </div>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <IconTooltip
                        icon={getTaxonomyTagIcon(TaxonomyActivationType.DEAL) || {name: ""}}
                        text={textTaxonomy(`tag.${TaxonomyActivationType.DEAL}`)}
                        cssClass={"element__icon"}
                    />
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <span className="element__info">{getCuratedDealStepFromValue(item.getTotalImpressions())}</span>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <span className={clsx("element__info", {"element__info--highlighted": isPriceHighlighted})}>{Formatter.formatNumber(item.getFloorPrice(filters.currency.currency) / 100)} {`${filters.currency.symbol}`}</span>
                </div>
            </a>
            <div className="element-column" onClick={(e) => e?.stopPropagation()}>
                <div className="element-column__content element-column__content--justify-center">
                    <div className="element__actions">
                        <div className="element__actions__item">
                            <IconTooltip
                                icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                                text={textTaxonomy("action.copy_deal_name")}
                                onClick={handleCopyName}
                            />
                        </div>
                        <div className="element__actions__item">
                            <FavoriteButton element={item.toTaxonomyElement()}/>
                        </div>
                        <div className="element__actions__item">
                            <CuratedDealPlatforms curatedDeal={item} platform={filters.platform}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchCuratedDeal;
