import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {ThresholdStat} from "../../../api/model/category/custom/Threshold";
import clsx from "clsx";

type RelevancySliderProps = {
    initialValue: number;
    statsPerThreshold: Map<number, ThresholdStat>;
    onChange: (value: number) => void;
};

const RelevancySlider: FunctionComponent<RelevancySliderProps> = ({initialValue, statsPerThreshold, onChange}) => {
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const [currentRelevancy, setCurrentRelevancy] = useState<number>(initialValue);

    return (
        <div className="relevancy-setting">
            <span className="relevancy-setting-label">{textConfig("relevancy.range_slider.reach")}</span>
            <div className="relevancy-setting-content">
                <div className="relevancy-setting-bars">
                    {Array.from(statsPerThreshold.values()).map(({value, percentage}) =>
                        <span
                            key={value}
                            className={clsx("relevancy-setting-item", {"active": value >= currentRelevancy})}
                            style={{height: percentage}}
                            onClick={() => {
                                setCurrentRelevancy(value);
                                onChange(value);
                            }}
                        />
                    )}
                </div>
                <div className="relevancy-setting-slide">
                    <input
                        className={clsx("range-slider-input", `input-value-${currentRelevancy}`)}
                        type="range"
                        value={currentRelevancy}
                        min="500"
                        max="900"
                        step="100"
                        onChange={(e) => {
                            setCurrentRelevancy(Number(e.target.value));
                            onChange(Number(e.target.value));
                        }}
                    />
                </div>
            </div>
            <span className="relevancy-setting-label">{textConfig("relevancy.range_slider.accuracy")}</span>
        </div>
    );
};

export default RelevancySlider;
