import {IconProps} from "@sirdata/ui-lib";

export enum SegmentDataType {
    INTENT = "INTENT",
    INTEREST = "INTEREST",
    DECLARATIVE = "DECLARATIVE",
    INFERRED = "INFERRED",
    MODELING = "MODELING",
    LOCATION = "LOCATION",
}

export type SegmentDataTypeItem = {
    type: SegmentDataType;
    icon: IconProps;
}

export const SEGMENT_DATATYPES: SegmentDataTypeItem[] = [
    {type: SegmentDataType.INTENT, icon: {name: "ads_click", outlined: true}},
    {type: SegmentDataType.INTEREST, icon: {name: "lightbulb", outlined: true}},
    {type: SegmentDataType.DECLARATIVE, icon: {name: "fact_check", outlined: true}},
    {type: SegmentDataType.INFERRED, icon: {name: "data_usage", outlined: true}},
    {type: SegmentDataType.MODELING, icon: {name: "view_in_ar", outlined: true}},
    {type: SegmentDataType.LOCATION, icon: {name: "location_on", outlined: true}}
];

export function getSegmentDataTypeItem(type: SegmentDataType): SegmentDataTypeItem | undefined {
    return SEGMENT_DATATYPES.find(((it) => it.type === type));
}
