import {ApiModel} from "../../../common/api/model/ApiModel";
import {pathDataSelection} from "../../../utils/constants";
import {DataSelectionSearchQueryParam} from "./DataSelectionSearchQueryParam";

export class DataSelectionSearchQuery extends ApiModel {
    search: string | undefined;
    theme: string | undefined;
    page: number = 0;
    size: number = 50;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            search: this.search,
            theme: this.theme,
            page: this.page,
            size: this.size
        };
    }

    getRoute(): string {
        let params = [];
        if (this.theme) params.push(`${DataSelectionSearchQueryParam.THEME}=${this.theme}`);
        if (this.search) params.push(`${DataSelectionSearchQueryParam.SEARCH}=${this.search}`);
        return params.length ? `${pathDataSelection}?${params.join("&")}` : pathDataSelection;
    }
}
