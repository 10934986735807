import React from "react";
import {Color} from "@sirdata/ui-lib";

const SvgFavoriteNotPublic = () => {
    return (
        <svg
            width="168"
            height="171"
            viewBox="0 0 168 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="91.7402" cy="94.2332" r="76" fill={Color.CORAL_LIGHTER}/>
            <ellipse
                cx="114.316"
                cy="144.733"
                rx="16"
                ry="1.5"
                fill={Color.GREY_DARK}
            />
            <path
                d="M9.02831 2.20056C2.34566 17.0665 -1.88459 47.0208 34.6556 47.9101C80.3309 49.0217 101.839 52.5836 110.801 103.466"
                stroke={Color.GREY}
                strokeWidth="3"
                strokeLinecap="round"
                strokeDasharray="6 12"
                fill="transparent"
            />
            <path d="M116.002 139.664L113.783 144.916L117.547 137.699L114.711 134.834L107.739 108.75L91.3159 113.139L112.296 135.48L109.819 139.765L116.002 139.664Z" fill={Color.WHITE} stroke={Color.WHITE} strokeLinecap="round"/>
            <path d="M109.316 113.233L117.816 108.233L115.816 135.233L117.316 136.733L115.816 139.733L115.448 138.233L111.316 139.233L114.316 135.233L109.316 113.233Z" fill={Color.GREY_DARK}/>
            <path d="M130.216 113.393L118.386 109.233L115.5 136.233L130.216 113.393Z" fill={Color.WHITE} stroke={Color.WHITE} strokeLinecap="round"/>
        </svg>
    );
};

export default SvgFavoriteNotPublic;
