import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {ButtonSize} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationCommonFile} from "../../../common/utils/constants";

type ButtonYesToggleProps = {
    checked: boolean;
    id?: string;
    name?: string;
    onChange: (checked: boolean) => void;
    size?: ButtonSize;
};

const ButtonYesToggle: FunctionComponent<ButtonYesToggleProps> = ({checked, id, name, onChange, size = ButtonSize.XSMALL}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);

    const handleClick = () => {
        onChange(!checked);
    };

    return (
        <button
            id={id}
            name={name}
            type="button"
            className={clsx("btn", "btn-yes-toggle", {"btn-yes-toggle--checked": checked}, size)}
            onClick={handleClick}
        >
            {textMain("buttons.yes")}
        </button>
    );
};

export default ButtonYesToggle;
