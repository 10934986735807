import React, {FunctionComponent} from "react";
import {Color} from "@sirdata/ui-lib";

const SvgContextualCustom: FunctionComponent = () => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 352.24 300.77"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="183.83"
                    y1="264.73"
                    x2="185.9"
                    y2="304.52"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"></stop>
                    <stop offset="0.08" stopOpacity="0.69"></stop>
                    <stop offset="0.21" stopOpacity="0.32"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="198.79"
                    y1="115.83"
                    x2="207.47"
                    y2="258.13"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="2316.74"
                    y1="-609.73"
                    x2="2301.18"
                    y2="-592.25"
                    gradientTransform="matrix(-0.82, 0.58, 0.58, 0.82, 2373.07, -750.08)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="4330.77"
                    y1="2142.84"
                    x2="4507.33"
                    y2="2228.49"
                    gradientTransform="translate(2301.07 4516.13) rotate(-142.1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"></stop>
                    <stop offset="0.08" stopOpacity="0.69"></stop>
                    <stop offset="0.21" stopOpacity="0.32"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="151.05"
                    y1="89.31"
                    x2="160.05"
                    y2="133.45"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="2290.73"
                    y1="-591.78"
                    x2="2325.28"
                    y2="-589.92"
                    gradientTransform="matrix(-0.82, 0.58, 0.58, 0.82, 2373.07, -750.08)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="169.8"
                    y1="127.82"
                    x2="168.29"
                    y2="306.52"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="163.48"
                    y1="158.08"
                    x2="90.67"
                    y2="199.84"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="130.94"
                    y1="144.46"
                    x2="3.3"
                    y2="217.66"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="287.37"
                    y1="96.88"
                    x2="352.62"
                    y2="250.07"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-11"
                    x1="169.38"
                    y1="134.78"
                    x2="406.73"
                    y2="301.18"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-12"
                    x1="199.17"
                    y1="253.11"
                    x2="243.94"
                    y2="12.38"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-13"
                    x1="171.77"
                    y1="88.49"
                    x2="165.01"
                    y2="385.82"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-14"
                    x1="144.76"
                    y1="213.89"
                    x2="232.33"
                    y2="309.06"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-4"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-15"
                    x1="163.53"
                    y1="235.49"
                    x2="213.67"
                    y2="305.68"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M380.31,226.05c-2.56,10.09-7,20.16-10.89,29.14-14.78,33.91-39.72,57.1-78.77,52.36-18.82-2.29-37.24-8.61-56.19-8-18.13.56-35.39,7.39-52.61,13.08-32,10.59-67.43,17.37-99.08,5.71s-56.26-47.53-45-79.33c8.76-24.82,36.15-41.47,39-67.63,3.75-34-36.49-63.33-28-96.51C52.38,60.9,64,50.5,76.31,43c28.28-17.29,63.05-23,95.54-17.12,30.9,5.55,56.61,31.57,87.75,32.77C279.68,59.41,299,48,319,49.88c24.74,2.39,42.88,24.3,54.45,46.31a133.81,133.81,0,0,1,9.31,21.47,95.67,95.67,0,0,1,2.84,46.76c-3,16-2.64,31-3.1,47.41A65.06,65.06,0,0,1,380.31,226.05Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <path d="M166,290.84c-1.22.38-2.59,1-2.9,2.21,3.84,2.44,8.59,3,13.13,2.88s9.07-.92,13.62-.95c6,0,12.76,1.07,17.58-2.57a14.53,14.53,0,0,0-7.13-2.24C201.19,284.57,169.56,289.73,166,290.84Z" transform="translate(-34.98 -23.65)" fill="url(#linear-gradient)"></path>
            <path
                d="M88.91,224.13c-1.62,0-3.86.82-3.46,2.39l73.93,6c8.7.71,17.41,1.41,26.15,1.41,13.2,0,26.34-1.62,39.51-2.38,12.4-.71,24.82-.66,37.23-.6,11.49.06,25.62,2.13,36.78-.88,1.68-.45,3.37-1.8,3.26-3.53-.05-.8-.47-1.63-.16-2.37a2,2,0,0,1,.82-.86c2.29-1.41,5.21-.31,7.85.27A18.76,18.76,0,0,0,323.25,222a3.89,3.89,0,0,0,1.76-1.46,1.56,1.56,0,0,0-.38-2.06,2.22,2.22,0,0,0-1-.23L301,216.49a5.22,5.22,0,0,0-2.49.22c-.89.39-.84,1.43-1.56,1.86-1.17.69-3.83.32-5.17.4-3.43.19-6.86.38-10.3.55q-10.27.49-20.58.83c-14,.47-28.07.75-42.1,1-27.75.45-55.51.69-83.25,1.76C120,223.69,104.46,223.79,88.91,224.13Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-2)"
            >
            </path>
            <path
                d="M145.76,70.06a5.69,5.69,0,0,0-4,.1,6.26,6.26,0,0,0-3.68,3.5.74.74,0,0,1-.88.68,5.86,5.86,0,0,0-3.94,1.51C132,77,132,78.41,131.83,80.06a1.48,1.48,0,0,0-1.33,0,4.13,4.13,0,0,0-1.11.8,4.58,4.58,0,0,0-1.29,1.65c-.4,1.09.05,2.29.07,3.45a.36.36,0,0,1-.09.29.33.33,0,0,1-.26,0c-.31-.06-.59-.22-.9-.25a1.56,1.56,0,0,0-1.52,1.28,5.06,5.06,0,0,0,.11,2.15,3.76,3.76,0,0,1-.06,2.13,4.55,4.55,0,0,1-.93,1.21,6.49,6.49,0,0,0-2,3.83,3.05,3.05,0,0,0,.54,2,2.06,2.06,0,0,0,1.85.84c1.66-.22,2.24-2.55,3.82-3.1s3.35-2.41,4.37-3.88c.82-1.18,1.62-2.55,3-3a4.82,4.82,0,0,0,1.3-.48,2.5,2.5,0,0,0,.66-.78,10.16,10.16,0,0,0,1.31-2.74c.5-1.92,0-4.08.93-5.85a4.07,4.07,0,0,1,2.61-2.06c.47-.12,1-.15,1.43-.28a5.08,5.08,0,0,0,1.52-.73,4.76,4.76,0,0,0,2.32-3.33C148.27,71.8,147,70.54,145.76,70.06Z"
                transform="translate(-34.98 -23.65)"
                fill="#ff681c"
            >
            </path>
            <path
                d="M149.85,72.43A2.25,2.25,0,0,1,151.24,75a3.38,3.38,0,0,1-.75,1.13l-1.81,2-.75-2.78a2.25,2.25,0,0,0-.73-1.35,1.7,1.7,0,0,0-1.69,0c-.53.27-1,.69-1.47,1-.13.08-.31.17-.45.09s-.16-.37-.09-.57C144.36,72.14,147.65,71.48,149.85,72.43Z"
                transform="translate(-34.98 -23.65)"
                fill="#ff681c"
            >
            </path>
            <path
                d="M151.08,147.66a5.11,5.11,0,0,0,.9,2.61c.81,1,2.16,1.5,3.28,2.23a.88.88,0,0,1,.46.59.62.62,0,0,1-.41.55,1.78,1.78,0,0,1-.73.05,28.75,28.75,0,0,0-3.45-.33c-.58,0-1.25.06-1.62-.39a1.75,1.75,0,0,1-.23-1.28,17.38,17.38,0,0,0-.4-4.93,3.16,3.16,0,0,1-.13-1.22,1.11,1.11,0,0,1,1.81-.63C151.22,145.48,151,146.89,151.08,147.66Z"
                transform="translate(-34.98 -23.65)"
                fill="#fcbdba"
            >
            </path>
            <path
                d="M132.58,153.85a5.2,5.2,0,0,0,.34,2.74c.59,1.19,1.82,1.9,2.77,2.84a.84.84,0,0,1,.33.67.6.6,0,0,1-.51.45,1.61,1.61,0,0,1-.72-.09,30.24,30.24,0,0,0-3.32-1c-.57-.1-1.24-.2-1.51-.7a1.78,1.78,0,0,1,0-1.3,17.37,17.37,0,0,0,.6-4.92,3.14,3.14,0,0,1,.12-1.22,1.11,1.11,0,0,1,1.9-.25C133.15,151.74,132.65,153.08,132.58,153.85Z"
                transform="translate(-34.98 -23.65)"
                fill="#fcbdba"
            >
            </path>
            <path
                d="M128.94,108.85s9.54,14.16,15.79,18.39a20.13,20.13,0,0,0,.09,4.25c.32,2.29,1.43,4.3,1.79,6.55l1.2,7.57c.05.34.15.75.48.87a1,1,0,0,0,.61,0l2.64-.72a121.73,121.73,0,0,1,.29-13.64,30.07,30.07,0,0,0,.14-5.53c-.44-4-3.72-7.57-6-10.78-1.37-1.94-2.84-4.84-4.76-6.31-1.6-1.22-3.86-.64-5.87-.44a13.64,13.64,0,0,1-4.5-.32A2,2,0,0,0,128.94,108.85Z"
                transform="translate(-34.98 -23.65)"
                fill="#473f47"
            >
            </path>
            <path
                d="M125.31,108.76s3.71,16.67,8,22.89a21.15,21.15,0,0,0-1.47,4,19.71,19.71,0,0,0-.41,3.43L130.57,153a11.13,11.13,0,0,0,3.93-.5l4.88-12.27A17,17,0,0,0,141,134c0-4.18-4-19.44-4.47-20.21-1.05-1.73-3.36-2-5.3-2.56a13.38,13.38,0,0,1-4.07-1.94C126.55,108.91,126,108.54,125.31,108.76Z"
                transform="translate(-34.98 -23.65)"
                fill="#473f47"
            >
            </path>
            <path
                d="M159.56,95.78a11.92,11.92,0,0,0,6.51,1,4.61,4.61,0,0,1,2-.1c-.29.41-.57,1-.16,1.35a.87.87,0,0,0,.42.14,3.47,3.47,0,0,0,1.6-.17,1.64,1.64,0,0,1,.78-.17.93.93,0,0,1,.68,1.12,2,2,0,0,1-.88,1.14,4.64,4.64,0,0,1-4.68.26c-.42-.23-.81-.52-1.24-.75a10,10,0,0,0-2.6-.78,53.28,53.28,0,0,1-7.73-2.22,2.89,2.89,0,0,1-1.83-1.36c-.51-1.26.43-3.37,1.93-2.55C156.1,93.7,157.67,95,159.56,95.78Z"
                transform="translate(-34.98 -23.65)"
                fill="#fcbdba"
            >
            </path>
            <path
                d="M147.14,88.6a27.92,27.92,0,0,0,9.76,4c.15,0,.33.1.37.25a.39.39,0,0,1-.07.29,17.93,17.93,0,0,1-2.67,3.95,1.24,1.24,0,0,1-.43.36,1.28,1.28,0,0,1-1-.12q-3.37-1.35-6.6-3a14.22,14.22,0,0,1-5-3.67,5,5,0,0,1-1.16-2.9c0-1.18,1.08-3.3,2.57-2.72.67.26,1.26,1.09,1.77,1.56A25.81,25.81,0,0,0,147.14,88.6Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M147.14,88.6a27.92,27.92,0,0,0,9.76,4c.15,0,.33.1.37.25a.39.39,0,0,1-.07.29,17.93,17.93,0,0,1-2.67,3.95,1.24,1.24,0,0,1-.43.36,1.28,1.28,0,0,1-1-.12q-3.37-1.35-6.6-3a14.22,14.22,0,0,1-5-3.67,5,5,0,0,1-1.16-2.9c0-1.18,1.08-3.3,2.57-2.72.67.26,1.26,1.09,1.77,1.56A25.81,25.81,0,0,0,147.14,88.6Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-3)"
            >
            </path>
            <path
                d="M189,82.64a4.28,4.28,0,0,1-4,6.89s-4.73.11-3.66,4.57l9,11.46-11.46,9c-4.46,1.07-4.57-3.66-4.57-3.66a4.29,4.29,0,1,0-4.64,3.66c4.07,1.34,2.49,5.3,2.49,5.3l-11.46,9L137.41,99.29l11.35-9c4.46-1.07,4.57,3.66,4.57,3.66a4.28,4.28,0,1,0,7.6-2,4.34,4.34,0,0,0-3-1.62C153.9,89,155.48,85,155.48,85l11.57-9.13,9,11.47s4,1.58,5.3-2.49A4.29,4.29,0,0,1,189,82.64Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M189,82.64a4.28,4.28,0,0,1-4,6.89s-4.73.11-3.66,4.57l9,11.46-11.46,9c-4.46,1.07-4.57-3.66-4.57-3.66a4.29,4.29,0,1,0-4.64,3.66c4.07,1.34,2.49,5.3,2.49,5.3l-11.46,9L137.41,99.29l11.35-9c4.46-1.07,4.57,3.66,4.57,3.66a4.28,4.28,0,1,0,7.6-2,4.34,4.34,0,0,0-3-1.62C153.9,89,155.48,85,155.48,85l11.57-9.13,9,11.47s4,1.58,5.3-2.49A4.29,4.29,0,0,1,189,82.64Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-4)"
            >
            </path>
            <path
                d="M151.13,108.72c2.45,2,5,4.15,8,4.67a4.44,4.44,0,0,0,3.39-.61,1.07,1.07,0,0,0,.52-1c-.11-.49-.71-.66-1.2-.75a16.55,16.55,0,0,1-6.46-2.32,18.7,18.7,0,0,1-2.07-1.7l-4.87-4.34a.63.63,0,0,0-.49-.23c-.2,0-.32.23-.41.41a8.68,8.68,0,0,0-.68,1.93c-.15.73,0,.83.63,1.25Q149.38,107.31,151.13,108.72Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-5)"
            >
            </path>
            <path
                d="M153.39,105.06a11.89,11.89,0,0,0,6,2.8,4.54,4.54,0,0,1,1.91.45c-.41.31-.85.84-.54,1.25a.87.87,0,0,0,.37.25,3.55,3.55,0,0,0,1.58.28,1.79,1.79,0,0,1,.8.06.92.92,0,0,1,.33,1.26,1.91,1.91,0,0,1-1.16.85,4.6,4.6,0,0,1-4.56-1.06c-.35-.34-.64-.73-1-1.06a9.77,9.77,0,0,0-2.28-1.47,53.36,53.36,0,0,1-6.8-4.3,2.9,2.9,0,0,1-1.38-1.81c-.14-1.36,1.35-3.13,2.57-1.91C150.65,102.09,151.8,103.74,153.39,105.06Z"
                transform="translate(-34.98 -23.65)"
                fill="#fcbdba"
            >
            </path>
            <path
                d="M125.31,110.9a1.84,1.84,0,0,0,.66.5,24.29,24.29,0,0,0,3.13,1.5,12.55,12.55,0,0,0,8.65-.07,11.54,11.54,0,0,0,3.1-1.69,3,3,0,0,0,.88-.93,3.38,3.38,0,0,0,.24-1.92c-.25-3.37-.62-6.85,0-10.12a14,14,0,0,1,1-3.26,25.28,25.28,0,0,0,2-4.47A6.66,6.66,0,0,0,143.58,85c-1.3-1.66-3.68-3.13-5.78-2.38a13.23,13.23,0,0,0-6.05,4.62,30.18,30.18,0,0,0-3.9,7.65c-.4,1.07-.79,2.14-1.1,3.24a47.07,47.07,0,0,0-1.28,8.11c-.11,1.14-.21,2.28-.33,3.42A1.79,1.79,0,0,0,125.31,110.9Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M137.75,112.83a11.54,11.54,0,0,0,3.1-1.69,3,3,0,0,0,.88-.93,3.38,3.38,0,0,0,.24-1.92c-.25-3.37-.62-6.85,0-10.12-.4-.4-.85-.76-1.29-1.12-1-.82-2.44-2.44-3.89-2.33.35,1.53.63,3.09.84,4.65s.13,3.18.38,4.72a28.48,28.48,0,0,1-.19,8.19A2.64,2.64,0,0,0,137.75,112.83Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-6)"
            >
            </path>
            <path
                d="M149.92,99.81a.66.66,0,0,1,.33.39.65.65,0,0,1-.17.45,12.67,12.67,0,0,1-3.36,3.25.85.85,0,0,1-.44.18.83.83,0,0,1-.49-.23c-2-1.53-4-3-5.95-4.6a14.84,14.84,0,0,1-3.3-3.22A13.88,13.88,0,0,1,135,92.44c-1.19-3.75,2.41-5.38,4.82-2.63C143,93.45,145.9,97,149.92,99.81Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M149.43,153.11a.76.76,0,0,1,.1-.76c.22-.21.58-.11.86,0a10.4,10.4,0,0,0,1.93.71,2.84,2.84,0,0,0,2-.21c.34-.2.67-.5,1.06-.47a1.37,1.37,0,0,1,.55.21,6.74,6.74,0,0,0,1.91.65,1.8,1.8,0,0,1,.79.26.39.39,0,0,1,.15.21c0,.2-.19.35-.38.42-2.08.81-4.41.51-6.63.27a2.69,2.69,0,0,0-.75,0c-.37.07-.82.26-1.13,0A2.61,2.61,0,0,1,149.43,153.11Z"
                transform="translate(-34.98 -23.65)"
                fill="#473f47"
            >
            </path>
            <path
                d="M129.68,158.81a.8.8,0,0,1,.19-.74c.25-.18.59,0,.86.11a11.77,11.77,0,0,0,1.83.95,2.87,2.87,0,0,0,2,0c.36-.15.72-.41,1.11-.33a1.52,1.52,0,0,1,.52.27A6.49,6.49,0,0,0,138,160a1.78,1.78,0,0,1,.76.36.31.31,0,0,1,.11.22c0,.2-.22.32-.42.37-2.17.55-4.44,0-6.61-.55a2.78,2.78,0,0,0-.75-.11c-.37,0-.84.16-1.12-.18A2.73,2.73,0,0,1,129.68,158.81Z"
                transform="translate(-34.98 -23.65)"
                fill="#473f47"
            >
            </path>
            <path
                d="M148.2,79.29c-.17.55-.34,1.1-.48,1.66a17.15,17.15,0,0,1-.66,2.46,2.94,2.94,0,0,1-1.72,1.76,1.65,1.65,0,0,1-2.05-1c-.48.11-.65.7-.64,1.2a2.14,2.14,0,0,1-.26,1.42c-.45.53-1.33.31-1.92-.07a5.44,5.44,0,0,1-2.29-5.6c.49-2.23.47-4.9,1.74-6.83a6,6,0,0,1,1.71-1.63,4.41,4.41,0,0,1,2-.81,5.51,5.51,0,0,1,1.51.12c2.12.36,3.92.67,3.93,3.1A13.67,13.67,0,0,1,148.2,79.29Z"
                transform="translate(-34.98 -23.65)"
                fill="#fcbdba"
            >
            </path>
            <path
                d="M145.59,75.36c-.33,0-.71-.08-1,.12s-.31.65-.49,1c-.35.65-1.24.74-1.91,1.08a2.5,2.5,0,0,0-1.32,2.25,1.52,1.52,0,0,0-.21-.84.53.53,0,0,0-.76-.13.64.64,0,0,0-.15.38,1.68,1.68,0,0,0,.66,1.5,2.5,2.5,0,0,1-2.29,1.41,1.28,1.28,0,0,1-.83-.28,1.34,1.34,0,0,1-.32-.54c-.57-1.6.3-3.31.51-5a6.89,6.89,0,0,1,.29-1.76,3.86,3.86,0,0,1,.75-1.09,7.62,7.62,0,0,1,3.56-2.39c1.34-.37,4-.18,5.05.88A2.11,2.11,0,0,1,145.59,75.36Z"
                transform="translate(-34.98 -23.65)"
                fill="#ff681c"
            >
            </path>
            <path d="M145,227.17V208.46c2.46-5.34,7.31-1.7,7.31-1.7a5.49,5.49,0,1,0,0-7.58c-4.58,3-7.31-1.7-7.31-1.7V178.76h18.71s4.74-2.73,1.7-7.31a5.49,5.49,0,1,1,7.58,0s-3.64,4.85,1.7,7.31h18.71v48.41Z" transform="translate(-34.98 -23.65)" fill="url(#linear-gradient-7)"></path>
            <path d="M109.15,226.17h-3.38l34.6-91.36a1.58,1.58,0,0,1,1.47-1h0a1.58,1.58,0,0,1,1.48,2.14Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <polygon points="94.44 202.52 91.06 202.52 126.04 110.14 129.42 110.14 94.44 202.52" fill={Color.RED_DARK}></polygon>
            <polygon points="120.58 124.57 102.7 124.57 103.6 122.19 121.48 122.19 120.58 124.57" fill={Color.RED_DARK}></polygon>
            <polygon points="115.08 139.08 97.2 139.08 98.11 136.7 115.99 136.7 115.08 139.08" fill={Color.RED_DARK}></polygon>
            <polygon points="109.59 153.59 91.71 153.59 92.61 151.2 110.49 151.2 109.59 153.59" fill={Color.RED_DARK}></polygon>
            <polygon points="104.1 168.09 86.22 168.09 87.12 165.71 105 165.71 104.1 168.09" fill={Color.RED_DARK}></polygon>
            <polygon points="98.6 182.6 80.72 182.6 81.63 180.22 99.5 180.22 98.6 182.6" fill={Color.RED_DARK}></polygon>
            <path
                d="M138.67,148.22l-4.6,12.13H151l-.9,2.38h-16.9l-4.59,12.12h16.89l-.9,2.38H127.68l-4.59,12.13H140l-.9,2.38H122.19l-4.6,12.13h16.9l-.91,2.38H116.69l-7.54,19.92h-3.38l34.6-91.36a1.58,1.58,0,0,1,3,.56,1.62,1.62,0,0,1-.1.56l-3.75,9.91h16.89l-.9,2.38Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-8)"
            >
            </path>
            <polygon points="94.44 202.52 91.06 202.52 126.04 110.14 129.42 110.14 94.44 202.52" fill="url(#linear-gradient-9)"></polygon>
            <path
                d="M297.11,147.18a3.87,3.87,0,0,1-1.74.84,4,4,0,0,1-1.72-.31l-8.32-2.77a3.15,3.15,0,0,0-.68,4.24A6.15,6.15,0,0,0,287.7,151l5.71,2.05a9.81,9.81,0,0,0,8.41-.05c1.7-.57,3.57-1.28,4.39-2.88s.22-3.72.46-5.59a24,24,0,0,0,.67-3.62c0-1.05-.86-2.63-2.11-2.41-1.08.19-1.8,2.07-2.37,2.86A26.76,26.76,0,0,1,297.11,147.18Z"
                transform="translate(-34.98 -23.65)"
                fill="#fff"
            >
            </path>
            <path
                d="M284.29,144.47a1.73,1.73,0,0,0-.39-.6.61.61,0,0,0-.66-.14.68.68,0,0,0-.32.65.34.34,0,0,1-.06.26c-.06.06-.17,0-.26,0-.94-.18-1.9-.32-2.86-.42a.58.58,0,0,0-.65.79,2.88,2.88,0,0,0,.47.88c.49.8.67,1.85,1.45,2.37a2.54,2.54,0,0,0,1.58.33c.55,0,1.09-.13,1.64-.1a1.72,1.72,0,0,0,1.08-.12.93.93,0,0,0,.37-.82,3.13,3.13,0,0,0-.51-1.38C284.86,145.63,284.57,145.05,284.29,144.47Z"
                transform="translate(-34.98 -23.65)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M307.49,207.1c-3.18-.72-2.56,3.79-3.4,5.47a8.81,8.81,0,0,1-2,2.26,4.08,4.08,0,0,0-1.43,2.57.93.93,0,0,0,.11.54,1.09,1.09,0,0,0,.64.38,7.33,7.33,0,0,0,5.45-.54,4.23,4.23,0,0,0,1.87-1.73,6,6,0,0,0,.48-2.58C309.31,211.59,310.15,207.69,307.49,207.1Z"
                transform="translate(-34.98 -23.65)"
                fill="#fdb9a9"
            >
            </path>
            <path d="M304.67,216.4s-9.06.39-5.22,3.23,9.57-.69,10.13-1.34.1-1.76.1-1.76Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M309.18,217.65c-.8,1-5.06,2.31-6.95,2.17s-3.58-1-3.39-2.23,3.51-4.06,4.14-4.05,1.12,1.46,2.38,1.76a3.35,3.35,0,0,0,3.07-1.1.84.84,0,0,1,1-.27A4,4,0,0,1,309.18,217.65Z" transform="translate(-34.98 -23.65)" fill="#fff"></path>
            <path
                d="M318.42,208.88c-3.18-.74-2.56,3.78-3.4,5.45a8.85,8.85,0,0,1-2,2.27,4.06,4.06,0,0,0-1.43,2.56,1,1,0,0,0,.11.55,1.14,1.14,0,0,0,.64.38,7.37,7.37,0,0,0,5.45-.54,4.31,4.31,0,0,0,1.87-1.74,6,6,0,0,0,.48-2.57C320.24,213.34,321.08,209.45,318.42,208.88Z"
                transform="translate(-34.98 -23.65)"
                fill="#fdb9a9"
            >
            </path>
            <path d="M315.6,218.14s-9.06.38-5.22,3.24,9.57-.7,10.13-1.35.1-1.76.1-1.76Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M320.11,219.37c-.8,1-5.06,2.32-6.95,2.18s-3.58-1-3.39-2.23,3.51-4.06,4.14-4,1.12,1.46,2.38,1.75a3.34,3.34,0,0,0,3.07-1.09.83.83,0,0,1,1-.27A4,4,0,0,1,320.11,219.37Z" transform="translate(-34.98 -23.65)" fill="#fff"></path>
            <path d="M310.21,167.75l4.26,44.84,6.14-.76c2.43-10.94,1.83-43.67,1.83-43.67Z" transform="translate(-34.98 -23.65)" fill="#473f47"></path>
            <path d="M314.47,166.54l-4.26,44.83-6.16-.76c-3.51-28.36-.61-46-.61-46Z" transform="translate(-34.98 -23.65)" fill="#473f47"></path>
            <path
                d="M304,150.33c-.08-.82-.3-1.64-.38-2.48a18.79,18.79,0,0,1,.09-5.08,19.24,19.24,0,0,1,1.61-5.18c.7-1.42,2-2,3.42-2.74a12.3,12.3,0,0,1,3.87-1.31,9.73,9.73,0,0,1,2.85.16c2.9.55,6.71,2,8.69,4.33,1.49,1.71,1.66,4.17,1.71,6.44.06,3.13,0,6.26-.2,9.39,0,.45-.05.9-.09,1.36A49.84,49.84,0,0,1,324,165.53a4.09,4.09,0,0,1-1.59,2.64c-2.27,1.32-5.73.59-8.18.25-3.25-.46-6.58-.94-9.46-2.52a2.67,2.67,0,0,1-1.36-1.31,3.57,3.57,0,0,1,.27-2.19c1-3.2.22-6.57.22-9.84,0-.59.07-1.19.07-1.79C304,150.63,304,150.48,304,150.33Z"
                transform="translate(-34.98 -23.65)"
                fill="#fff"
            >
            </path>
            <path
                d="M314.2,156.12a7.65,7.65,0,0,0,2.51,1c2.65.77,5.37,1.18,8,2a1,1,0,0,1,.48.24.89.89,0,0,1,.14.56,7,7,0,0,1-.34,2.27,1.51,1.51,0,0,1-.59.91,1.74,1.74,0,0,1-1.19,0L313.3,161a8.37,8.37,0,0,0-2.17-.26,10.2,10.2,0,0,0-2.53.63,24.16,24.16,0,0,1-4.67,1,36.69,36.69,0,0,1,.69-5.63,7.41,7.41,0,0,1,.62-1.58,7.65,7.65,0,0,1,1.12-1.36c1.6-1.67,2.71-3.76,4.33-5.43.85-.88,1.91-1.44,2.66-2.38.3-.38,2.9-3.65,2.65-4,.5.62,1.74,0,2.19.64.28.4,0,.94-.26,1.35a31.89,31.89,0,0,0-3.95,10.4C313.78,155.23,313.48,155.53,314.2,156.12Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-10)"
            >
            </path>
            <path
                d="M306.12,115.07a5.49,5.49,0,0,1-1.88,10s-5.68,2.14-2.49,7.08l15.78,10.05-10,15.78c-4.94,3.19-7.08-2.49-7.08-2.49a5.48,5.48,0,1,0-4.07,6.4c5.5-.11,5.26,5.36,5.26,5.36l-10,15.78-40.84-26,9.95-15.64c4.94-3.18,7.08,2.49,7.08,2.49a5.49,5.49,0,1,0,4.07-6.39c-5.5.11-5.25-5.36-5.25-5.36l10.14-15.94,15.79,10.06s5.47.24,5.36-5.26a5.56,5.56,0,0,1,.7-4.27A5.5,5.5,0,0,1,306.12,115.07Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M330.47,147.4a12,12,0,0,1,1,2.25,10.15,10.15,0,0,1,.1,4.18,7.53,7.53,0,0,1-1.41,3.76c-1.69,2-4.71,2.19-7.33,1.94a42.13,42.13,0,0,1-8.76-1.76,1.89,1.89,0,0,1-.73-.34,2,2,0,0,1-.46-.92,14.39,14.39,0,0,1-.43-2.53c3-.86,6.32-.16,9.45-.62a1.34,1.34,0,0,0,1.26-1.78c-.15-1.76-1.24-3.3-1.75-5a7.94,7.94,0,0,1,.12-5,4.11,4.11,0,0,1,2.28-2.57c1.25-.41,1.73.57,2.42,1.49A52.68,52.68,0,0,1,330.47,147.4Z"
                transform="translate(-34.98 -23.65)"
                fill="#fff"
            >
            </path>
            <path
                d="M311.2,152.86a.84.84,0,0,0-.41-.18.65.65,0,0,0-.53.39,6.09,6.09,0,0,1-.3.63,1.49,1.49,0,0,1-1.2.49,6.76,6.76,0,0,0-1.35,0,1,1,0,0,0-.45.22.92.92,0,0,0-.25.44,2.1,2.1,0,0,0,.54,1.92,4.34,4.34,0,0,0,1.75,1.08,3.72,3.72,0,0,0,2.15.26,4.41,4.41,0,0,0,1.13-.51l1.46-.81a1,1,0,0,0,.32-.24c.32-.4-.12-1.6-.41-2A10.22,10.22,0,0,0,311.2,152.86Z"
                transform="translate(-34.98 -23.65)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M310.22,129c0-.86.07-1.73.16-2.57s.2-1.5.34-2.24a1.52,1.52,0,0,1,.58-1.13,7.09,7.09,0,0,1,3.83-1,3.68,3.68,0,0,1,2.79,1,3.64,3.64,0,0,1,.77,2,22.9,22.9,0,0,1-.56,5.63,23.79,23.79,0,0,0-.23,4.38,5.16,5.16,0,0,1-2.37.84,3.86,3.86,0,0,1-1.16-.19.89.89,0,0,1-.52-.34.91.91,0,0,1,0-.49,3.57,3.57,0,0,0,0-.46h0a14.69,14.69,0,0,0-.05-2.57.26.26,0,0,0-.08-.2.25.25,0,0,0-.17,0h-.24a6.08,6.08,0,0,1-1.28,0,2.12,2.12,0,0,1-1.34-.66A2.78,2.78,0,0,1,310.22,129Z"
                transform="translate(-34.98 -23.65)"
                fill="#fdb9a9"
            >
            </path>
            <path
                d="M312,123.52c-.3,0-.57.16-.83,0a3.77,3.77,0,0,1-.71-.78,3.31,3.31,0,0,1-.66-2.21,1.94,1.94,0,0,1,.34-1,2.11,2.11,0,0,1,.83-.61,3.89,3.89,0,0,1,2-.39,4.74,4.74,0,0,1,2.66,1.27,1.46,1.46,0,0,0,.55.36,1.41,1.41,0,0,0,.58,0,4.92,4.92,0,0,1,3.51,1.08c.56.49,1,1.12,1.58,1.55s1.15.59,1.45,1.14a1.51,1.51,0,0,1-.17,1.6,2.5,2.5,0,0,1-1.45.84,1.31,1.31,0,0,1-.78,1.7,2.05,2.05,0,0,0-.66.22c-.14.11-.2.28-.34.39-.33.27-.76.36-.9.82a2.32,2.32,0,0,0-.05.59,1.35,1.35,0,0,1-.34.79c-.13.14-.36.49-.55.53s-.36-.19-.42-.39a3.89,3.89,0,0,1,.17-2.56,2.74,2.74,0,0,0,.42-1.3c0-.46-.66-.82-1-.55s-.36.92-.72.79a2.85,2.85,0,0,0-1.38-3.46,6.66,6.66,0,0,0-2.08-.29,5.13,5.13,0,0,0-1-.11Z"
                transform="translate(-34.98 -23.65)"
                fill="#473f47"
            >
            </path>
            <path d="M313.31,131.68a15.13,15.13,0,0,0,2.9-.86,3.45,3.45,0,0,1-2.37,3.65h0a14.69,14.69,0,0,0,0-2.57.3.3,0,0,0-.08-.2.25.25,0,0,0-.17,0h-.23Z" transform="translate(-34.98 -23.65)" fill="#de9f93"></path>
            <path d="M282,138.87a1.68,1.68,0,0,1,.21,1.51,4.92,4.92,0,0,1-.75,1.39l-1.57,2.32c-.41.6-1,1.28-1.69,1.22-1.72-.12-2.48-4.58-1.84-5.9C277.1,137.76,280.87,137.46,282,138.87Z" transform="translate(-34.98 -23.65)" fill="#fdb9a9"></path>
            <path d="M290.1,178.76v48.41H241.7V208.45s2.73-4.74,7.31-1.7a5.49,5.49,0,1,0,0-7.58s-4.85,3.64-7.31-1.7V178.76h18.71c5.34-2.46,1.7-7.31,1.7-7.31a5.49,5.49,0,1,1,7.58,0c-3,4.58,1.7,7.31,1.7,7.31Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M290.1,178.76v48.41H241.7V208.45s2.73-4.74,7.31-1.7a5.49,5.49,0,1,0,0-7.58s-4.85,3.64-7.31-1.7V178.76h18.71c5.34-2.46,1.7-7.31,1.7-7.31a5.49,5.49,0,1,1,7.58,0c-3,4.58,1.7,7.31,1.7,7.31Z" transform="translate(-34.98 -23.65)" fill="url(#linear-gradient-11)"></path>
            <path d="M210.08,154.55a5.5,5.5,0,0,1-9.47,3.79c-4.58-3-7.31,1.7-7.31,1.7v18.72h48.4V130.35H193.3v18.71c2.46,5.34,7.31,1.7,7.31,1.7a5.5,5.5,0,0,1,9.47,3.79Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M213.71,186.07a5.49,5.49,0,1,0,7.58,0s-3.64-4.85,1.7-7.31H212S216.75,181.49,213.71,186.07Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M193.3,130.35v18.71c2.46,5.34,7.31,1.7,7.31,1.7a5.49,5.49,0,1,1,0,7.58c-4.58-3-7.31,1.7-7.31,1.7v18.72H212s4.74,2.73,1.7,7.31a5.49,5.49,0,1,0,7.58,0s-3.64-4.85,1.7-7.31H241.7V130.35Z" transform="translate(-34.98 -23.65)" fill="url(#linear-gradient-12)"></path>
            <path d="M193.3,178.76v48.41h48.4V208.45s2.73-4.74,7.31-1.7a5.49,5.49,0,1,0,0-7.58s-4.85,3.64-7.31-1.7V178.76H223c-5.34,2.46-1.7,7.31-1.7,7.31a5.49,5.49,0,1,1-7.58,0c3-4.58-1.7-7.31-1.7-7.31Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M145,227.17V208.46c2.46-5.34,7.31-1.7,7.31-1.7a5.49,5.49,0,1,0,0-7.58c-4.58,3-7.31-1.7-7.31-1.7V178.76h18.71s4.74-2.73,1.7-7.31a5.49,5.49,0,1,1,7.58,0s-3.64,4.85,1.7,7.31h18.71v48.41Z" transform="translate(-34.98 -23.65)" fill={Color.RED_DARK}></path>
            <path d="M145,227.17V208.46c2.46-5.34,7.31-1.7,7.31-1.7a5.49,5.49,0,1,0,0-7.58c-4.58,3-7.31-1.7-7.31-1.7V178.76h18.71s4.74-2.73,1.7-7.31a5.49,5.49,0,1,1,7.58,0s-3.64,4.85,1.7,7.31h18.71v48.41Z" transform="translate(-34.98 -23.65)" fill="url(#linear-gradient-13)"></path>
            <path
                d="M210.14,154.56a5.5,5.5,0,0,1-9.47,3.79s-4.85-3.64-7.31,1.7v18.71H174.65c-5.34-2.46-1.7-7.31-1.7-7.31a5.49,5.49,0,1,0-7.58,0c3,4.58-1.7,7.31-1.7,7.31H145V130.35h18.53c5.34,2.46,1.7,7.31,1.7,7.31a5.49,5.49,0,1,0,7.58,0c-3-4.58,1.7-7.31,1.7-7.31h18.89v18.72s2.73,4.74,7.31,1.7a5.5,5.5,0,0,1,9.47,3.79Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M222,247.58a5.49,5.49,0,0,1-9.41,3.93s-4.9-3.57-7.28,1.8l.27,18.71-18.71.27c-5.38-2.38-1.81-7.28-1.81-7.28a5.48,5.48,0,1,0-7.58.11c3.11,4.53-1.59,7.33-1.59,7.33l-18.71.27-.7-48.41,18.53-.26c5.38,2.38,1.81,7.28,1.81,7.28a5.48,5.48,0,1,0,7.58-.11c-3.11-4.53,1.59-7.33,1.59-7.33l18.89-.27.27,18.72s2.8,4.7,7.33,1.59a5.57,5.57,0,0,1,4-1.76A5.48,5.48,0,0,1,222,247.58Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M163.26,224.49c-.67.74-1.19,2.37-.13,3.06a2.86,2.86,0,0,0,1.23.34l5.66.69c-.17,0,2.79,33.35,3.95,40.44.14.84.49,1.89,1.34,2a1.63,1.63,0,0,0,1.27-.74,8.65,8.65,0,0,0,1.35-4.27,243.47,243.47,0,0,0,2-32.83c0-2.17-.08-4.44-1.16-6.33a8.84,8.84,0,0,0-5.93-3.92C169.61,222.2,165.61,221.87,163.26,224.49Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-14)"
            >
            </path>
            <path
                d="M176.88,293.71l0,.13c.24.61,1,.81,1.64.89a13.93,13.93,0,0,0,1.82.08,2.49,2.49,0,0,0,.86-.12,3.2,3.2,0,0,0,.71-.43l1.75-1.3a7.56,7.56,0,0,0,1.34-1.18,2.4,2.4,0,0,0,.58-1.65,1.46,1.46,0,0,0-1.05-1.3,4.52,4.52,0,0,0-1.1,0,2.12,2.12,0,0,1-2.08-1,1.56,1.56,0,0,0-.5-.84,1.27,1.27,0,0,0-.82-.1,6.32,6.32,0,0,0-1.78.35A2.06,2.06,0,0,0,177,288.5a3.26,3.26,0,0,0-.08.82,13.94,13.94,0,0,1,0,1.71,19.19,19.19,0,0,0-.12,2.14A2.35,2.35,0,0,0,176.88,293.71Z"
                transform="translate(-34.98 -23.65)"
                fill="#262836"
            >
            </path>
            <path
                d="M193.85,293.94a29.45,29.45,0,0,1-3.63,1.38,2.28,2.28,0,0,1-1.56.09,1.57,1.57,0,0,1-.83-1,4.73,4.73,0,0,1-.13-1.35,11,11,0,0,1,.43-2.07c.11-.63.2-1.26.28-1.89.13-.91.25-1.81.36-2.71l3.15.67c.33.07.64,1.34.84,1.61a1.65,1.65,0,0,0,1.26.67,12.78,12.78,0,0,1,1.62-.13,1.62,1.62,0,0,1,1.25,1.54,2.57,2.57,0,0,1-.85,1.89,6.25,6.25,0,0,1-1.8,1.14Z"
                transform="translate(-34.98 -23.65)"
                fill="#262836"
            >
            </path>
            <path
                d="M193.9,290.42a20.22,20.22,0,0,1-2.57,1.23l-.08,0c-1.46.57-3.09-1.72-3.09-1.72s-2.06-12.72-2-18c.07-3.62-.79-8.78-2.08-18.14-.32,9.56-1.94,13.76-1.53,17.19.63,5.21-.12,18.15-.12,18.15s-1.38,2.52-2.89,2.18l-.09,0a18.78,18.78,0,0,1-2.67-.82s-1.76-17.82-1.76-22.52.2-8.91.2-8.91l-.92-14.88,3.92-5.7.32-.13.3-.3,4.35-3.19s0,.74-.08,2l3.38,1.81.32.24.33.08,5.66,1s1.48,22.83,2,27.5S193.9,290.42,193.9,290.42Z"
                transform="translate(-34.98 -23.65)"
                fill={Color.RED_DARK}
            >
            </path>
            <path
                d="M193.9,290.42a20.22,20.22,0,0,1-2.57,1.23l-.08,0c-1.46.57-3.09-1.72-3.09-1.72s-2.06-12.72-2-18c.07-3.62-.79-8.78-2.08-18.14-.32,9.56-1.94,13.76-1.53,17.19.63,5.21-.12,18.15-.12,18.15s-1.38,2.52-2.89,2.18l-.09,0a18.78,18.78,0,0,1-2.67-.82s-1.76-17.82-1.76-22.52.2-8.91.2-8.91l-.92-14.88,3.92-5.7.32-.13.3-.3,4.35-3.19s0,.74-.08,2l3.38,1.81.32.24.33.08,5.66,1s1.48,22.83,2,27.5S193.9,290.42,193.9,290.42Z"
                transform="translate(-34.98 -23.65)"
                fill="url(#linear-gradient-15)"
            >
            </path>
            <path
                d="M220.54,210.84a.43.43,0,0,1,.24-.08c.23,0,.24.37.16.59a6.22,6.22,0,0,1-.9,1.71.75.75,0,0,0-.21.48c0,.18.16.31.28.44a3.57,3.57,0,0,1,.77,3.15,6.22,6.22,0,0,1-1.65,2.89,2.37,2.37,0,0,1-1.59.89c-1.08,0-2.43-1.33-2.86-2.25a2.8,2.8,0,0,1,.52-2.72A14.32,14.32,0,0,1,220.54,210.84Z"
                transform="translate(-34.98 -23.65)"
                fill="#a67c52"
            >
            </path>
            <path
                d="M189.21,200.34c.35.36.89.73.74,1.2-.08.25-.35.4-.55.58a2.72,2.72,0,0,0-.74,1.94,6.27,6.27,0,0,1-.3,2.11,1.56,1.56,0,0,1-1.7,1c-.41-.11-.82-.47-1.22-.3s-.4.5-.56.78a2.18,2.18,0,0,1-2.08,1,6.58,6.58,0,0,1-2.28-.79,1.72,1.72,0,0,1-.75-.58,1.25,1.25,0,0,1-.08-.81,6.51,6.51,0,0,1,1.19-2.23,9.57,9.57,0,0,0,1.51-4.43c.08-1.1,0-2.31.71-3.16,1-1.16,3.27-1.15,4.46-.3S188.11,199.21,189.21,200.34Z"
                transform="translate(-34.98 -23.65)"
                fill="#a67c52"
            >
            </path>
            <path
                d="M187.79,195.36a1.71,1.71,0,0,1-.61.67c-.53.31-1,.67-1.48,1a2.23,2.23,0,0,0-.95,1.38c-.21,1,.35,2.14,0,3.15-.13.21-.46.08-.59-.12s-.21-.45-.41-.57c-.35-.2-.77.14-.92.51a1.77,1.77,0,0,0,1,2.24c-.05.32-.46.45-.79.41a3.64,3.64,0,0,0-1-.13c-.75.14-1,1.21-1.78,1.32a1.68,1.68,0,0,1-1.29-.75l-.91-1a5.11,5.11,0,0,0-.48-.55c-.21-.16-.55-.17-.72-.36s0-.64-.18-.88a.71.71,0,0,0-.31-.19,1.28,1.28,0,0,1-.66-1.47.34.34,0,0,0,0-.22c0-.1-.14-.14-.24-.19a1.38,1.38,0,0,1-.51-1.72,2.93,2.93,0,0,1,.64-.92.92.92,0,0,1,.9-.41l.15-1.31a1.5,1.5,0,0,1,1.77-1.5l-.3-1.08a.58.58,0,0,1,0-.43.54.54,0,0,1,.32-.18,4.89,4.89,0,0,1,1.07-.18,5.14,5.14,0,0,1-.3-.94.26.26,0,0,1,.32-.36,1.34,1.34,0,0,0,.4,0s-.2-.92-.21-1a1.14,1.14,0,0,1,.15-.84,1,1,0,0,1,.89-.33,2.76,2.76,0,0,1,.92.34c0-.35.43-.53.78-.56a4,4,0,0,1,3.48,1.67,1.86,1.86,0,0,0,.22.27,1.7,1.7,0,0,0,.52.22,2.09,2.09,0,0,1,1.17,1.36,6.19,6.19,0,0,1,.18,1.82,5,5,0,0,1-.13,1.4A1.91,1.91,0,0,1,187.79,195.36Z"
                transform="translate(-34.98 -23.65)"
                fill="#2f3554"
            >
            </path>
            <path d="M182.82,206.7a20.23,20.23,0,0,1,2.42,1.21,1.83,1.83,0,0,1,.61.53.84.84,0,0,1,.08.84c-.21.37-.71.24-1.09.1a14.7,14.7,0,0,0-5.48-.76.68.68,0,0,1-.66-.27.9.9,0,0,1,.07-.75C179.72,205.69,181.27,206,182.82,206.7Z" transform="translate(-34.98 -23.65)" fill="#fff"></path>
            <path
                d="M217.05,221.44a7.43,7.43,0,0,1-1.57,2.41c-.65.73-1.32,1.43-2,2.11a59.63,59.63,0,0,1-5.19,4.47,10.61,10.61,0,0,1-4.29,2.3,3.38,3.38,0,0,1-1.94-.12,4.83,4.83,0,0,1-1.35-1c-.46-.46-5.8-6.61-5.89-6.61a1.43,1.43,0,0,0-.66.75,14.8,14.8,0,0,0-1.41,5.09,14.43,14.43,0,0,0,0,2.37c.21,2.69,1.15,5.46.22,8a3.66,3.66,0,0,1-.9,1.42,4.26,4.26,0,0,1-1.33.79,12.43,12.43,0,0,1-2.68.76,10.59,10.59,0,0,1-1.59.17c-2,.08-3.9-.3-5.86-.21-1.66.09-3.31.52-5,.46a1.68,1.68,0,0,1-2-1.59,62,62,0,0,1-1-10.79c0-1.26-.07-2.53-.15-3.79-.07-1.07.22-3.79-1-4-3.1-.6-6.32.16-9.45-.28a8,8,0,0,1,2.43-5.41,13.59,13.59,0,0,1,2.34-1.59,13,13,0,0,0,2.56-2.42,22.35,22.35,0,0,1,7.88-6.17,7,7,0,0,1,1.53-.54l.13,0a1,1,0,0,1,.23,0,7,7,0,0,1,2,.12,115.48,115.48,0,0,1,13.22,3,4.59,4.59,0,0,1,1.6.7,4.55,4.55,0,0,1,.86,1.05c1.22,1.84,2.41,3.7,3.64,5.54,1.06,1.58,2.1,3.56,3.66,4.72a43.1,43.1,0,0,0,9.54-5.38c.41-.31.94-.65,1.41-.44a1.21,1.21,0,0,1,.41.3C216.6,218.63,217.61,219.73,217.05,221.44Z"
                transform="translate(-34.98 -23.65)"
                fill="#fff"
            >
            </path>
        </svg>
    );
};

export default SvgContextualCustom;
