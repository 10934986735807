import React, {FunctionComponent} from "react";
import {Color} from "@sirdata/ui-lib";

const SvgFavoritesFolder: FunctionComponent = () => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="406"
            height="306"
            viewBox="0 0 686.2 592.16"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="281.13"
                    y1="689.93"
                    x2="110.5"
                    y2="984.73"
                    gradientTransform="translate(0 -174)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopOpacity="0"></stop>
                    <stop offset="0.98"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="161.43"
                    y1="246.95"
                    x2="195.9"
                    y2="-190.69"
                    gradientTransform="matrix(1, 0, 0, -1, -68.9, 419.49)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="349.2"
                    y1="192.54"
                    x2="239.88"
                    y2="-126.91"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="541.48"
                    y1="293.55"
                    x2="700.76"
                    y2="-110.27"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="554.44"
                    y1="347.42"
                    x2="580.42"
                    y2="-83.06"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="552.9"
                    y1="347.33"
                    x2="578.88"
                    y2="-83.15"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="551.36"
                    y1="347.23"
                    x2="577.34"
                    y2="-83.24"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="90.22"
                    y1="319.41"
                    x2="116.2"
                    y2="-111.07"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="88.68"
                    y1="319.31"
                    x2="114.66"
                    y2="-111.17"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="87.14"
                    y1="319.22"
                    x2="113.12"
                    y2="-111.26"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-11"
                    x1="336.61"
                    y1="334.28"
                    x2="362.58"
                    y2="-96.2"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-12"
                    x1="335.07"
                    y1="334.18"
                    x2="361.04"
                    y2="-96.3"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-13"
                    x1="333.53"
                    y1="334.09"
                    x2="359.5"
                    y2="-96.39"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 420)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-14"
                    x1="222.53"
                    y1="358.48"
                    x2="259.98"
                    y2="309.49"
                    gradientTransform="matrix(1, 0, 0, -1, 0, 594)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-15"
                    x1="360.24"
                    y1="204.13"
                    x2="383.88"
                    y2="204.13"
                    gradientTransform="matrix(1, 0, 0, -1, -68.9, 419.49)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#ecc4d7"></stop>
                    <stop offset="0.42" stopColor="#efd4d1"></stop>
                    <stop offset="1" stopColor="#f2eac9"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-16"
                    x1="286.95"
                    y1="52.36"
                    x2="286.64"
                    y2="-28.59"
                    gradientTransform="matrix(1, 0, 0, -1, -68.9, 419.49)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-17"
                    x1="5249.59"
                    y1="148.28"
                    x2="5275.1"
                    y2="148.75"
                    gradientTransform="translate(5473.13 419.49) rotate(180)"
                    xlinkHref="#linear-gradient-15"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-18"
                    x1="5251.07"
                    y1="152.41"
                    x2="5245.81"
                    y2="149.63"
                    gradientTransform="translate(5473.14 419.49) rotate(180)"
                    xlinkHref="#linear-gradient-15"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-19"
                    x1="313.79"
                    y1="209.29"
                    x2="323.92"
                    y2="209.29"
                    xlinkHref="#linear-gradient-15"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-20"
                    x1="298.57"
                    y1="143.44"
                    x2="417.37"
                    y2="31.24"
                    gradientTransform="matrix(1, 0, 0, -1, -68.9, 419.49)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M192.67,23.07c25.33,6.47,48.89,20.71,75.16,20.62,25.43-.08,48.16-13.53,71.53-22.8C373.58,7.31,411.1,2.34,448.28.54,478.93-.95,511-.09,538.28,13s46.82,37.72,57.89,64.38,14.31,55.52,15.56,84.05c1,24.14.75,48.56,6.55,72.12,8,32.53,27.17,61.58,42.53,91.77,12.29,24.14,22.37,49.73,24.84,76.35s-3.24,54.56-20.08,76.26C640.37,510.42,595,523.88,552,527.35c-24.11,1.94-49,1.5-71.56,9.56-15.21,5.42-28.57,14.45-43.11,21.26-29,13.59-62,18.09-94.45,19.66a577.93,577.93,0,0,1-241.69-40.34c-11.42-4.58-22.86-9.65-32.07-17.4-10.27-8.62-17.2-20.09-22.61-31.92a172.3,172.3,0,0,1-2.64-136.62c13.34-32.32,37-63.43,33.14-97.84-5.79-51.26-57-96.61-35.47-150.86C64.32,45.56,126.1,6.08,192.67,23.07Z"
                transform="translate(0 0)"
                fill={Color.YELLOW}
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M165.57,569.49c-4.56,1.29-9.74,3.14-11.33,7.6-1.45,4.09,1,8.76,4.65,11.14s8.11,3,12.44,3.34c13,1.06,26,.48,39-.09l80.41-3.57c15.73-.69,32.41-1.7,45.32-10.72,2-1.39,4-3.45,3.57-5.82-.35-1.78-2-3-3.59-3.84-14-7.8-31.13-6.33-47.16-7.5-21.15-1.55-42.06-8.21-63.38-7.33C205.25,553.49,185.1,563.94,165.57,569.49Z"
                transform="translate(0 0)"
                opacity="0.52"
                fill="url(#linear-gradient)"
                style={{isolation: "isolate"}}
            >
            </path>
            <path d="M0,115.93l4.1-1.81h188v134.1s0,21.45-15.56,30.14C170.18,281.93,0,115.93,0,115.93Z" transform="translate(0 0)" fill={Color.PINK}></path>
            <path d="M189.27,115.93V255.22a27.45,27.45,0,0,1-27.45,27.44H0V115.93Z" transform="translate(0 0)" fill={Color.PINK}></path>
            <path d="M189.27,115.93V255.22a27.45,27.45,0,0,1-27.45,27.44H0V115.93Z" transform="translate(0 0)" fill="url(#linear-gradient-2)"></path>
            <path d="M189.27,255.22a27.45,27.45,0,0,1-27.45,27.44h-4.33c9.59-3.09,8.35-20.1,8.35-20.1C183.78,265.65,189.27,255.22,189.27,255.22Z" transform="translate(0 0)" fill={Color.GREY}></path>
            <rect
                x="250.45"
                y="156.13"
                width="189.26"
                height="166.73"
                fill={Color.YELLOW}
            >
            </rect>
            <rect
                x="250.45"
                y="156.13"
                width="189.26"
                height="166.73"
                fill="url(#linear-gradient-3)"
            >
            </rect>
            <rect
                x="464.85"
                y="88.72"
                width="189.26"
                height="166.73"
                fill={Color.BLUE}
            >
            </rect>
            <rect
                x="464.85"
                y="88.72"
                width="189.26"
                height="166.73"
                fill="url(#linear-gradient-4)"
            >
            </rect>
            <path
                d="M120.47,91.24,93.66,152.72c0-.63,0-1.26-.06-1.88-1.5.56-2.52-1.13-3-2.67-.82-2.44-1.85-5.42-4.21-5.25-1.23.09-2.42,1.08-3.51,2a8.44,8.44,0,0,1-3.47,2.29,1.92,1.92,0,0,1-2.3-1.45h0a1.84,1.84,0,0,1,0-.74l-2.51,1.65c-2.41,1.57-1.26-2.07-1.81-3.28a11,11,0,0,0-3.94,2.48L97.1,81Z"
                transform="translate(0 0)"
                fill={Color.GREEN}
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M313.55,169.27c.57-.25,1.13-.53,1.69-.83-1.13-1.14,0-2.75,1.19-3.86,1.89-1.74,4.18-3.9,3.06-6-.59-1.09-2-1.78-3.31-2.37a8.35,8.35,0,0,1-3.5-2.22,1.91,1.91,0,0,1,1-3c-.85-.53-1.69-1.07-2.53-1.61-2.43-1.55,1.37-2,2.24-3a10.9,10.9,0,0,0-3.87-2.59l65.56-.75a5.31,5.31,0,0,0-1,2.79c0,1.09,1.69,1.85,2.19,2.68.06.11.11.22.16.33l.27,1.27a4.63,4.63,0,0,1-.84,2.4,12.18,12.18,0,0,0-1.84,3.28,6.27,6.27,0,0,0,.81,4.19c1,2,2.54,3.86,4.68,4.45h.2l.84,4Z"
                transform="translate(0 0)"
                fill={Color.GREEN}
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M525.51,85.21c.63,0,1.26.07,1.89.07-.45-1.53,1.3-2.42,2.88-2.82,2.49-.63,5.54-1.44,5.55-3.8,0-1.24-.9-2.5-1.79-3.66a8.45,8.45,0,0,1-2-3.62,1.91,1.91,0,0,1,1.58-2.19,1.94,1.94,0,0,1,.76,0l-1.46-2.63c-1.39-2.52,2.16-1.1,3.41-1.56a11,11,0,0,0-2.17-4.12L592.1,91.49a5.41,5.41,0,0,0-2.19,2c-.52,1,.62,2.43.65,3.4a1.15,1.15,0,0,1,0,.37l-.34,1.23a5,5,0,0,1-1.89,1.72,11.46,11.46,0,0,0-3.18,2,6.27,6.27,0,0,0-1.29,4.07c-.07,2.22.39,4.61,2,6.15a.65.65,0,0,0,.16.13l-1.19,3.93Z"
                transform="translate(0 0)"
                fill={Color.GREEN}
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M120.47,91.24,93.66,152.72c0-.63,0-1.26-.06-1.88-1.5.56-2.52-1.13-3-2.67-.82-2.44-1.85-5.42-4.21-5.25-1.23.09-2.42,1.08-3.51,2a8.44,8.44,0,0,1-3.47,2.29,1.92,1.92,0,0,1-2.3-1.45h0a1.84,1.84,0,0,1,0-.74l-2.51,1.65c-2.41,1.57-1.26-2.07-1.81-3.28a11,11,0,0,0-3.94,2.48L97.1,81Z"
                transform="translate(0 0)"
                fill="#fff"
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M313.55,169.27c.57-.25,1.13-.53,1.69-.83-1.13-1.14,0-2.75,1.19-3.86,1.89-1.74,4.18-3.9,3.06-6-.59-1.09-2-1.78-3.31-2.37a8.35,8.35,0,0,1-3.5-2.22,1.91,1.91,0,0,1,1-3c-.85-.53-1.69-1.07-2.53-1.61-2.43-1.55,1.37-2,2.24-3a10.9,10.9,0,0,0-3.87-2.59l65.56-.75a5.31,5.31,0,0,0-1,2.79c0,1.09,1.69,1.85,2.19,2.68.06.11.11.22.16.33l.27,1.27a4.63,4.63,0,0,1-.84,2.4,12.18,12.18,0,0,0-1.84,3.28,6.27,6.27,0,0,0,.81,4.19c1,2,2.54,3.86,4.68,4.45h.2l.84,4Z"
                transform="translate(0 0)"
                fill="#fff"
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M525.51,85.21c.63,0,1.26.07,1.89.07-.45-1.53,1.3-2.42,2.88-2.82,2.49-.63,5.54-1.44,5.55-3.8,0-1.24-.9-2.5-1.79-3.66a8.45,8.45,0,0,1-2-3.62,1.91,1.91,0,0,1,1.58-2.19,1.94,1.94,0,0,1,.76,0l-1.46-2.63c-1.39-2.52,2.16-1.1,3.41-1.56a11,11,0,0,0-2.17-4.12L592.1,91.49a5.41,5.41,0,0,0-2.19,2c-.52,1,.62,2.43.65,3.4a1.15,1.15,0,0,1,0,.37l-.34,1.23a5,5,0,0,1-1.89,1.72,11.46,11.46,0,0,0-3.18,2,6.27,6.27,0,0,0-1.29,4.07c-.07,2.22.39,4.61,2,6.15a.65.65,0,0,0,.16.13l-1.19,3.93Z"
                transform="translate(0 0)"
                fill="#fff"
                opacity="0.34"
                style={{isolation: "isolate"}}
            >
            </path>
            <rect
                x="495"
                y="154.43"
                width="128.96"
                height="3.41"
                fill="url(#linear-gradient-5)"
            >
            </rect>
            <rect
                x="495"
                y="180.02"
                width="128.96"
                height="3.41"
                fill="url(#linear-gradient-6)"
            >
            </rect>
            <rect
                x="495"
                y="205.62"
                width="128.96"
                height="3.41"
                fill="url(#linear-gradient-7)"
            >
            </rect>
            <rect
                x="47.28"
                y="171.99"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-8)"
            >
            </rect>
            <rect
                x="47.28"
                y="197.59"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-9)"
            >
            </rect>
            <rect
                x="47.28"
                y="223.19"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-10)"
            >
            </rect>
            <rect
                x="296.21"
                y="199.3"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-11)"
            >
            </rect>
            <rect
                x="296.21"
                y="224.9"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-12)"
            >
            </rect>
            <rect
                x="296.21"
                y="250.49"
                width="94.7"
                height="3.41"
                fill="url(#linear-gradient-13)"
            >
            </rect>
            <path d="M189.27,296s34.06-59.35,46-78.13l7.66-9.28,7.51,12.77.17,10.28-31.42,59.21S196.73,311.39,189.27,296Z" transform="translate(0 0)" fill={Color.MIDNIGHT_LIGHT}></path>
            <polygon points="250.95 210.16 213.5 286.25 240.29 252.2 250.64 231.68 250.95 210.16" fill="url(#linear-gradient-14)"></polygon>
            <path
                d="M294.93,205.61a10.23,10.23,0,0,1,1.93,6.79c4.56-3.74,4.63-11.6,9.82-14.41a25.6,25.6,0,0,1-1.57,8.83c1.65-3.4,3.68-7.15,7.31-8.23a24,24,0,0,1-4.13,10.77l4.73-4.78c.42-.43,1-.9,1.57-.61s.42,1.28.11,1.94l-5.22,11.16a28.48,28.48,0,0,1,5.35-3.4l-5.73,9.44a16.17,16.17,0,0,1-2.38,3.31c-2.13,2.14-5.52,3.39-6.25,6.32.16-.63-8.24-3.31-8.83-4.22-1-1.57.84-4.87,1.05-6.75C293.29,216.49,291.85,210.39,294.93,205.61Z"
                transform="translate(0 0)"
                fill="url(#linear-gradient-15)"
            >
            </path>
            <path d="M244.87,397.35l11.54,84.06a22.48,22.48,0,0,1-1.08,10.51L242.52,551.1,222.66,549l9.09-60.47a8.55,8.55,0,0,0-.23-3.73l-15.66-54-3,61.16-25.67,52-18-7.2L192.05,490l-3.67-57.76c-3.25-20.1,2.69-34.94,2.69-34.94Z" transform="translate(0 0)" fill={Color.GREY_DARK}></path>
            <rect
                x="191.03"
                y="388.4"
                width="53.84"
                height="8.95"
                fill={Color.MIDNIGHT_LIGHT}
            >
            </rect>
            <rect
                x="191.03"
                y="388.4"
                width="53.84"
                height="8.95"
                fill="url(#linear-gradient-16)"
            >
            </rect>
            <path d="M304,234.6l-50.65,83.66-2.64,62.79c0,6-.24,11-4.7,12.37a9.29,9.29,0,0,1-2.67.35H198.53A12.82,12.82,0,0,1,185.71,381h0V323.28s-3.3-32.71,12.22-32.71H229a24.5,24.5,0,0,0,19.27-9.33l41.46-54.63Z" transform="translate(0 0)" fill={Color.MIDNIGHT_LIGHT}></path>
            <rect
                x="210.98"
                y="251.37"
                width="15.54"
                height="39.51"
                fill="url(#linear-gradient-17)"
            >
            </rect>
            <path d="M211,247.05v43.84h8.2v-2.67a5.92,5.92,0,0,1,5.92-5.92h.47a14.72,14.72,0,0,0,14.72-14.72h0V247.05Z" transform="translate(0 0)" fill="url(#linear-gradient-18)"></path>
            <path
                d="M246.38,208.71c.31-.65.54-1.33.88-2a14.09,14.09,0,0,1,2.6-3.21l3.24-3.3a1.57,1.57,0,0,1,.88-.56c.67-.08,1.14.74,1,1.4a4.23,4.23,0,0,1-1,1.68,15,15,0,0,0-3,7.41,63.94,63.94,0,0,0-.37,8.07,4.94,4.94,0,0,1-.52,2.56,73.32,73.32,0,0,0-3.38-6.53c-.43-.72-1.74-2.13-1.78-2.94S246.1,209.42,246.38,208.71Z"
                transform="translate(0 0)"
                fill="url(#linear-gradient-19)"
            >
            </path>
            <path
                d="M241.71,240.15a11,11,0,0,0-5.07-7.4V235c-5.81-2.81-12.76-.6-19.13-1.65a9,9,0,0,1,1.76,2.64,26.87,26.87,0,0,0-12,2.83l1,1.46a1.11,1.11,0,0,1,.23.52c0,.39-.37.65-.68.89-1.8,1.37-2,4-1.9,6.26.33,6.84,2.1,13.51,3.86,20.13.67,2.51,1.33,5,2,7.53l1.4-1.93,1.33.93c1.82-2.82,9.76-3.41,10.48-6.69-2-.46-4.39-3.17-4.17-5.22s.88-2.51,2.83-1.85c1.36.45-.87-2.33-1.11-3.46s-.75-2.28-.19-3.25c.77-1.35,2.78-1,4.22-.44a8.77,8.77,0,0,1,8.74-5.86c2,.15,3.93,1.36,5.25-.54S242.1,242.35,241.71,240.15Z"
                transform="translate(0 0)"
                fill="#3f3d56"
            >
            </path>
            <path d="M304,234.6l-50.65,83.66-2.64,62.79c0,6,.11,11.82-6.18,12.72H236.1s7.12-71.8,5.81-78.76,58.6-82.28,58.6-82.28Z" transform="translate(0 0)" fill="url(#linear-gradient-20)"></path>
            <path d="M226,549.32l-3.47,15h45s.47-7.42-8.81-10a36.22,36.22,0,0,0-17.16-.47l-1.19-3Z" transform="translate(0 0)" fill="#3f3d56"></path>
            <path d="M172.87,538.21l-7.92,12,9.28,5.41,2.93-1.54,6.65,6.8,23.35.77s-1.54-11.68-12.37-11.71-9.87-6.9-9.87-6.9Z" transform="translate(0 0)" fill="#3f3d56"></path>
        </svg>
    );
};

export default SvgFavoritesFolder;
