import React, {FunctionComponent, useEffect, useState} from "react";
import {ListTaxonomyElement} from "../index";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {Button, ButtonSize, Loadable} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {TaxonomyPagedElements} from "../../../api/model/taxonomy/TaxonomyPagedElements";
import {scroller} from "react-scroll";

type SearchResultsProps = {
    results: TaxonomyPagedElements;
    filters: TaxonomyFilters;
};

const ListResults: FunctionComponent<SearchResultsProps> = ({results, filters}) => {
    const {t: textTranslation} = useTranslation(TranslationPortalFile.TRANSLATION);
    const [isNextPageLoading, setNextPageLoading] = useState(false);
    const [isNextPageLoaded, setNextPageLoaded] = useState(false);
    const [nextResults, setNextResults] = useState<TaxonomyPagedElements>(new TaxonomyPagedElements());
    const [currentFilters, setCurrentFilters] = useState<TaxonomyFilters>(filters);
    const refIdPrefix = "taxonomy-list__items__page";

    const handleLoadNextPage = () => {
        (async function () {
            try {
                setNextPageLoading(true);
                const nextPageFilters = new TaxonomyFilters({...currentFilters, page: currentFilters.page + 1});
                const results = await session.restTaxonomy.search(nextPageFilters.toTaxonomyQueryParams());
                setNextResults(results);
            } catch (e) {
                console.error("Failed to get results:", e);
            } finally {
                setNextPageLoaded(true);
                scroller.scrollTo(`${refIdPrefix}${currentFilters.page + 1}`, {offset: -100, smooth: true, duration: 1200});
            }
        })();
    };

    useEffect(() => {
        setCurrentFilters(filters);
    }, [results, filters]);

    return (
        <>
            <div id={`${refIdPrefix}${currentFilters.page}`}>
                {results.elements.map((item) =>
                    <ListTaxonomyElement key={item.elementId} item={item} filters={filters}/>
                )}
            </div>
            {isNextPageLoaded ?
                <ListResults results={nextResults} filters={new TaxonomyFilters({...currentFilters, page: currentFilters.page + 1})}/> :
                (!!results.total && results.page + 1 < results.getTotalPages()) &&
                <div className="taxonomy-list__elements__load-more">
                    <Loadable loading={isNextPageLoading} loaderOptions={{small: true}}>
                        <Button cssClass="taxonomy-list__elements__load-more__button" size={ButtonSize.SMALL} onClick={handleLoadNextPage}>
                            {textTranslation("search.load_more")}
                        </Button>
                        <span className="taxonomy-list__elements__load-more__page">
                            {`${results.page + 1} / ${results.getTotalPages()}`}
                        </span>
                    </Loadable>
                </div>
            }
        </>
    );
};

export default ListResults;
