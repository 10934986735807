export type Partner = {
    name: string;
    img: string;
    link: string;
    text?: string;
}

export const MEDIA_PARTNERS: Partner[] = [
    {name: "20 Minutes", img: "/images/partners/20-minutes.png", link: "https://www.20minutes-media.com/"},
    {name: "Altice Media", img: "/images/partners/altice.png", link: "https://alticefrance.com/media"},
    {name: "Canal+", img: "/images/partners/canal.png", link: "https://www.canalplus.com/"},
    {name: "Invibes", img: "/images/partners/invibes.png", link: "https://www.invibes.com/"},
    {name: "Lagardère Publicité News", img: "/images/partners/lagardere-publicite.png", link: "https://www.lagarderepublicitenews.fr/"},
    {name: "Le Point", img: "/images/partners/le-point.png", link: "https://www.lepoint.fr/"},
    {name: "M6 Publicité", img: "/images/partners/m6-publicite.png", link: "https://m6pub.fr/"},
    {name: "MediaSquare", img: "/images/partners/media-square.png", link: "https://www.mediasquare.fr/"},
    {name: "Ouest France", img: "/images/partners/ouest-france.png", link: "https://www.ouest-france.fr/"},
    {name: "Additi Media", img: "/images/partners/additi-media.png", link: "https://additi.ouest-france.fr/"},
    {name: "Prisma", img: "/images/partners/prisma-media.png", link: "https://www.prismamedia.com/"},
    {name: "Seedtag", img: "/images/partners/seedtag.png", link: "https://www.seedtag.com/"},
    {name: "Smile Wanted", img: "/images/partners/smile-wanted.png", link: "https://www.smilewanted.com/"},
    {name: "TF1 Publicité", img: "/images/partners/tf1-publicite.png", link: "https://tf1pub.fr/"},
    {name: "Webedia", img: "/images/partners/webedia.png", link: "https://webedia-group.com/"},
    {name: "FranceTV Publicité", img: "/images/partners/francetv-publicite.png", link: "https://www.francetvpub.fr/"}
];

export const ACTIVATION_PARTNERS: Partner[] = [
    {name: "Adobe", img: "/images/partners/adobe.png", link: "https://www.adobe.com/experience-cloud.html"},
    {name: "Amazon", img: "/images/partners/amazon.png", link: "https://advertising.amazon.com/"},
    {name: "Audiencerate", img: "/images/partners/audiencerate.png", link: "https://www.audiencerate.com/"},
    {name: "Meta", img: "/images/partners/meta.png", link: "https://www.meta.com/"},
    {name: "Freewheel", img: "/images/partners/freewheel.png", link: "https://www.freewheel.com/"},
    {name: "Google Audience Manager", img: "/images/partners/google-ad-manager.png", link: "https://admanager.google.com/intl/fr/home/"},
    {name: "Google DV360", img: "/images/partners/google-dv360.png", link: "https://marketingplatform.google.com/intl/fr/about/display-video-360/"},
    {name: "Magnite", img: "/images/partners/magnite.png", link: "https://www.magnite.com/"},
    {name: "Mediarithmics", img: "/images/partners/mediarithmics.png", link: "https://www.mediarithmics.com/fr-fr"},
    {name: "Numberly", img: "/images/partners/numberly.png", link: "https://numberly.com/fr/"},
    {name: "Nielsen", img: "/images/partners/nielsen.png", link: "https://www.nielsen.com/fr/fr/"},
    {name: "Oracle", img: "/images/partners/oracle.png", link: "https://www.oracle.com/fr/index.html"},
    {name: "Proxistore", img: "/images/partners/proxistore.png", link: "https://www.proxistore.com/"},
    {name: "Pubmatic", img: "/images/partners/pubmatic.png", link: "https://pubmatic.com/"},
    {name: "Equativ", img: "/images/partners/equativ.png", link: "https://equativ.com/"},
    {name: "theTradeDesk", img: "/images/partners/the-trade-desk.png", link: "https://www.thetradedesk.com/"},
    {name: "Xandr", img: "/images/partners/xandr.png", link: "https://www.appnexus.com/"},
    {name: "Yahoo", img: "/images/partners/yahoo.png", link: "https://www.adtech.yahooinc.com/"}
];

export const DATA_PARTNERS: Partner[] = [
    {name: "Kantar TGI", img: "/images/partners/kantar.png", link: "https://www.kantarmedia.com/"},
    {name: "Kantar Worldpanel", img: "/images/partners/kantar.png", link: "https://www.kantarworldpanel.com/global/Consumer-Panels"},
    {name: "Proxistore", img: "/images/partners/proxistore.png", link: "https://www.proxistore.com/"}
];
