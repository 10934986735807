import React, {FunctionComponent, useState} from "react";
import clsx from "clsx";
import {Action, Alert, Icon, IconTooltip, Loadable} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";

type FavoriteFolderButtonProps = {
    onSelect: (folder: FolderFavorites) => void;
};

const FavoriteFolderButton: FunctionComponent<FavoriteFolderButtonProps> = ({onSelect}) => {
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);
    const [folders, setFolders] = useState<FolderFavorites[]>([]);
    const [highlightedFolder, setHighlightedFolder] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isShowFavorites, setShowFavorites] = useState<boolean>(false);

    const handleClickFavoriteFolder = (folder: FolderFavorites) => {
        setHighlightedFolder(folder.name);
        onSelect(folder);
        setTimeout(() => setHighlightedFolder(""), 1000);
    };

    const handleShowFavorites = () => {
        setShowFavorites(true);
        (async function () {
            try {
                setLoading(true);
                setFolders(await session.getFavoriteFolders());
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    };

    return (
        <div
            className={clsx("favorite-button", {"favorite-button--active": isShowFavorites})}
            onClick={(e) => e.stopPropagation()}
            onMouseLeave={() => setShowFavorites(false)}
        >
            <IconTooltip
                icon={{name: "favorite"}}
                text={textFavorites("actions.add_your_favorites")}
                cssClass={clsx("favorite-button__icon", "favorite-button__icon--big")}
                onClick={handleShowFavorites}
            />
            <div className="favorite-button__overlay" onClick={() => setShowFavorites(false)}/>
            <div className="favorite-button__content">
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    {folders.length ?
                        folders.map((folder) =>
                            <div key={folder.id} className="favorite-item" onClick={() => handleClickFavoriteFolder(folder)}>
                                {highlightedFolder === folder.name ?
                                    <>
                                        <Icon name="check"/>
                                        <span className="favorite-item__name">{textFavorites("actions.added")}</span>
                                    </> :
                                    <>
                                        <Icon {...Action.ADD.icon}/>
                                        <span className="favorite-item__name">{folder.name}</span>
                                    </>
                                }
                            </div>
                        ) : <Alert text={textFavorites("info.no_favorites")}/>
                    }
                </Loadable>
            </div>
        </div>
    );
};

export default FavoriteFolderButton;
