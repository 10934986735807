import React, {FunctionComponent} from "react";
import {Color, Tag, TagSize, TagTextTransform} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

const TagBrandSafety: FunctionComponent = () => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    return (
        <Tag
            size={TagSize.SMALL}
            customColor={{backgroundColor: Color.CORAL_LIGHTER, color: Color.CORAL, borderColor: Color.CORAL_LIGHT}}
            label={textTaxonomy("tag.brand_safety")}
            textTransform={TagTextTransform.CAPITALIZE}
            icon={{name: "security"}}
        />
    );
};

export default TagBrandSafety;
