import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Tag, TagSize, TagStyle, TagTextTransform} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {getSegmentDataTypeItem, SegmentDataType} from "../../../api/model/segment/SegmentDataType";
import {TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {getTaxonomyTagIcon} from "../../../utils/taxonomy/TaxonomyTag";

type TagUserSegmentTypeProps = {
    type: SegmentDataType;
};

const TagUserSegmentType: FunctionComponent<TagUserSegmentTypeProps> = ({type}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    if (!type) return (<></>);
    const typeItem = getSegmentDataTypeItem(type);
    if (!typeItem) return (<></>);

    return (
        <span className="tag-user-segment-type">
            <Tag
                size={TagSize.SMALL}
                style={TagStyle.PRIMARY_CYAN_LIGHT}
                label={TaxonomyTargetingType.USER}
                textTransform={TagTextTransform.CAPITALIZE}
                icon={getTaxonomyTagIcon(TaxonomyTargetingType.USER)}
            />
            <Tag
                size={TagSize.SMALL}
                style={TagStyle.DEFAULT_CYAN_LIGHT}
                label={textTaxonomy(`data_type.${typeItem.type}`)}
                textTransform={TagTextTransform.CAPITALIZE}
                icon={typeItem.icon}
            />
        </span>
    );
};

export default TagUserSegmentType;
