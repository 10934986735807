import {ApiModel} from "../../../common/api/model/ApiModel";
import {TaxonomyPagedElements} from "./TaxonomyPagedElements";

export type TaxonomyPathItem = {
    name: string;
    link: string;
}

export class TaxonomyNode extends ApiModel {
    path_element: TaxonomyPathItem = {name: "", link: ""};
    top_path_element?: TaxonomyPathItem;
    parent_path_element?: TaxonomyPathItem;
    count: number = 0;
    children?: TaxonomyNode[];
    elements?: TaxonomyPagedElements;
    expanded: boolean = false;
    loaded: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;

        for (const [key, value] of Object.entries(o)) {
            switch (key) {
                case "children":
                    if (!value) break;
                    let nodes: TaxonomyNode[] = [];
                    (value as object[]).forEach((x: object) => {
                        const node = new TaxonomyNode();
                        node.load(x);
                        nodes.push(node);
                    });
                    this.children = nodes;
                    break;
                case "elements":
                    if (!value) break;
                    const elements = new TaxonomyPagedElements();
                    elements.load(value);
                    this.elements = elements;
                    break;
                default:
                    this[key] = value;
            }
        }
    }

}
