import {ApiModel} from "../../../common/api/model/ApiModel";

export class CuratedDealRequest extends ApiModel {
    email: string = "";
    title: string = "";
    vertical: string = "";
    platform: string = "";
    deal_id: string = "";
    dsp_name: string = "";
    seat_id: string = "";

    getJsonParameters(): {} {
        return {
            email: this.email,
            title: this.title,
            vertical: this.vertical,
            platform: this.platform,
            deal_id: this.deal_id,
            dsp_name: this.dsp_name,
            seat_id: this.seat_id
        };
    }
}
