export enum CampaignBriefObjective {
    BRANDING = "BRANDING",
    PERFORMANCE = "PERFORMANCE"
}

export const CAMPAIGN_BRIEF_OBJECTIVES: CampaignBriefObjective[] = [
    CampaignBriefObjective.BRANDING,
    CampaignBriefObjective.PERFORMANCE
];

export const defaultCampaignBriefObjective = CAMPAIGN_BRIEF_OBJECTIVES[0];
