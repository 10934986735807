import {FunctionComponent, useEffect, useState} from "react";
import clsx from "clsx";
import {Color, Icon, InputRadio, InputToggleSwitch, Select} from "@sirdata/ui-lib";
import {SEGMENT_DATATYPES, SegmentDataType} from "../../../api/model/segment/SegmentDataType";
import {SEGMENT_STEP_SIZES, SegmentStepSize} from "../../../api/model/segment/SegmentStepSize";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {getTaxonomyTag, getTaxonomyTagColor} from "../../../utils/taxonomy/TaxonomyTag";
import {TAXONOMY_TARGETING_TYPES, TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {TAXONOMY_MODELING_TYPES, TaxonomyModelingType} from "../../../utils/taxonomy/TaxonomyModelingType";
import {TAXONOMY_ACTIVATION_TYPES, TaxonomyActivationType} from "../../../utils/taxonomy/TaxonomyActivationType";
import {CURATED_DEAL_STEP_SIZES, CuratedDealStepSize} from "../../../api/model/curated-deal/CuratedDealStepSize";

type TaxonomyFilterToolbarProps = {
    initialFilters: TaxonomyFilters;
    onChange: (filters: TaxonomyFilters, requireRefresh: boolean) => void;
};

const TaxonomyFilterToolbar: FunctionComponent<TaxonomyFilterToolbarProps> = ({initialFilters, onChange}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());

    const handleChangeSegmentDataTypes = (dataType: SegmentDataType) => {
        let dataTypes: SegmentDataType[] = [...(filters.segmentDataTypes || [])];
        if (dataTypes.find((it) => it === dataType)) {
            dataTypes = dataTypes.filter((it) => it !== dataType);
        } else {
            dataTypes.push(dataType);
        }
        updateFilter("segmentDataTypes", dataTypes);
    };

    const handleClearSegmentDataTypes = () => {
        updateFilter("segmentDataTypes", undefined);
    };

    const updateFilter = (name: string, value: any) => {
        const newFilters = new TaxonomyFilters({...filters, [name]: value});
        newFilters.preventBadScenarios();
        setFilters(newFilters);
        onChange(newFilters, true);
    };

    const isDisabledTargetingType = (type: TaxonomyTargetingType) => {
        if (filters.modelingType === TaxonomyModelingType.COOKIE) {
            return (type === TaxonomyTargetingType.ALL || type === TaxonomyTargetingType.CONTEXTUAL);
        }
        return false;
    };

    const isDisabledModelingType = (type: TaxonomyModelingType) => {
        if (filters.targetingType === TaxonomyTargetingType.CONTEXTUAL) {
            return (type === TaxonomyModelingType.ALL || type === TaxonomyModelingType.COOKIE);
        }
        return false;
    };

    const isDisabledActivationType = (type: TaxonomyActivationType) => {
        if (filters.targetingType === TaxonomyTargetingType.USER) {
            return (type === TaxonomyActivationType.ALL || type === TaxonomyActivationType.DEAL);
        }
        return false;
    };

    useEffect(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    return (
        <div className="taxonomy-filters">
            <span className="h2 taxonomy-filters__title">
                <Icon name="filter_alt" cssClass="taxonomy-filters__title__icon"/>
                {textTaxonomy("filters.title")}
            </span>
            <div className="taxonomy-filters__content">
                <div className="filter-group">
                    <span className="h3">{textTaxonomy("filters.targeting_type.title")}</span>
                    <div className="filter-group__items">
                        {TAXONOMY_TARGETING_TYPES.map((type) =>
                            <div
                                key={type}
                                className={clsx("filter-item",
                                    {"filter-item--has-subitems": (type === TaxonomyTargetingType.USER || type === TaxonomyTargetingType.CONTEXTUAL) && filters.activationType !== TaxonomyActivationType.DEAL},
                                    {"filter-item--selected": filters.targetingType === type},
                                    {"filter-item--disabled": isDisabledTargetingType(type)})}
                                style={{color: getTaxonomyTagColor(type)}}
                            >
                                <label htmlFor={`targeting_type.${type}`} className="filter-item__label">
                                    <Icon {...getTaxonomyTag(type)?.icon || {name: ""}} cssClass="filter-item__icon"/>
                                    <span>{textTaxonomy(`filters.targeting_type.${type}`)}</span>
                                </label>
                                <InputRadio
                                    id={`targeting_type.${type}`}
                                    value={type}
                                    checked={filters.targetingType === type}
                                    onChange={(value) => isDisabledTargetingType(type) ? () => {} : updateFilter("targetingType", value)}
                                />
                            </div>
                        )}
                    </div>
                    <div className={clsx("filter-group__subitems", {"filter-group__subitems--hidden": filters.targetingType !== TaxonomyTargetingType.USER || filters.activationType === TaxonomyActivationType.DEAL})}>
                        <div className="filter-subitem">
                            <div>
                                <div className="filter-subitem__label">{textTaxonomy("filters.data_type.title")}</div>
                                <div className="filter-subitem__full-content">
                                    <span className={clsx("filter-button", {"filter-button--active": !filters.segmentDataTypes?.length})} onClick={handleClearSegmentDataTypes}>
                                        {textTaxonomy("data_type.all")}
                                    </span>
                                    {SEGMENT_DATATYPES.map((it) =>
                                        <span key={it.type} className={clsx("filter-button", {"filter-button--active": filters.segmentDataTypes?.includes(it.type)})} onClick={() => handleChangeSegmentDataTypes(it.type)}>
                                            {textTaxonomy(`data_type.${it.type}`)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="filter-subitem">
                            <span className="filter-subitem__label">{textTaxonomy("filters.volume.title")}</span>
                            <div className="filter-subitem__content">
                                <Select
                                    value={filters.segmentStep.value}
                                    options={SEGMENT_STEP_SIZES.filter((it) => it.value !== SegmentStepSize.STEP1).map((it) => ({value: it.value, label: it.value === SegmentStepSize.STEP0 ? textTaxonomy("filters.volume.all") : it.label, segmentStep: it}))}
                                    onChange={(option) => updateFilter("segmentStep", option?.segmentStep)}
                                    small
                                />
                            </div>
                        </div>
                    </div>
                    <div className={clsx("filter-group__subitems", {"filter-group__subitems--hidden": filters.targetingType !== TaxonomyTargetingType.CONTEXTUAL || filters.activationType === TaxonomyActivationType.DEAL})}>
                        <div className="filter-subitem">
                            <span className="filter-subitem__label filter-subitem__label--with-icon">
                                <Icon name="security" colorIcon={Color.CORAL}/>{textTaxonomy("filters.brand_safety.title")}
                            </span>
                            <InputToggleSwitch
                                checked={filters.categoryBrandSafety}
                                onChange={(value) => updateFilter("categoryBrandSafety", value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter-group">
                    <span className="h3">{textTaxonomy("filters.modeling_type.title")}</span>
                    <div className="filter-group__items">
                        {TAXONOMY_MODELING_TYPES.map((type) =>
                            <div
                                key={type}
                                className={clsx("filter-item",
                                    {"filter-item--selected": filters.modelingType === type},
                                    {"filter-item--disabled": isDisabledModelingType(type)})}
                                style={{color: getTaxonomyTagColor(type)}}
                            >
                                <label htmlFor={`modeling_type.${type}`} className="filter-item__label">
                                    <Icon {...getTaxonomyTag(type)?.icon || {name: ""}} cssClass="filter-item__icon"/>
                                    <span>{textTaxonomy(`filters.modeling_type.${type}`)}</span>
                                </label>
                                <InputRadio
                                    id={`modeling_type.${type}`}
                                    value={type}
                                    checked={filters.modelingType === type}
                                    onChange={(value) => isDisabledModelingType(type) ? () => {} : updateFilter("modelingType", value)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="filter-group">
                    <span className="h3">{textTaxonomy("filters.activation_type.title")}</span>
                    <div className="filter-group__items">
                        {TAXONOMY_ACTIVATION_TYPES.map((type) =>
                            <div
                                key={type}
                                className={clsx("filter-item",
                                    {"filter-item--has-subitems": type === TaxonomyActivationType.DEAL},
                                    {"filter-item--selected": filters.activationType === type},
                                    {"filter-item--disabled": isDisabledActivationType(type)})}
                                style={{color: getTaxonomyTagColor(type)}}
                            >
                                <label htmlFor={`activation_type.${type}`} className="filter-item__label">
                                    <Icon {...getTaxonomyTag(type)?.icon || {name: ""}} cssClass="filter-item__icon"/>
                                    <span>{textTaxonomy(`filters.activation_type.${type}`)}</span>
                                </label>
                                <InputRadio
                                    id={`activation_type.${type}`}
                                    value={type}
                                    checked={filters.activationType === type}
                                    onChange={(value) => isDisabledActivationType(type) ? () => {} : updateFilter("activationType", value)}
                                />
                            </div>
                        )}
                    </div>
                    <div className={clsx("filter-group__subitems", {"filter-group__subitems--hidden": filters.activationType !== TaxonomyActivationType.DEAL})}>
                        <div className="filter-subitem">
                            <span className="filter-subitem__label">{textTaxonomy("filters.volume.title")}</span>
                            <div className="filter-subitem__content">
                                <Select
                                    value={filters.dealStep.value}
                                    options={CURATED_DEAL_STEP_SIZES.map((it) => ({value: it.value, label: it.value === CuratedDealStepSize.STEP_0 ? textTaxonomy("filters.volume.all") : it.label, dealStep: it}))}
                                    onChange={(option) => updateFilter("dealStep", option?.dealStep)}
                                    small
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaxonomyFilterToolbar;