import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Icon, Loadable} from "@sirdata/ui-lib";
import {TreeTaxonomyNode} from "../index";
import {TaxonomyNode} from "../../../api/model/taxonomy/TaxonomyNode";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {session} from "../../../api/ApiSession";

type TreeProps = {
    items: TaxonomyNode[];
    filters: TaxonomyFilters;
    onUpdate: (nodes: TaxonomyNode[]) => void;
    title?: string;
    loading?: boolean;
};

const Tree: FunctionComponent<TreeProps> = ({items, filters, onUpdate, loading, title}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [nodes, setNodes] = useState<TaxonomyNode[]>([]);

    const doCollapseNodes = (nodes: TaxonomyNode[]): TaxonomyNode[] => {
        return nodes.map((it) => {
            it.expanded = false;
            if (it.children) it.children = doCollapseNodes(it.children);
            return it;
        });
    };

    const handleCollapseAll = () => {
        const newNodes = doCollapseNodes(nodes);
        onUpdate(newNodes);
    };

    const handleClickNode = async (node: TaxonomyNode) => {
        if (!node.loaded) {
            try {
                const params = filters.toTaxonomyQueryParams();
                params.link = node.path_element.link;
                const result = await session.restTaxonomy.getNode(params);
                node.children = result.children;
                node.elements = result.elements;
                node.loaded = true;
            } catch (e) {
                console.error("Failed to load node:", node.path_element.link, e);
            }
        }

        node.expanded = !node.expanded;
        if (node.children && !node.expanded) node.children = doCollapseNodes(node.children);
        onUpdate([node]);
    };

    useEffect(() => {
        setNodes(items);
    }, [items]);

    return (
        <div className="taxonomy-tree">
            <div className="taxonomy-tree__title">
                {title && <span className="taxonomy-tree__title__label" dangerouslySetInnerHTML={{__html: title}}/>}
                <span className="taxonomy-tree__title__link" onClick={handleCollapseAll}>
                    <Icon name="keyboard_double_arrow_up"/>
                    <span>{textTaxonomy("collapse_all")}</span>
                </span>
            </div>
            <Loadable loading={!!loading}>
                {nodes.map((node) =>
                    <TreeTaxonomyNode key={node.path_element.name} item={node} filters={filters} onClick={handleClickNode}/>
                )}
            </Loadable>
        </div>
    );
};

export default Tree;
