import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Keyword} from "./Keyword";

export class KeywordsQuery extends ApiModel {
    keywords: string[] = [];

    constructor(keywords?: string[] | Keyword[]) {
        super();
        if (keywords) {
            this.keywords = keywords.map((k) => typeof k === "object" ? k.keyword : k);
        }
    }

    toJson(): string {
        return JSON.stringify(this.keywords);
    }
}
