import React, {useEffect, useState} from "react";
import {TranslationPortalFile} from "../../utils/constants";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import ModalCreateCustomCategory from "../../component/modal/ModalCreateCustomCategory";
import ModalEditCustomCategory from "../../component/modal/ModalEditCustomCategory";
import {CategoryTableRow} from "../../component/snippet";
import SvgContextualCustom from "../../component/svg/SvgContextualCustom";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Action, AlertItem, AlertSeverity, Button, ButtonAdd, ButtonSize, ButtonStyle, ContentBlock, EmptyContentMessage, LayoutRows, Loadable, MainAlert, MainContentPageHeader, ModalConfirmMessage, SearchError, SearchToolbar, Table, TableColumnStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {MainHeader} from "../../common/component/snippet";

const MAX_ROWS_PER_PAGE = 20;

function ContextualCustom() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContextualCustom} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM);
    const [categories, setCategories] = useState<CustomCategory[]>([]);
    const [currentCategories, setCurrentCategories] = useState<CustomCategory[]>([]);
    const [isLoading, setLoading] = useState(true);

    const [isShowNewCategoryModal, setShowNewCategoryModal] = useState<boolean>(false);
    const [activeDeleteCategory, setActiveDeleteCategory] = useState<CustomCategory>();
    const [activeEditCategory, setActiveEditCategory] = useState<CustomCategory>();
    const [message, setMessage] = useState<AlertItem>();

    const [query, setQuery] = useState<string>("");

    useEffect(() => {
        let currentCategories = (categories || []);
        if (query) {
            currentCategories = currentCategories.filter((category) => (category.name.toLowerCase().includes(query.toLowerCase()) || category.description.toLowerCase().includes(query.toLowerCase())));
        }
        setCurrentCategories(currentCategories);
    }, [categories, query]);

    const onChangeQuery = (query: string) => {
        setQuery(query);
    };

    const doDeleteCategory = async (category: CustomCategory) => {
        setMessage(undefined);
        try {
            await session.restCustomCategory.deleteCustomCategory(category);
            setCategories((prevState) => {
                const newState = [...prevState];
                newState.splice(newState.findIndex((c) => c === category), 1);
                return newState;
            });
            setActiveDeleteCategory(undefined);
        } catch (e) {
            setMessage({text: textContextualCustom("error.delete_custom_category"), severity: AlertSeverity.DANGER});
        }
    };

    const doToggleCategory = async (category: CustomCategory) => {
        setMessage(undefined);
        try {
            category.active = !category.active;
            await session.restCustomCategory.updateCustomCategory(category);
            setCategories((prevState) => {
                const newState = [...prevState];
                const index = newState.findIndex((c) => c === category);
                newState[index] = category;
                return newState;
            });
        } catch (e) {
            if (category.active) {
                setMessage({text: textContextualCustom("error.enable_custom_category"), severity: AlertSeverity.DANGER});
            } else {
                setMessage({text: textContextualCustom("error.disable_custom_category"), severity: AlertSeverity.DANGER});
            }
        }
    };

    const doUpdateCategory = async (category: CustomCategory) => {
        setMessage(undefined);
        try {
            await session.restCustomCategory.updateCustomCategory(category);
            setCategories((prevState) => {
                const newState = [...prevState];
                const index = newState.findIndex((c) => c.id_group === category.id_group);
                if (index !== -1) {
                    newState[index] = category;
                }
                return newState;
            });
        } catch (e) {
            setMessage({text: textContextualCustom("error.update_custom_category"), severity: AlertSeverity.DANGER});
        }
        setActiveEditCategory(undefined);
    };

    useEffect(() => {
        (async function () {
            try {
                const customCategories = await session.restCustomCategory.list();
                setCategories(customCategories);
            } catch (e) {
                setMessage({text: textContextualCustom("error.get_custom_categories"), severity: AlertSeverity.DANGER});
            } finally {
                setLoading(false);
            }
        })();
    }, [textContextualCustom]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textContextualCustom("title")} icon={Action.EDIT.icon}/>
                <MainAlert content={message}/>
                <LayoutRows>
                    {categories.length > 0 ?
                        <ContentBlock>
                            <SearchToolbar
                                searchBar={{placeholder: textContextualCustom("search_for_categories"), onChange: onChangeQuery}}
                                actions={[<ButtonAdd key={"button-add-category"} onClick={() => setShowNewCategoryModal(true)} size={ButtonSize.MEDIUM}/>]}
                            />
                            <Loadable loading={isLoading}>
                                {currentCategories.length > 0 ?
                                    <Table
                                        columns={[
                                            {width: 10, label: textContextualCustom("table.headers.status")},
                                            {width: 10, label: textContextualCustom("table.headers.id")},
                                            {width: 20, label: textContextualCustom("table.headers.name")},
                                            {width: 40, label: textContextualCustom("table.headers.description"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {width: 15, label: textContextualCustom("table.headers.urls"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {width: 5, label: textContextualCustom("table.headers.actions"), styles: TableColumnStyle.ALIGN_CENTER}
                                        ]}
                                        pagination={MAX_ROWS_PER_PAGE}
                                    >
                                        {currentCategories.map((category) =>
                                            <CategoryTableRow
                                                key={category.id}
                                                category={category}
                                                onEdit={() => setActiveEditCategory(category)}
                                                onToggleActive={() => doToggleCategory(category)}
                                                onDelete={() => setActiveDeleteCategory(category)}
                                            />
                                        )}
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                            </Loadable>
                        </ContentBlock> :
                        <EmptyContentMessage svg={SvgContextualCustom} message={textContextualCustom("table.message.text")}>
                            <Button onClick={() => setShowNewCategoryModal(true)}>
                                {textContextualCustom("table.message.start")}
                            </Button>
                        </EmptyContentMessage>
                    }
                </LayoutRows>
                <ModalCreateCustomCategory onClose={() => setShowNewCategoryModal(false)} active={isShowNewCategoryModal}/>
                <ModalConfirmMessage
                    message={textContextualCustom("table.actions.delete_warning")}
                    confirm={{style: ButtonStyle.DEFAULT_MIDNIGHT, onClick: () => doDeleteCategory(activeDeleteCategory!), children: textCommon(Action.DELETE.labelKey)}}
                    cancel={{style: ButtonStyle.PRIMARY_MIDNIGHT, onClick: () => setActiveDeleteCategory(undefined)}}
                    active={!!activeDeleteCategory}
                />
                <ModalEditCustomCategory onClose={() => setActiveEditCategory(undefined)} category={activeEditCategory} onSubmit={(category) => doUpdateCategory(category)}/>
            </MainContent>
        </Wrapper>
    );
}

export default ContextualCustom;
