import {ApiModel} from "../../../common/api/model/ApiModel";
import {Category} from "../category/Category";
import {CuratedDeal} from "../curated-deal/CuratedDeal";
import {Segment} from "../segment/Segment";
import {TaxonomyElementType} from "./TaxonomyElementType";

export class TaxonomyElement extends ApiModel {
    type: TaxonomyElementType = TaxonomyElementType.SEGMENT;
    audience_category?: Category;
    audience_segment?: Segment;
    audience_curated_deal?: CuratedDeal;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;

        for (const [key, value] of Object.entries(o)) {
            if (!value) continue;
            switch (key) {
                case "audience_category":
                    const category = new Category();
                    category.load(value);
                    this.audience_category = category;
                    break;
                case "audience_segment":
                    const segment = new Segment();
                    segment.load(value);
                    this.audience_segment = segment;
                    break;
                case "audience_curated_deal":
                    const deal = new CuratedDeal();
                    deal.load(value);
                    this.audience_curated_deal = deal;
                    break;
                default:
                    this[key] = value;
            }
        }
    }

    get elementId(): number {
        let elementId;
        switch (this.type) {
            case TaxonomyElementType.SEGMENT:
                elementId = this.audience_segment?.id;
                break;
            case TaxonomyElementType.CATEGORY:
                elementId = this.audience_category?.id;
                break;
            case TaxonomyElementType.CURATED_DEAL:
                elementId = this.audience_curated_deal?.id;
                break;
            default:
                break;
        }
        return elementId || 0;
    }
}
