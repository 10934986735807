import React, {FunctionComponent} from "react";
import {Partner} from "../../api/model/Partner";
import {Box, BoxProps, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing} from "@sirdata/ui-lib";

type PartnerCardProps = {
    partner: Partner;
};

const PartnerCard: FunctionComponent<PartnerCardProps> = ({partner}) => {
    return (
        <Box
            cssClass="grid-card"
            onClick={() => window.open(partner.link, "_blank")}
            {...BoxProps.SECTION_BLOCK_WITH_SHADOW}
        >
            <FlexContent
                direction={FlexContentDirection.COLUMN}
                alignment={FlexContentAlignment.CENTER}
                spacing={FlexContentSpacing.MEDIUM}
            >
                <FlexContent
                    direction={FlexContentDirection.COLUMN}
                    alignment={FlexContentAlignment.CENTER}
                    justify={FlexContentJustify.CENTER}
                    cssClass="grid-card__logo"
                >
                    <img src={partner.img} alt={`Logo ${partner.name}`}/>
                </FlexContent>
                <span className="grid-card__name">{partner.name}</span>
            </FlexContent>
        </Box>
    );
};

export default PartnerCard;
