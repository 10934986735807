import React from "react";

const TagKantarMedia: React.FC = () => {
    return (
        <span className="tag-kantar-media">
            <img src="/images/logo-kantar-media.png" alt="Kantar Media"/>
        </span>
    );
};

export default TagKantarMedia;
