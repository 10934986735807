import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Threshold} from "./Threshold";


export class Keyword extends ApiModel {
    keyword: string = "";
    threshold: number = Threshold.THRESHOLD_500;

    constructor(keyword?: string, threshold?: number) {
        super();
        this.keyword = keyword ? keyword : "";
        this.threshold = threshold ? threshold : Threshold.THRESHOLD_500;
    }
}
