import {Formatter} from "../../../common/utils/Formatter";

export enum CuratedDealStepSize {
    STEP_0 = "",
    STEP_10M = "10M",
    STEP_30M = "30M",
    STEP_50M = "50M",
    STEP_100M = "100M",
    STEP_150M = "150M",
    STEP_200M = "200M",
    STEP_250M = "250M",
    STEP_300M = "300M"
}

export type CuratedDealStepSizeItem = {
    value: CuratedDealStepSize;
    label: string;
    minSize: number;
}

export const CURATED_DEAL_STEP_SIZES: CuratedDealStepSizeItem[] = [
    {value: CuratedDealStepSize.STEP_0, label: "", minSize: 0},
    {value: CuratedDealStepSize.STEP_10M, label: "> 10M", minSize: 10000000},
    {value: CuratedDealStepSize.STEP_30M, label: "> 30M", minSize: 30000000},
    {value: CuratedDealStepSize.STEP_50M, label: "> 50M", minSize: 50000000},
    {value: CuratedDealStepSize.STEP_100M, label: "> 100M", minSize: 100000000},
    {value: CuratedDealStepSize.STEP_150M, label: "> 150M", minSize: 150000000},
    {value: CuratedDealStepSize.STEP_200M, label: "> 200M", minSize: 200000000},
    {value: CuratedDealStepSize.STEP_250M, label: "> 250M", minSize: 250000000},
    {value: CuratedDealStepSize.STEP_300M, label: "> 300M", minSize: 300000000}
];

export const defaultCuratedDealStepSizeItem = CURATED_DEAL_STEP_SIZES[0];

export function getCuratedDealStepSize(stepSize: CuratedDealStepSize): CuratedDealStepSizeItem | undefined {
    return CURATED_DEAL_STEP_SIZES.find(((it) => it.value === stepSize));
}

export function getCuratedDealStepFromValue(value: number): string {
    if (!value) return "-";
    const stepSize = CURATED_DEAL_STEP_SIZES.filter((step) => value > step.minSize)?.pop();
    return stepSize?.label || Formatter.formatNumber(value);
}
