import {Rest} from "../../common/api/rest/Rest";
import {Category} from "../model/category/Category";
import {CustomCategory} from "../model/category/custom/CustomCategory";
import {KeywordsQuery} from "../model/category/custom/KeywordsQuery";
import {KeywordsOverview} from "../model/category/custom/KeywordsOverview";
import {KeywordStats} from "../model/category/custom/KeywordStats";
import {Keyword} from "../model/category/custom/Keyword";
import {session} from "../ApiSession";

export class RestCustomCategory extends Rest {
    rootPath = `/audience-api/partner/${session.partnerId}/categories`;
    keywordsRootPath = "/audience-api/categorizer-keywords";

    list(): Promise<CustomCategory[]> {
        return this._client.get(`${this.rootPath}`, CustomCategory) as Promise<CustomCategory[]>;
    }

    getCustomCategory(groupId: number): Promise<CustomCategory> {
        return this._client.get(`${this.rootPath}/${groupId}`, CustomCategory) as Promise<CustomCategory>;
    }

    createCustomCategory(category: CustomCategory): Promise<CustomCategory> {
        return this._client.post(`${this.rootPath}`, category, CustomCategory) as Promise<CustomCategory>;
    }

    updateCustomCategory(category: CustomCategory): Promise<CustomCategory> {
        return this._client.put(`${this.rootPath}/${category.id_group}`, category, CustomCategory) as Promise<CustomCategory>;
    }

    deleteCustomCategory(category: CustomCategory) {
        return this._client.delete(`${this.rootPath}/${category.id_group}`);
    }

    getSuggestedKeywords(query: string, strict: boolean): Promise<KeywordsQuery> {
        return this._client.get(`${this.keywordsRootPath}?q=${query}&strict=${strict}`, KeywordsQuery) as Promise<KeywordsQuery>;
    }

    getKeywordsStats(keywords: string[] | Keyword[]): Promise<KeywordStats[]> {
        return this._client.post(`${this.keywordsRootPath}/stats`, new KeywordsQuery(keywords), KeywordStats) as Promise<KeywordStats[]>;
    }

    getKeywordsOverview(keywords: string[] | Keyword[]): Promise<KeywordsOverview> {
        return this._client.post(`${this.keywordsRootPath}/overview`, new KeywordsQuery(keywords), KeywordsOverview) as Promise<KeywordsOverview>;
    }

    getSuggestedCategories(keywords: string[] | Keyword[]): Promise<Category[]> {
        return this._client.post(`${this.keywordsRootPath}/categories`, new KeywordsQuery(keywords), Category) as Promise<Category[]>;
    }
}
