import React, {FunctionComponent, useEffect, useState} from "react";
import {Segment} from "../../../api/model/segment/Segment";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Action, Button, ButtonSize, ButtonStyle, Icon, IconTooltip, Loadable, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {getTaxonomyPlatform, getTaxonomyPlatformItem, SEGMENT_PLATFORMS, TaxonomyPlatform, TaxonomyPlatformItem} from "../../../api/model/taxonomy/TaxonomyPlatform";
import copy from "copy-to-clipboard";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {TaxonomyElementType} from "../../../api/model/taxonomy/TaxonomyElementType";
import {ModalContactUiEvent} from "../../../common/component/modal/ModalContact";

type SegmentPlatformsProps = {
    segment: Segment;
    platform: string;
    isButtonStyle?: boolean;
};

const SegmentPlatforms: FunctionComponent<SegmentPlatformsProps> = ({segment, platform, isButtonStyle}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isLoading, setLoading] = useState(true);
    const [isActive, setActive] = useState<boolean>();
    const [platformIds, setPlatformIds] = useState<Map<TaxonomyPlatformItem, string>>(new Map<TaxonomyPlatformItem, string>());
    const [copiedPlatform, setCopiedPlatform] = useState<string>();

    const handleCopyId = (name: string, id?: string) => {
        if (!id) return;
        copy(id);
        setCopiedPlatform(name);
        setTimeout(() => setCopiedPlatform(""), 1000);
    };

    const handleActivatePlatform = (platform: TaxonomyPlatformItem) => {
        UIEventManager.emit(ModalContactUiEvent, {
            topicValue: "DISTRIBUTION",
            topicLabel: t("contact.topic.DISTRIBUTION"),
            message: t("contact.message.DISTRIBUTION", {type: "segment", name: `${segment.name} (${segment.id})`, platform: platform?.name})
        });
        setActive(false);
    };

    useEffect(() => {
        if (!isActive) return;
        (async () => {
            const platformIds: Map<TaxonomyPlatformItem, string> = new Map<TaxonomyPlatformItem, string>();
            try {
                const adsConfig = await session.getAdsConfig();
                if (adsConfig) {
                    const adsCategoryId = adsConfig.getMapId(segment.id);
                    if (adsCategoryId) {
                        platformIds.set(getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_ADS), adsCategoryId?.toString() || "");
                    }
                }
            } catch (e) {
            }
            SEGMENT_PLATFORMS.forEach((platform) => {
                platformIds.set(platform, segment.getPlatformId(platform));
            });
            setPlatformIds(platformIds);
            setLoading(false);
        })();
    }, [isActive, segment]);

    return (
        <div className={clsx("activation-platforms", {"activation-platforms--active": isActive})} onClick={(e) => e.stopPropagation()} onMouseLeave={() => setActive(false)}>
            <Tooltip text={textTaxonomy("action.activate_segment")} onClick={() => setActive(true)} cssClass="activation-platforms__button">
                {isButtonStyle ?
                    <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_MIDNIGHT} icon={{name: "ios_share"}}>{textCommon(Action.ACTIVATE.labelKey)}</Button> :
                    <Icon name="ios_share"/>
                }
            </Tooltip>
            <div className="activation-platforms__overlay" onClick={() => setActive(false)}/>
            <div className="activation-platforms__content">
                <Loadable loading={isLoading} loaderOptions={{small: true}}>
                    {
                        Array.from(platformIds).map(([item, value]) =>
                            (!getTaxonomyPlatformItem(TaxonomyElementType.SEGMENT, platform) || item.value === platform) &&
                            <div key={item.name} className="platform-item">
                                <div className="platform-item__info">
                                    <span className="platform-item__name">{item.name}</span>
                                    {value && <span className="platform-item__value">{value}</span>}
                                </div>
                                <span className="platform-item__icon">
                                    {value ?
                                        <IconTooltip
                                            icon={copiedPlatform === item.value ? Action.COPIED.icon : Action.COPY.icon}
                                            text={textTaxonomy("action.copy_id")}
                                            onClick={() => handleCopyId(item.value, value)}
                                        /> :
                                        <IconTooltip
                                            icon={{name: "live_help"}}
                                            text={textTaxonomy("action.request_distribution")}
                                            onClick={() => handleActivatePlatform(item)}
                                        />
                                    }
                                </span>
                            </div>
                        )
                    }
                </Loadable>
            </div>
        </div>
    );
};

export default SegmentPlatforms;
