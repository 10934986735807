import {ApiModel} from "../../../common/api/model/ApiModel";
import {DataSelection} from "./DataSelection";

export class DataSelectionSearchResponse extends ApiModel {
    page: number = 0;
    size: number = 0;
    total_elements: number = 0;
    elements: DataSelection[] = [];

    load(o: any) {
        if (!o) return;

        for (const [key, value] of Object.entries(o)) {
            if (key === "elements") {
                if (value) {
                    (value as DataSelection[]).forEach((obj) => {
                        const it = new DataSelection(obj);
                        this.elements.push(it);
                    });
                }
            } else {
                this[key] = value as number;
            }
        }
    }
}
