import React, {FunctionComponent} from "react";
import {Color} from "@sirdata/ui-lib";

const SvgDataPartners: FunctionComponent = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1176.8 997.93"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="1600.77"
                    y1="689.81"
                    x2="1630.37"
                    y2="1088.75"
                    gradientTransform="matrix(-1, 0, 0, 1, 2214.45, -151.85)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.01"></stop>
                    <stop offset="0.08" stopOpacity="0.69"></stop>
                    <stop offset="0.21" stopOpacity="0.32"></stop>
                    <stop offset="1" stopOpacity="0"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="2047.63"
                    y1="664.47"
                    x2="1704.37"
                    y2="779.79"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="1409.51"
                    y1="717.65"
                    x2="1212.1"
                    y2="692.21"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="1225.26"
                    y1="738.96"
                    x2="1694.7"
                    y2="657.55"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="1983.32"
                    y1="660.64"
                    x2="1807.62"
                    y2="609.08"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="1347.66"
                    y1="861.86"
                    x2="1512.5"
                    y2="828.79"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="1380.06"
                    y1="682.64"
                    x2="1464.51"
                    y2="595.13"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="1309.08"
                    y1="726.87"
                    x2="1495.8"
                    y2="704.99"
                    gradientTransform="matrix(-1, 0, 0, 1, 2264.32, 0)"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
            </defs>
            <path
                d="M445,180.56C380,215.47,332.6,279.31,311.51,350c-12.48,41.87-16.37,86-30.6,127.34C240.42,594.86,123.72,671.31,74,785.17,46.23,848.58,41.46,922.5,64.79,987.65s75.51,120.23,140.78,143.23c40,14.09,83,16.2,125.39,17.55,79.34,2.53,159.31,2.82,237.43-11.27,37.2-6.72,73.86-16.67,111.41-21,72.65-8.4,146.82,4.37,219-7.34,50.26-8.16,97.91-28,143.43-50.8,34.18-17.14,67.78-36.3,96-62.15,74.57-68.4,102.19-179.3,82.16-278.48s-83-186.3-162.25-249.2c-34.84-27.66-72.8-51.08-109.33-76.47A1222.59,1222.59,0,0,1,815,284.09C714.46,190.83,584.77,105.54,445,180.56Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M375.88,931.19l-68.19,9.48c-3.25.45-6.69,1-9.17,3.11-4,3.39-4,9.77-1.53,14.35s6.84,7.77,11.06,10.81c-16.2,3.86-44.34,3-43.52,26.11a22.67,22.67,0,0,0,13.8,19.73c4.79,1.94,10.08,2.15,15.24,2.33,50.42,1.8,101.67,3.5,150.5-9.15,9.31-2.41,20.34-7.68,20.35-17.29,0-3.41-1.5-6.67-1.67-10.08-1.08-21.8,45.53-9,57.55-8.31q40,2.33,80,3.34c54.47,1.45,109,1.37,163.46.6,17.21-.25,34.54-.63,51.53,2.17,13.4,2.2,26.4,6.35,39.56,9.7a338.92,338.92,0,0,0,132.58,6.81c20-3,44.66-13.72,43.36-33.93-1.11-17.34-20.83-26.34-37.61-30.84a579.33,579.33,0,0,0-75.63-15c-45-5.84-90.55-6.37-136-6.74-98.66-.79-197.32-.85-296-.91C452.45,907.48,408.75,926.62,375.88,931.19Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.44"
                style={{isolation: "isolate"}}
            >
            </path>
            <polygon
                points="460.13 751.73 735.5 751.73 686.77 576.6 501.23 576.6 460.13 751.73"
                fill="#6ed216"
            >
            </polygon>
            <polygon
                points="460.13 751.73 735.5 751.73 686.77 576.6 501.23 576.6 460.13 751.73"
                fill="url(#linear-gradient)"
            >
            </polygon>
            <path
                d="M1060.14,260.52V712.07a49.42,49.42,0,0,1-49.43,49.43H297.16a49.42,49.42,0,0,1-49.43-49.43V260.52a49.44,49.44,0,0,1,49.43-49.42h713.55A49.44,49.44,0,0,1,1060.14,260.52Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M354.64,679.2c.39,1.64-.82,3.32-2.34,4a11.41,11.41,0,0,1-5,.71l-7.19-.07a3.5,3.5,0,0,0-2.15.44c-.86.63-1,1.85-1,2.92L336.23,718c-.17,7-.28,14.35,2.84,20.63,1.55,3.12,3.83,5.83,5.47,8.91s2.62,6.81,1.32,10q-18.32.76-36.64.05c-2.27-.09-4.77-.29-6.34-1.93a8.82,8.82,0,0,1-1.72-3.28,261.18,261.18,0,0,1-8.87-31.14c-2.2-10.07-3.81-20.34-3.41-30.64s2.9-20.67,8.4-29.38a19.09,19.09,0,0,1,5-5.69,21.63,21.63,0,0,1,7-2.83c12.11-3.16,24.69-4,37.17-4.86,4.18-.28,8.71-.48,12.2,1.83,3.72,2.45,5.31,7.25,5.08,11.7s-2,8.64-3.73,12.73"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-2)"
            >
            </path>
            <path
                d="M956.77,729q-11.53-38.19-26.32-75.3a5.06,5.06,0,0,0-1.88-2.74,5.14,5.14,0,0,0-3.32-.27l-21.45,3.05c.4,12.08,5.89,23.3,10.58,34.43a288,288,0,0,1,18.15,61.44,15.2,15.2,0,0,0,1.94,6c2,2.87,5.63,4,9,4.69,10.5,2.2,17.35,5.4,19.12-5.28C963.88,747.4,959,736.18,956.77,729Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-3)"
            >
            </path>
            <path
                d="M856.31,735.62l-23-.26c-1.6,0-3.36,0-4.54,1.09s-1.37,2.52-2.22,3.69c-1.86,2.57-5.63,2.57-8.79,2.23a201.93,201.93,0,0,1-32.69-6.26L789.18,708a247.44,247.44,0,0,0,59.63,5.9l1.06-33.84-30.41-.27a6.11,6.11,0,0,1-3.71-.83c-1.42-1.07-1.63-3.09-1.67-4.87a83.9,83.9,0,0,1,.7-12.37,3.68,3.68,0,0,1,1.09-2.59,3.81,3.81,0,0,1,2.85-.37,368.62,368.62,0,0,0,69.89,2.55A358.07,358.07,0,0,0,907.3,762.78c-12.38-1.34-27,.77-39-1.92-5.68-1.28-5.41-1.9-7.24-8C860.61,751.29,857.07,735.63,856.31,735.62Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-4)"
            >
            </path>
            <path
                d="M1060.14,260.52v396H247.73v-396a49.44,49.44,0,0,1,49.43-49.42h713.55A49.44,49.44,0,0,1,1060.14,260.52Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M325.82,634.44c9.46,6.5,19.3,13.17,30.6,15.21,1.39.25,3,.55,3.71,1.76a4.67,4.67,0,0,1,.16,3.38,137.2,137.2,0,0,1-7.17,23.54,118.45,118.45,0,0,1-46.49-16.39c-2.25-1.38-4.53-2.91-5.9-5.16s-1.72-5.27-1.93-8.06c-.59-7.62-1.72-18.52,2.84-25.28,3.56-5.28,5.54-1.81,10.28,1.45Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M320.06,633.17c9.46,6.5,19.3,13.17,30.6,15.21,1.39.25,3,.54,3.71,1.76a4.67,4.67,0,0,1,.16,3.38,137.07,137.07,0,0,1-7.16,23.53,118.34,118.34,0,0,1-46.5-16.39c-2.25-1.37-4.52-2.9-5.9-5.15s-1.72-5.28-1.93-8.06c-.59-7.63-1.72-18.52,2.84-25.28,3.56-5.28,5.54-1.81,10.29,1.45Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-5)"
            >
            </path>
            <path
                d="M1047.91,270V643.32H260V270c0-26.18,21.47-47.42,47.94-47.42H1000C1026.44,222.54,1047.91,243.78,1047.91,270Z"
                transform="translate(-49.87 -151.85)"
                fill="#fff"
            >
            </path>
            <path
                d="M1047.91,270V643.32H260V270c0-26.18,21.47-47.42,47.94-47.42H1000C1026.44,222.54,1047.91,243.78,1047.91,270Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M809.86,394.63a61.8,61.8,0,0,1,5.67-26l-38.77-21.11a106.45,106.45,0,0,0,69.39,150.31L854,454.3A62.45,62.45,0,0,1,809.86,394.63Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M963.64,449.35a106.55,106.55,0,0,1-117.49,48.5L854,454.3a61.32,61.32,0,0,0,18.32,2.75,62.38,62.38,0,0,0,52.59-28.81Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.5"
            >
            </path>
            <path
                d="M978.78,394.63a105.78,105.78,0,0,1-15.14,54.72l-38.78-21.11a62.41,62.41,0,1,0-109.33-59.59l-38.77-21.11a106.5,106.5,0,0,1,202,47.09Z"
                transform="translate(-49.87 -151.85)"
                fill="#fff"
            >
            </path>
            <rect
                x="278.24"
                y="119.61"
                width="259.5"
                height="238.4"
                fill="#6ed216"
                opacity="0.18"
                style={{isolation: "isolate"}}
            >
            </rect>
            <path
                d="M860.7,972.85c-14.25.83-29.51,1.42-41.69-6a5.83,5.83,0,0,1-2.15-1.94,4,4,0,0,1,.38-4.08,8.81,8.81,0,0,1,3.3-2.71c4.44-2.41,9.51-3.31,14.49-4.18,6.14-1.07,12.28-2.15,18.49-2.77,4-.39,10.74-2,14,1.21,2.55,2.54,2,7,1.58,10.26C868.27,968.4,867.15,972.47,860.7,972.85Z"
                transform="translate(-49.87 -151.85)"
                fill="#fff"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M955.11,956.77c-11.24,8.8-23.45,18-37.69,18.81a5.8,5.8,0,0,1-2.87-.37,4,4,0,0,1-2-3.57,8.91,8.91,0,0,1,1.16-4.11c2.28-4.5,5.94-8.14,9.53-11.69,4.44-4.37,8.88-8.76,13.62-12.8,3-2.58,7.7-7.76,12.16-7,3.54.64,5.66,4.66,7.14,7.54C958.8,948.81,960.19,952.79,955.11,956.77Z"
                transform="translate(-49.87 -151.85)"
                fill="#fff"
                style={{isolation: "isolate"}}
            >
            </path>
            <path
                d="M843.75,940.59q.09,7.11.22,14.18a135,135,0,0,0,25.89.72,2.55,2.55,0,0,0,3-2.87c1.84-23.46-1.17-47.16,1.57-70.54a50.42,50.42,0,0,1,3.35-14.27c2-4.58,5-8.6,8-12.64a294.81,294.81,0,0,0,24-39.23c7.21-14.1,9.1-28.68,8.9-44.65-.2-15.39-8.07-22.06-23.09-18.06a12.48,12.48,0,0,0-9.9,7.44c-8.74,14.92-17.51,29.9-24.07,45.9C844.36,848.57,843.19,895,843.75,940.59Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M843.75,940.59q.09,7.11.22,14.18a135,135,0,0,0,25.89.72,2.55,2.55,0,0,0,3-2.87c1.84-23.46-1.17-47.16,1.57-70.54a50.42,50.42,0,0,1,3.35-14.27c2-4.58,5-8.6,8-12.64a294.81,294.81,0,0,0,24-39.23c7.21-14.1,9.1-28.68,8.9-44.65-.2-15.39-8.07-22.06-23.09-18.06a12.48,12.48,0,0,0-9.9,7.44c-8.74,14.92-17.51,29.9-24.07,45.9C844.36,848.57,843.19,895,843.75,940.59Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-6)"
            >
            </path>
            <path
                d="M835.56,669.44a127.17,127.17,0,0,1-73.67-55.19c-2.64-4.28-5.09-9.5-3.18-14.16a2.45,2.45,0,0,1,1.11-1.39c.86-.39,1.83.15,2.63.66l39.71,26c11,7.19,22.26,14.5,35,17.68,1.38,4.52,3.84,7.49,4,12.22S838.72,665.92,835.56,669.44Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <rect
                x="355.46"
                y="360.33"
                width="465.54"
                height="375.14"
                transform="translate(1126.58 943.95) rotate(-180)"
                fill="#fff"
            >
            </rect>
            <path
                d="M755.36,728c2,1.62,4.65,2.22,7.19,2.73a197.5,197.5,0,0,0,51.81,3.35c1.69-.11,3.49-.29,4.79-1.37,1.57-1.29,2-3.5,2.15-5.53a51.66,51.66,0,0,0-1.07-15.88c-.15-.67-42.1-.61-46.09,0C768.18,712.2,745.46,720,755.36,728Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.16"
            >
            </path>
            <path
                d="M394.21,579.79a29.46,29.46,0,0,1,.18,10.17c-1,4.82-4.07,8.89-6.94,12.89a217.24,217.24,0,0,0-29.72,58.26c-3.1-11.27-3-23.13-2.93-34.82a59.54,59.54,0,0,1,.95-12.3c1.22-5.64,4-10.78,6.44-16a120.51,120.51,0,0,0,8.42-25.55c1.3-6.21,3.51-21.95,13.29-20.89C392.13,552.42,393.31,573.56,394.21,579.79Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
                opacity="0.16"
            >
            </path>
            <path
                d="M947.93,784c-3.57,14.85-9.23,29.07-15.79,42.84-5.54,11.62-18.35,26.57-18.29,39.74.1,23.66,25,55.33,40.26,71.57a135.14,135.14,0,0,1-25.77,13.29c-2,.78-4.23,1.52-6.31.9-2.48-.74-4-3.17-5.29-5.41l-16.67-29c-9.29-16.13-18.84-33.09-19.61-51.69-.28-6.73.61-13.44,1.37-20.12,1.78-14.24.16-30.38,3.58-44.22,3.13-12.62,7.23-25.14,10.17-38,1.48-6.43,3.31-13.42,8.64-17.31a22.94,22.94,0,0,1,7.5-3.29c13-3.65,27.06-4.13,34.18,9.35C950.82,762,950.33,774,947.93,784Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M831.71,644.24c-7.08,2.57-.46,19.1,1.44,24a5.62,5.62,0,0,0,2.06,3,5.83,5.83,0,0,0,3.54.42q16-1.49,31.82-3.79a16.8,16.8,0,0,0,6.73-2,11.43,11.43,0,0,0,4.68-7.56c1-5,1.46-16.84-2.84-20.63-3.12-2.74-9.33-.8-13.41-.38C854.2,638.53,842.64,640.26,831.71,644.24Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M831.71,644.24c-7.08,2.57-.46,19.1,1.44,24a5.62,5.62,0,0,0,2.06,3,5.83,5.83,0,0,0,3.54.42q16-1.49,31.82-3.79a16.8,16.8,0,0,0,6.73-2,11.43,11.43,0,0,0,4.68-7.56c1-5,1.46-16.84-2.84-20.63-3.12-2.74-9.33-.8-13.41-.38C854.2,638.53,842.64,640.26,831.71,644.24Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-7)"
            >
            </path>
            <path
                d="M852.33,583.22c-1.26,2.1-3.81,3.33-4.64,5.63-1,2.78.94,5.65,1.93,8.43,1.38,3.88,1,8.22,2.3,12.11a4.6,4.6,0,0,0,1.37,2.19,5.06,5.06,0,0,0,2.06.81,29.75,29.75,0,0,0,8.78.51,2.62,2.62,0,0,1,1.71.23,2.41,2.41,0,0,1,.76,1.49,47,47,0,0,1-.35,22.33c-.63,2.41-1.33,5.34.45,7.08a4.94,4.94,0,0,0,2.27,1.14c5.56,1.35,10.92-2.76,14.68-7.08a65.28,65.28,0,0,0,8.61-12.66,12.4,12.4,0,0,0,1.48-4.06,14.41,14.41,0,0,0-.77-5.5,93.23,93.23,0,0,1-2.88-18.57c-.42-8,.16-16.39-2.91-23.83a12.51,12.51,0,0,0-4.87-6.26c-2.55-1.5-5.69-1.5-8.65-1.39-5.85.23-12.26,1-16.23,5.3C856.28,572.36,853.88,580.61,852.33,583.22Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <path
                d="M854.75,564.14c-2.38-.44-4.17,2.75-3.24,5s3.44,3.38,5.83,3.79,4.87.31,7.17,1.05,4.51,2.69,4.45,5.11c2.58-.71,5.24,1.47,6,4s.26,5.31-.28,7.93c.73.79,2.1.25,2.8-.57s1.14-1.92,2.08-2.45c2-1.16,4.5,1.44,4.22,3.75s-2.14,4.07-3.82,5.68a3.66,3.66,0,0,0-1,1.28c-.7,1.94,1.89,3.33,3.9,3.77a11,11,0,0,0,.52,4.46,3.76,3.76,0,0,0,3.42,2.51c1.35-.07,2.43-1.09,3.67-1.62s3.15-.19,3.26,1.16c2.09-4.36-.13-8.42.77-12.56.82-3.79,4.16-2.38,6.45-4.93,1.27-1.42,1.16-3.55,1.75-5.35.75-2.31,2.67-4.15,3.19-6.52.88-4-2.45-7.61-5.49-10.38a3.79,3.79,0,0,0-1-4.38,8.52,8.52,0,0,0-4.37-1.9,2.32,2.32,0,0,1-1.18-.47c-.52-.5-.41-1.34-.35-2.06,1-12.15-18.86-8.78-26.17-8.16a18.82,18.82,0,0,0-8.52,2.89C858.09,555.75,852.05,563.65,854.75,564.14Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M893.28,622l-.5.11c-10.61,2.65-26.1,12.34-29.58,23.1-4.36,13.52-7.22,31.7-4.07,45.67,2.19,9.71,8.08,18.18,10.55,27.65,2.75,10.49,3.21,20.76,7.48,31,1.88,4.51,4.31,9.12,8.51,11.6,3.49,2.05,7.69,2.35,11.73,2.57,11.9.63,28.61,4.66,38.48-3.52,7.61-6.24,10.61-16.77,10.09-26.59s-4.09-19.2-7.61-28.39L921.8,662C917.81,651.62,908.12,619.07,893.28,622Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M902,728.24a9.4,9.4,0,0,0-1.24,8.83,78.75,78.75,0,0,1,2.16,21.47,5,5,0,0,1-1.12,3.57,5.2,5.2,0,0,1-3.24,1c-4.15.32-8.54.6-12.28-1.23-4.43-2.18-7-6.86-9-11.38-5.69-12.93-8.88-26.8-12-40.57a17.44,17.44,0,0,1-.64-6.87c.62-3.47,3.28-6.25,6.2-8.24s6.18-3.45,9-5.6c5.48-4.21,9.53-11.83,17-12.79,5.63-.73,9.74,3.23,11.57,8.23C913.12,697.81,908,716.35,902,728.24Z"
                transform="translate(-49.87 -151.85)"
                fill="url(#linear-gradient-8)"
            >
            </path>
            <path
                d="M884,653.52s-15.17,41.05-18.16,44.05S895,718.73,895,718.73s21-39.92,20-57.22S895.82,637.22,884,653.52Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M895,713.16s-1.11,13.22-17.33,13l-27.41-.39c-12.63-.17-25.28-.6-37.91-.51-3.39,0-5.74,1.58-9,2.34a36.74,36.74,0,0,1-33.08-9c-3.42-3.31-6.29-8.34-4.28-12.66a2.89,2.89,0,0,1,1.26-1.45,3.51,3.51,0,0,1,1.58-.2c7.64.24,15.55,2.19,22.75-.38a6.07,6.07,0,0,1-.93-3.22c.11-1.12,1.15-2.24,2.25-2a3.36,3.36,0,0,1,1.47,1.07,17.94,17.94,0,0,0,14.83,5.86c20.79-1,41.3-5.41,62.09-6.35,4.87-.21,9.89-.22,14.44,1.52S894.38,708.33,895,713.16Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <path
                d="M399.9,980.44a14.8,14.8,0,0,1,4.71,1.21,4.31,4.31,0,0,1,2.5,3.88,4.78,4.78,0,0,1-2.86,3.57c-1.43.72-3,1.08-4.42,1.82a25.62,25.62,0,0,1-3.85,2.15c-2.27.72-4.69-.17-6.92-1a91.15,91.15,0,0,0-31.47-5.21,11.56,11.56,0,0,1-3.11-.21c-3-.79-4.82-4-5.68-7-1.85-6.53-1-13.51.2-20.19a4.52,4.52,0,0,1,.76-2.09,3.93,3.93,0,0,1,1.69-1.06c10.56-3.94,15,6.52,21.91,12.11A56.52,56.52,0,0,0,399.9,980.44Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <path
                d="M365,980.75c.69.11,1.39.19,2.08.26a87.19,87.19,0,0,0,14.18.38,5,5,0,0,0,2.59-.62,4.27,4.27,0,0,0,1.56-3.43,19.38,19.38,0,0,1,.26-3.89.94.94,0,0,1,1.09-.85c3.63-.12,7.12,1.32,10.39,2.9,6.32,3.06,12.58,7,16.1,13.08a3.16,3.16,0,0,1,.57,1.68,2.91,2.91,0,0,1-.55,1.46c-4.26,6.63-14.88,5-21.38,3.62-7.25-1.53-14.22-4.27-21.54-5.42-8-1.27-16.76-.71-23.71-4.95a7.75,7.75,0,0,1-2.81-2.61c-1.92-3.38.44-8.77,2.85-11.38a2,2,0,0,1,1-.67,2,2,0,0,1,1.47.4c3.34,2.06,5.51,5.65,8.84,7.73A18.33,18.33,0,0,0,365,980.75Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M293,711.63s-32.82,18.78-23.62,53.16c8,30,31.08,48.68,45.83,74.33,8.17,14.22,6.68,37.43,10.14,54a391.89,391.89,0,0,0,17.48,59.58c1.48,3.9,3.91,8.43,8.08,8.56,2,.07,3.84-.94,5.82-1s3.75.6,5.58.19c4.77-1.09,4.74-7.85,3.66-12.63a397.56,397.56,0,0,1-9.38-70.06c-.8-18.16,4.13-37.45-1-55.06C351.48,808.7,327.11,734,327.11,734Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M330.81,998.57a15.26,15.26,0,0,1,4.26,2.35,4.32,4.32,0,0,1,1.46,4.38,4.74,4.74,0,0,1-3.66,2.74c-1.56.35-3.18.3-4.74.67a25.75,25.75,0,0,1-4.26,1.13c-2.37.13-4.49-1.34-6.46-2.66a91.15,91.15,0,0,0-29.18-12.86,11.57,11.57,0,0,1-3-1c-2.75-1.52-3.69-5-3.77-8.18-.17-6.79,2.37-13.34,5.21-19.5a4.4,4.4,0,0,1,1.26-1.84,3.82,3.82,0,0,1,1.9-.61c11.2-1.2,12.93,10,18.21,17.18A56.54,56.54,0,0,0,330.81,998.57Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <path
                d="M297.08,734.81c-9.25,1.13-16,5.27-23,11.25-13.76,11.66-12.78,35.5-8.14,52.92,2.44,9.18,17,38,19.14,46.12,4.06,15.5-4.05,34.7-4.76,50.36a393.45,393.45,0,0,0,2.11,62.06c.48,4.15,1.7,9.13,5.7,10.3,1.9.56,4,0,5.91.43s3.48,1.52,5.35,1.57c4.9.13,6.55-6.42,6.69-11.32a396.6,396.6,0,0,1,8.33-70.2c3.74-17.79,13.31-35.25,12.7-53.58-.5-15-.94-29.9-1.4-44.85-.51-16.62,1.8-33.45-3.26-49.44-.59-1.87-1.34-3.85-2.94-5a9.12,9.12,0,0,0-4.78-1.27,95.39,95.39,0,0,0-14.83.33C299,734.59,298,734.69,297.08,734.81Z"
                transform="translate(-49.87 -151.85)"
                fill="#473f47"
            >
            </path>
            <path
                d="M320.6,566.71c1.25,2.11,3.81,3.33,4.63,5.64,1,2.78-.93,5.64-1.93,8.42-1.38,3.88-.94,8.22-2.29,12.11a4.58,4.58,0,0,1-1.38,2.2,5.13,5.13,0,0,1-2.06.8,29.71,29.71,0,0,1-8.77.52,2.49,2.49,0,0,0-1.71.23,2.32,2.32,0,0,0-.76,1.48,46.85,46.85,0,0,0,.34,22.33c.64,2.41,1.33,5.34-.45,7.08a4.77,4.77,0,0,1-2.26,1.14c-5.57,1.36-10.93-2.76-14.68-7.08a65.07,65.07,0,0,1-8.61-12.65,12.59,12.59,0,0,1-1.49-4.06,14.9,14.9,0,0,1,.77-5.51,92.51,92.51,0,0,0,2.88-18.57c.42-8-.16-16.39,2.92-23.83a12.55,12.55,0,0,1,4.86-6.26c2.56-1.5,5.69-1.5,8.66-1.39,5.84.23,12.25,1,16.22,5.31C316.64,555.85,319.05,564.11,320.6,566.71Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <path
                d="M279.6,606.92c2.2-.16,4.42-.17,6.62-.18a56.4,56.4,0,0,1,12,.86,19.36,19.36,0,0,1,10.43,5.6c1.7,1.9,2.87,4.21,4.48,6.19s3.89,3.69,6.44,3.62c1.75,0,3.36-.93,5.07-1.32,2.44-.56,5-.13,7.43-.65s4-2.33,5.93-3.84q6.31-5,12.17-10.57c1.94-1.83,22.2-23.51,21.4-24.08a36.8,36.8,0,0,1,8.16,8.13,6.78,6.78,0,0,1,1.53,3.4,7.23,7.23,0,0,1-.9,3.37,195.86,195.86,0,0,1-24.59,40.39,98.75,98.75,0,0,1-17.65,17.79c-4.33,3.29-9.47,6.15-12.14,11.11-6,11.12-.07,28.07,0,39.92,0,4.06-1.68,7.37-1.67,11.5,0,7.2,6.25,13.59,4,21-1.87,6.13-8.92,8.76-15.08,10.54l-24.19,7c-5.36,1.55-11.39,3-16.28.36-6.09-3.34-7.43-11.35-8-18.27-1.44-17.87-.89-39.3-8.29-56-4.76-10.77-5.73-18.37-4.34-30.45,1-9,2.36-18.1,5.46-26.66,1.34-3.71,3.1-7.32,4.79-10.87,1.5-3.17,5.21-4.92,8.35-6.13A30.29,30.29,0,0,1,279.6,606.92Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M312.87,553.79a16.41,16.41,0,0,1-5.32,10.28c-2.83,2.67-6.37,4.49-9.3,7s-5.33,6.31-4.66,10.14c-2.32-.78-5.33-.15-6.37,2a4.21,4.21,0,0,0,.68,4.41,5.3,5.3,0,0,0,4.2,1.74,16.62,16.62,0,0,0-7.5,6.06c-2.59,3.81-3.05,7.65-4.6,11.82-1,2.67-3.21,4.7-4.64,7.16-2.52,4.32-2.49,9.6-3.1,14.56-1,7.82-3.79,15.6-9.15,21.37s-13.46,9.28-21.23,8-14.66-8-15.1-15.83c-3.37-.54-6-3.45-7.09-6.67s-.94-6.74-.57-10.13c.4-3.66,1.08-7.4,3-10.52,3.49-5.54,10.16-8,16-11a61.64,61.64,0,0,0,7.54-4.59,8.66,8.66,0,0,0,2.74-2.68c1.32-2.35.43-5.25-.15-7.89-.94-4.25-.85-9,1.66-12.57,2.28-3.25,6.14-4.93,9.81-6.45l9.41-3.92a5.27,5.27,0,0,0,2.12-1.31,5,5,0,0,0,.84-1.94c1.22-4.57,1.79-9.35,3.7-13.67,4-9.1,13.61-12,22.75-10.4C306.27,540.24,313.65,545.07,312.87,553.79Z"
                transform="translate(-49.87 -151.85)"
                fill="#fc682d"
            >
            </path>
            <path
                d="M280.42,621s43.2,7,47.28,5.87,4,35.85,4,35.85-45.09-1.36-59.66-10.74S260.4,623.3,280.42,621Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M295.82,992.78c.62.19,1.25.35,1.87.51a82.67,82.67,0,0,0,13,2.13,4.86,4.86,0,0,0,2.46-.25,4,4,0,0,0,1.87-3,17.65,17.65,0,0,1,.72-3.54,1.08,1.08,0,0,1,.43-.57,1.21,1.21,0,0,1,.68-.08c3.35.35,6.38,2.12,9.18,4,5.42,3.6,10.67,8,13.15,14a3.08,3.08,0,0,1,.31,1.62,2.74,2.74,0,0,1-.7,1.28c-4.73,5.55-14.29,2.72-20.09.63-6.46-2.32-12.52-5.71-19.1-7.69-7.23-2.17-15.31-2.75-21.16-7.52a7.13,7.13,0,0,1-2.25-2.75c-1.34-3.35,1.5-8,4-10.1a1.49,1.49,0,0,1,2.28.07c2.81,2.3,4.35,5.88,7.15,8.2A16.8,16.8,0,0,0,295.82,992.78Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M382.29,410.76H786.86V692.34H382.29Zm401,3.61H385.9V688.72H783.24Z"
                transform="translate(-49.87 -151.85)"
                fill="#6ed216"
            >
            </path>
            <path
                d="M372.43,568.88c0-4.55.1-9.48,2.89-13.07l2.06,8.48a72.7,72.7,0,0,1,11.39-22.2c.64,4.71-.86,9.43-2.35,14a111.92,111.92,0,0,1,7.37-12.68c1.72,4.84-2.66,9.66-3.22,14.77a25.54,25.54,0,0,1,6.37-6.66c.76,1.43,0,3.18-.72,4.63l-4.57,8.95A21.82,21.82,0,0,1,396,561.6c1.41,3.46-1.43,7-3.07,10.38-1.21,2.49-1.79,5.24-3,7.75a18.17,18.17,0,0,1-11.31,9.62c-1.76-3.09-5.54-3.34-6.12-6.75C371.77,578.3,372.45,573.26,372.43,568.88Z"
                transform="translate(-49.87 -151.85)"
                fill="#ffbbab"
            >
            </path>
            <rect
                x="363.97"
                y="349.27"
                width="28.33"
                height="187.6"
                fill={Color.PURPLE}
            >
            </rect>
            <rect
                x="427.06"
                y="307.51"
                width="28.33"
                height="229.36"
                fill={Color.BLUE}
            >
            </rect>
            <rect
                x="492.19"
                y="386.61"
                width="28.33"
                height="150.25"
                fill={Color.GREEN}
            >
            </rect>
            <rect
                x="557.31"
                y="307.51"
                width="28.33"
                height="229.36"
                fill={Color.PINK}
            >
            </rect>
            <rect
                x="622.44"
                y="342.65"
                width="28.33"
                height="194.22"
                fill={Color.CORAL}
            >
            </rect>
            <rect
                x="687.56"
                y="281.97"
                width="28.33"
                height="254.9"
                fill={Color.YELLOW}
            >
            </rect>
            <line
                x1="874.99"
                y1="276.39"
                x2="765.66"
                y2="216.79"
                fill="none"
            >
            </line>
        </svg>
    );
};

export default SvgDataPartners;
