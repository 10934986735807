import React, {FunctionComponent} from "react";
import {ButtonLinkClose, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FormLayoutButtons, FormLayoutRows, Icon, ModalDescription, ModalDescriptionAlignment} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalCampaignBriefConfirmationProps = {
    onClose: () => void;
}

const ModalCampaignBriefConfirmation: FunctionComponent<ModalCampaignBriefConfirmationProps> = ({onClose}) => {
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);

    return (
        <FormLayoutRows>
            <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER}>
                <Icon name="task_alt" colorIcon={Color.GREEN}/>
            </FlexContent>
            <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                {textCampaignBrief("form.confirmation")}
            </ModalDescription>
            <FormLayoutButtons>
                <ButtonLinkClose onClick={onClose}/>
            </FormLayoutButtons>
        </FormLayoutRows>
    );
};

export default ModalCampaignBriefConfirmation;
