import React, {FunctionComponent} from "react";
import {Tag, TagSize, TagStyle, TagTextTransform} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {getTaxonomyTag} from "../../../utils/taxonomy/TaxonomyTag";
import {TranslationPortalFile} from "../../../utils/constants";

type TagActivationTypeProps = {
    type: string;
};

const TagActivationType: FunctionComponent<TagActivationTypeProps> = ({type}) => {
    const tag = getTaxonomyTag(type);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    if (!tag) return <></>;

    return (
        <Tag
            size={TagSize.SMALL}
            style={TagStyle.PRIMARY_GREY_LIGHT}
            label={textTaxonomy(`tag.${tag.name}`)}
            textTransform={TagTextTransform.CAPITALIZE}
            icon={tag.icon ? {...tag.icon} : undefined}
        />
    );
};

export default TagActivationType;
