import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, Icon, SearchAutocomplete} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {TagUserSegmentType} from "./index";
import {Segment} from "../../api/model/segment/Segment";

type SearchSegmentAutocompleteProps = {
    selectedItems?: Segment[];
    onSelect: (item: Segment) => void;
};

const SearchSegmentAutocomplete: FunctionComponent<SearchSegmentAutocompleteProps> = ({selectedItems, onSelect}) => {
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);

    const searchTimeout: any = useRef<any>();
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [segments, setSegments] = useState<Segment[]>([]);

    useEffect(() => {
        if (!searchQuery) {
            setSegments([]);
            return;
        }

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
            searchTimeout.current = undefined;
        }
        searchTimeout.current = setTimeout(() => {
            (async function () {
                try {
                    const segments = await session.restTaxonomy.searchLightSegments(searchQuery);
                    setSegments(segments);
                } catch (e) {
                    console.error("Failed to get results:", e);
                } finally {
                    searchTimeout.current = undefined;
                }
            })();
        }, 300);
    }, [searchQuery]);

    return (
        <SearchAutocomplete
            value={searchQuery}
            onChange={setSearchQuery}
            onSelect={(option) => onSelect(option.value)}
            items={segments.map((segment) => ({
                value: segment,
                searchField: `${segment.id} - ${segment.name} ${searchQuery}`,
                component: (
                    <>
                        <TagUserSegmentType type={segment.data_type}/>
                        <span>{segment.name} ({segment.id})</span>
                        <Icon {...Action.ADD.icon}/>
                    </>
                )
            }))}
            selectedItems={selectedItems?.map((item) => ({
                value: item,
                searchField: `${item.id} - ${item.name} ${searchQuery}`
            }))}
            placeholder={textCampaignBrief("form.placeholder.segments")}
        />
    );
};

export default SearchSegmentAutocomplete;
