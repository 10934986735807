import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonYesToggle} from "../../snippet";
import {CampaignBrief} from "../../../api/model/campaign-brief/CampaignBrief";
import {CAMPAIGN_BRIEF_FORMATS, CampaignBriefFormat} from "../../../api/model/campaign-brief/CampaignBriefFormat";
import {CAMPAIGN_BRIEF_OBJECTIVES, CampaignBriefObjective} from "../../../api/model/campaign-brief/CampaignBriefObjective";
import {AlertSeverity, Checkbox, FieldBlock, FlexContent, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Form, Formatter, FormatterFormat, FormLayoutColumns, FormLayoutRows, FormLayoutTitle, InputDate, InputDateType, RadioButtons} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {FormLayoutMessage} from "../../../common/component/snippet";

export enum ModalCampaignBriefTargetingFormError {
    END_DATE = "end_date"
}

type ModalCampaignBriefTargetingProps = {
    campaignBrief: CampaignBrief;
    setCampaignBrief: (campaign: CampaignBrief) => void;
    onGoToNext: () => void;
}

export const TARGETING_FORM_ID = "targeting-step-form";
const LIMIT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

const ModalCampaignBriefTargeting: FunctionComponent<ModalCampaignBriefTargetingProps> = ({campaignBrief, setCampaignBrief, onGoToNext}) => {
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);
    const [isLimitDateNeeded, setLimitDateNeed] = useState(false);
    const [formError, setFormError] = useState("");

    useEffect(() => {
        setLimitDateNeed(!!campaignBrief.limit_date);
    }, [campaignBrief.limit_date]);

    const handleChangeSelectedFormat = (name: CampaignBriefFormat, checked: boolean) => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);

        if (!checked) {
            newCampaign.format = newCampaign.format.filter((it) => it !== name);
        } else {
            newCampaign.format = [...newCampaign.format, name];
        }

        setCampaignBrief(newCampaign);
    };

    const handleChangeSelectedObjective = (value: CampaignBriefObjective) => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);
        newCampaign.objective = value;
        setCampaignBrief(newCampaign);
    };

    const handleChangeCampaignDate = (name: string, value: string) => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);
        newCampaign[name] = value;
        setCampaignBrief(newCampaign);
        setFormError("");
    };

    const handleChangeLimitDate = (value: string) => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);
        newCampaign["limit_date"] = Formatter.formatDate(value, LIMIT_DATE_FORMAT);
        setCampaignBrief(newCampaign);
    };

    const handleToggleLimitDate = () => {
        if (isLimitDateNeeded && !!campaignBrief.limit_date) {
            let newCampaign = new CampaignBrief();
            newCampaign.load(campaignBrief);
            newCampaign.limit_date = "";
            setCampaignBrief(newCampaign);
        }
        setLimitDateNeed(!isLimitDateNeeded);
    };

    const handleGoNext = (e: FormEvent) => {
        e.preventDefault();
        if (campaignBrief.end_date && (campaignBrief.end_date < campaignBrief.start_date)) {
            setFormError(ModalCampaignBriefTargetingFormError.END_DATE);
        } else {
            onGoToNext();
        }
    };

    const buildLimitDateInput = () => {
        const today = new Date();
        today.setHours(today.getHours() + 5);

        return (
            <FieldBlock label={textCampaignBrief("form.label.limit_date")} required>
                <InputDate
                    type={InputDateType.DATETIME_LOCAL}
                    value={Formatter.formatDate(campaignBrief.limit_date, FormatterFormat.DATE_TIME_INPUT) || ""}
                    onChange={handleChangeLimitDate}
                    min={today}
                />
            </FieldBlock>
        );
    };

    return (
        <Form id={TARGETING_FORM_ID} onSubmit={handleGoNext}>
            <FormLayoutRows>
                <FormLayoutTitle>{textCampaignBrief("form.title.format")}</FormLayoutTitle>
                <FormLayoutColumns>
                    {CAMPAIGN_BRIEF_FORMATS.map((it) =>
                        <Checkbox
                            key={it}
                            name={it}
                            label={textCampaignBrief(`form.label.${it}`)}
                            checked={campaignBrief.format.includes(it)}
                            onChange={(value) => handleChangeSelectedFormat(it, value)}
                        />
                    )}
                </FormLayoutColumns>
                <FormLayoutTitle>{textCampaignBrief("form.title.objective")}</FormLayoutTitle>
                <RadioButtons
                    id="objective"
                    value={campaignBrief.objective}
                    options={CAMPAIGN_BRIEF_OBJECTIVES.map((it) => ({label: textCampaignBrief(`form.label.${it}`), value: it}))}
                    onChange={(value) => handleChangeSelectedObjective(value as CampaignBriefObjective)}
                />
                <FormLayoutTitle>{textCampaignBrief("form.title.campaign")}</FormLayoutTitle>
                {formError &&
                    <FormLayoutMessage message={textCampaignBrief(`form.error.${formError}`)} severity={AlertSeverity.DANGER}/>
                }
                <FormLayoutColumns>
                    <FieldBlock label={textCampaignBrief("form.label.start_date")} required>
                        <InputDate
                            value={campaignBrief.start_date}
                            min={new Date()}
                            onChange={(value) => handleChangeCampaignDate("start_date", value)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textCampaignBrief("form.label.end_date")}>
                        <InputDate
                            value={campaignBrief.end_date || ""}
                            min={campaignBrief.start_date}
                            onChange={(value) => handleChangeCampaignDate("end_date", value)}
                            disabled={!campaignBrief.start_date}
                        />
                    </FieldBlock>
                </FormLayoutColumns>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN}>
                        <FormLayoutTitle>{textCampaignBrief("form.title.limit_date")}</FormLayoutTitle>
                        <ButtonYesToggle name="limit_date_toggle" checked={isLimitDateNeeded} onChange={handleToggleLimitDate}/>
                    </FlexContent>
                    {isLimitDateNeeded && buildLimitDateInput()}
                </FormLayoutRows>
            </FormLayoutRows>
        </Form>
    );
};

export default ModalCampaignBriefTargeting;
