import React, {FunctionComponent} from "react";
import SvgIabChip from "../../svg/SvgIabChip";
import clsx from "clsx";
import {TagSize, TagStyle} from "@sirdata/ui-lib";

type TagIabProps = {
    id: number;
};

const TagIab: FunctionComponent<TagIabProps> = ({id}) => {
    if (!id) return <></>;

    return (
        <span className={clsx("tag", TagSize.SMALL, TagStyle.PRIMARY_GREY_LIGHT)}>
            <span className="tag__logo"><SvgIabChip/></span>
            <span>{id}</span>
        </span>
    );
};

export default TagIab;
