import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Threshold} from "./Threshold";

export class KeywordStats extends ApiModel {
    keyword: string = "";
    count: number = 0;
    count_over: CountOver = {
        [Threshold.THRESHOLD_500]: 0,
        [Threshold.THRESHOLD_600]: 0,
        [Threshold.THRESHOLD_700]: 0,
        [Threshold.THRESHOLD_800]: 0,
        [Threshold.THRESHOLD_900]: 0
    };
}

export interface CountOver {
    [Threshold.THRESHOLD_500]: number;
    [Threshold.THRESHOLD_600]: number;
    [Threshold.THRESHOLD_700]: number;
    [Threshold.THRESHOLD_800]: number;
    [Threshold.THRESHOLD_900]: number;
}
