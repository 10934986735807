import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Color, Tag, TagSize, TagStyle, TagTextTransform} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SegmentDataType, getSegmentDataTypeItem} from "../../../api/model/segment/SegmentDataType";

type TagSegmentTypeProps = {
    type: SegmentDataType;
};

const TagSegmentType: FunctionComponent<TagSegmentTypeProps> = ({type}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    if (!type) return (<></>);
    const typeItem = getSegmentDataTypeItem(type);
    if (!typeItem) return (<></>);

    return (
        <Tag
            size={TagSize.SMALL}
            style={TagStyle.DEFAULT_CYAN_LIGHT}
            label={textTaxonomy(`data_type.${typeItem.type}`)}
            textTransform={TagTextTransform.CAPITALIZE}
            icon={typeItem.icon}
            customColor={{borderColor: Color.CYAN_LIGHT}}
        />
    );
};

export default TagSegmentType;
