import React, {FunctionComponent, useEffect, useState} from "react";
import {Action, Tag, TagSize, TagStyle} from "@sirdata/ui-lib";

type KeywordProps = {
    value: string;
    onClick?: (keyword: string) => void;
    removable?: boolean;
    highlight?: boolean;
};

const TagKeyword: FunctionComponent<KeywordProps> = ({value, onClick, removable, highlight}) => {
    const [isHighlighted, setHighlighted] = useState(false);

    useEffect(() => {
        if (highlight) {
            setTimeout(() => setHighlighted(false), 600);
            setHighlighted(true);
        }
    }, [highlight]);

    return (
        <Tag
            size={TagSize.MEDIUM}
            style={!removable && onClick ? TagStyle.DEFAULT_MIDNIGHT_LIGHT : TagStyle.PRIMARY_MIDNIGHT_LIGHT}
            label={value}
            icon={removable && onClick ? {...Action.REMOVE.icon, onClick: () => onClick(value)} : undefined}
            highlighted={isHighlighted}
            onClick={!removable && onClick ? () => onClick(value) : undefined}
            reverse
        />
    );
};

export default TagKeyword;
