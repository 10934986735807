import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {IconProps, IconTooltip} from "@sirdata/ui-lib";

type TagWithIconActionProps = {
    icon: IconProps;
    action: () => void;
    actionTooltipText: string;
    cssClass?: string;
    children: React.ReactNode;
};

const TagWithIconAction: FunctionComponent<TagWithIconActionProps> = ({icon, action, actionTooltipText, children, cssClass}) => {
    return (
        <span className={clsx("tag", cssClass)}>
            {children}
            <IconTooltip icon={icon} text={actionTooltipText} onClick={action}/>
        </span>
    );
};

export default TagWithIconAction;
