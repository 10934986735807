export enum TaxonomyActivationType {
    ALL = "all",
    SELF_SERVICE = "self_service",
    DEAL = "deal"
}

export const TAXONOMY_ACTIVATION_TYPES: TaxonomyActivationType[] = [
    TaxonomyActivationType.ALL,
    TaxonomyActivationType.SELF_SERVICE,
    TaxonomyActivationType.DEAL
];

export function getTaxonomyActivationType(type: string): TaxonomyActivationType {
    return TAXONOMY_ACTIVATION_TYPES.find((it) => it === type) || TaxonomyActivationType.ALL;
}
