import React from "react";
import {Action, Color, IconTooltip, InputToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import ValueCopier from "./ValueCopier";
import {FolderFavorites} from "../../api/model/FolderFavorites";
import {TranslationPortalFile} from "../../utils/constants";
import {PORTAL} from "../../utils/Portal";
import {usePreprod} from "../../common/utils/helper";

type FavoriteFolderShareButtonProps = {
    folder: FolderFavorites;
    onChange: () => void;
}

const FavoriteFolderShareButton: React.FC<FavoriteFolderShareButtonProps> = ({folder, onChange}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textFavorites} = useTranslation(TranslationPortalFile.FAVORITES);

    return (
        <div className="share-favorites-folder">
            <div className="share-favorites-folder__toggle-public">
                {textFavorites("actions.share_your_favorites")}
                <IconTooltip
                    icon={{name: "info", outlined: true, colorIcon: Color.GREY_DARK}}
                    text={textFavorites("info.share_favorites")}
                />
                <InputToggleSwitch
                    checked={folder.public}
                    onChange={onChange}
                />
            </div>
            {folder.public &&
                <ValueCopier
                    value={PORTAL.origin.getUrl(usePreprod) + folder.getShortRoute()}
                    tooltip={textCommon(Action.COPY.labelKey)}
                />
            }
        </div>
    );
};

export default FavoriteFolderShareButton;
