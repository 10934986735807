import {Rest} from "../../common/api/rest/Rest";
import {TaxonomyNode} from "../model/taxonomy/TaxonomyNode";
import {TaxonomyQueryParams} from "../model/taxonomy/TaxonomyQueryParams";
import {TaxonomyPagedElements} from "../model/taxonomy/TaxonomyPagedElements";
import {TaxonomyElement} from "../model/taxonomy/TaxonomyElement";
import {TaxonomyElementType} from "../model/taxonomy/TaxonomyElementType";
import {Segment} from "../model/segment/Segment";

export class RestTaxonomy extends Rest {
    rootPath = "/audience-api/taxonomy";

    getNode(params?: TaxonomyQueryParams): Promise<TaxonomyNode> {
        return this._client.get(`${this.rootPath}/node?${params?.toQueryString()}`, TaxonomyNode) as Promise<TaxonomyNode>;
    }

    getElement(type: TaxonomyElementType, id: number): Promise<TaxonomyElement> {
        return this._client.get(`${this.rootPath}/element/${type}/${id}`, TaxonomyElement) as Promise<TaxonomyElement>;
    }

    getElements(type: TaxonomyElementType, ids: number []): Promise<TaxonomyElement[]> {
        return this._client.get(`${this.rootPath}/element/${type}`, TaxonomyElement, {queryParams: {ids: ids}}) as Promise<TaxonomyElement[]>;
    }

    search(params?: TaxonomyQueryParams): Promise<TaxonomyPagedElements> {
        return this._client.get(`${this.rootPath}/search?${params?.toQueryString()}`, TaxonomyPagedElements) as Promise<TaxonomyPagedElements>;
    }

    searchLightSegments(search: string): Promise<Segment[]> {
        return this._client.get(`${this.rootPath}/segment-light-search?search=${search}`, Segment) as Promise<Segment[]>;
    }
}
