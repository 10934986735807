import {Rest} from "../../common/api/rest/Rest";
import {CuratedDealRequest} from "../model/curated-deal/CuratedDealRequest";

export class RestCuratedDeal extends Rest {
    rootPath = "/audience-api/curated-deal";

    getDspList(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/platforms`) as Promise<string[]>;
    }

    sendCuratedDealRequest(request: CuratedDealRequest): Promise<void> {
        return this._client.post(`${this.rootPath}/request`, request);
    };
}
