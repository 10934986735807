import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Tag, TagSize, TagStyle, TagTextTransform} from "@sirdata/ui-lib";

type TagNewProps = {
    big?: boolean;
};

const TagNew: FunctionComponent<TagNewProps> = ({big}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);

    return (
        <Tag
            size={big ? TagSize.MEDIUM : TagSize.SMALL}
            style={TagStyle.PRIMARY_RED}
            label={textTaxonomy("new")}
            textTransform={TagTextTransform.CAPITALIZE}
        />
    );
};

export default TagNew;
