export enum TagCloudItemType {
    SMALL,
    NORMAL,
    BIG,
    EXTRA_BIG
}

export type TagCloudItem = {
    type: TagCloudItemType;
    fontClass: string;
    minValue: number;
}

export const TAG_CLOUD_ITEMS: TagCloudItem[] = [
    {type: TagCloudItemType.EXTRA_BIG, fontClass: "tag-cloud-extra-big", minValue: 15000},
    {type: TagCloudItemType.BIG, fontClass: "tag-cloud-big", minValue: 8000},
    {type: TagCloudItemType.NORMAL, fontClass: "tag-cloud-normal", minValue: 4000},
    {type: TagCloudItemType.SMALL, fontClass: "tag-cloud-small", minValue: 0}
];