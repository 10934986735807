import {pathDataSelectionWithPath} from "../../../utils/constants";
import {routeParamPath} from "../../../common/utils/constants";
import {DataSelectionContent} from "./DataSelectionContent";
import {ApiModel} from "../../../common/api/model/ApiModel";

export class DataSelection extends ApiModel {
    id: number = 0;
    string_identifier: string = "";
    title: string = "";
    content: DataSelectionContent = new DataSelectionContent();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "content") {
                    this[key] = new DataSelectionContent(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getRoute(): string {
        return pathDataSelectionWithPath.replace(routeParamPath, `/${this.string_identifier}`);
    }
}
