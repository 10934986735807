import React, {FunctionComponent} from "react";
import {TaxonomyElement} from "../../../api/model/taxonomy/TaxonomyElement";
import {ListCategory, ListCuratedDeal, ListSegment} from "../index";
import {TaxonomyElementType} from "../../../api/model/taxonomy/TaxonomyElementType";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";

type SearchTaxonomyElementProps = {
    item: TaxonomyElement;
    filters: TaxonomyFilters;
};

const ListTaxonomyElement: FunctionComponent<SearchTaxonomyElementProps> = ({item, filters}) => {
    const buildItem = () => {
        switch (item.type) {
            case TaxonomyElementType.SEGMENT:
                if (item.audience_segment?.id) return <ListSegment item={item.audience_segment} filters={filters}/>;
                break;
            case TaxonomyElementType.CATEGORY:
                if (item.audience_category?.id) return <ListCategory item={item.audience_category} filters={filters}/>;
                break;
            case TaxonomyElementType.CURATED_DEAL:
                if (item.audience_curated_deal?.id) return <ListCuratedDeal item={item.audience_curated_deal} filters={filters}/>;
                break;
            default:
                return <></>;
        }
    };

    return (
        <div className="taxonomy-list__item">
            {buildItem()}
        </div>
    );
};

export default ListTaxonomyElement;
