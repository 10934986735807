export enum Threshold {
    THRESHOLD_500 = 500,
    THRESHOLD_600 = 600,
    THRESHOLD_700 = 700,
    THRESHOLD_800 = 800,
    THRESHOLD_900 = 900
}

export const THRESHOLD_ITEMS: Threshold[] = [
    Threshold.THRESHOLD_500,
    Threshold.THRESHOLD_600,
    Threshold.THRESHOLD_700,
    Threshold.THRESHOLD_800,
    Threshold.THRESHOLD_900
];

export class ThresholdStat {
    value: number = Threshold.THRESHOLD_500;
    percentage: string = "";
    urlsCount: string = "";
}

export const DEFAULT_KEYWORD_THRESHOLD =  Threshold.THRESHOLD_500;
