import React, {FunctionComponent} from "react";
import {CustomCategoryConfigStep} from "../../../api/model/category/custom/CustomCategoryConfigStep";
import ConfigHeaderCreate from "./ConfigHeaderCreate";
import ConfigHeaderEdit from "./ConfigHeaderEdit";

type ConfigHeaderProps = {
    step: CustomCategoryConfigStep;
    edit: boolean;
    touched: boolean;
    last?: boolean;
    disableSave?: boolean;
    onSave: (finish?: boolean) => void;
    previousPath: string;
    nextPath: string;
};

const ConfigHeader: FunctionComponent<ConfigHeaderProps> = ({step, edit, touched, last, disableSave, onSave, previousPath, nextPath}) => {
    return (
        edit ?
            <ConfigHeaderEdit step={step} touched={touched} onSave={onSave}/> :
            <ConfigHeaderCreate
                step={step}
                touched={touched}
                last={last}
                disableSave={disableSave}
                onSave={onSave}
                previousPath={previousPath}
                nextPath={nextPath}
            />
    );
};

export default ConfigHeader;
