import React, {FunctionComponent} from "react";
import {Taxonomy} from "../../api/model/Taxonomy";
import {SvgIabChip, SvgSirdataChip} from "@sirdata/ui-lib";

type TaxonomyChipProps = {
    taxonomy: Taxonomy;
    cssClass?: string;
    color?: string;
}

const TaxonomyChip: FunctionComponent<TaxonomyChipProps> = ({taxonomy, cssClass, color}) => {
    return (
        <span className={cssClass}>
            {(taxonomy === Taxonomy.SIRDATA) && <SvgSirdataChip currentColor={color}/>}
            {(taxonomy === Taxonomy.IAB) && <SvgIabChip currentColor={color}/>}
        </span>
    );
};

export default TaxonomyChip;
