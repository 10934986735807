export enum TaxonomyModelingType {
    ALL = "all",
    COOKIE = "cookie",
    COOKIELESS = "cookieless"
}

export const TAXONOMY_MODELING_TYPES: TaxonomyModelingType[] = [
    TaxonomyModelingType.ALL,
    TaxonomyModelingType.COOKIE,
    TaxonomyModelingType.COOKIELESS
];

export function getTaxonomyModelingType(type: string): TaxonomyModelingType {
    return TAXONOMY_MODELING_TYPES.find((it) => it === type) || TaxonomyModelingType.ALL;
}
