import React, {FunctionComponent} from "react";
import {Tag, TagSize, TagStyle, TagTextTransform} from "@sirdata/ui-lib";
import {TaxonomyModelingType} from "../../../utils/taxonomy/TaxonomyModelingType";
import {getTaxonomyTagIcon} from "../../../utils/taxonomy/TaxonomyTag";

const TagCookieless: FunctionComponent = () => {
    return (
        <Tag
            size={TagSize.SMALL}
            style={TagStyle.DEFAULT_OCEAN}
            label={TaxonomyModelingType.COOKIELESS}
            textTransform={TagTextTransform.CAPITALIZE}
            icon={getTaxonomyTagIcon(TaxonomyModelingType.COOKIELESS)}
        />
    );
};

export default TagCookieless;
