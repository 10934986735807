import {Action, Color, Icon} from "@sirdata/ui-lib";
import {FunctionComponent, KeyboardEvent, useRef, useState} from "react";
import {onKeyEnter} from "../../common/utils/form";

type ChipsInputSeatIdProps = {
    platform: string;
    addSeatId: (seat: string) => void;
    placeholder: string;
}

const ChipsInputSeatId: FunctionComponent<ChipsInputSeatIdProps> = ({platform, addSeatId, placeholder}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [seatID, setSeatId] = useState("");

    const handleAddSeatIdKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        onKeyEnter(e, () => {
            if (seatID.trim()) {
                addSeatId(seatID);
            }
            setSeatId("");
            inputRef.current?.focus();
        });
    };

    const handleAddSeatIdClick = () => {
        if (seatID.trim()) {
            addSeatId(seatID);
        }
        setSeatId("");
        inputRef.current?.focus();
    };

    const handleClearSeatId = () => {
        setSeatId("");
        inputRef.current?.focus();
    };

    return (
        <span className="chip-input">
            <input
                ref={inputRef}
                type="text"
                name={platform}
                placeholder={placeholder}
                onKeyDown={handleAddSeatIdKeyDown}
                value={seatID}
                onChange={(e) => setSeatId(e.currentTarget.value)}
            />
            {seatID &&
                <>
                    <Icon name="check" onClick={handleAddSeatIdClick} colorIcon={Color.CYAN}/>
                    <Icon {...Action.CLOSE.icon} onClick={handleClearSeatId} colorIcon={Color.RED}/>
                </>
            }
        </span>
    );
};

export default ChipsInputSeatId;
