import ModalCampaignBriefConfirmation from "./ModalCampaignBriefConfirmation";
import ModalCampaignBriefPlatform from "./ModalCampaignBriefPlatform";
import ModalCampaignBriefSegment from "./ModalCampaignBriefSegment";
import ModalCampaignBriefTargeting from "./ModalCampaignBriefTargeting";

export enum CampaignBriefStep {
    TARGETING = "TARGETING",
    SEGMENT_SELECTION = "SEGMENT_SELECTION",
    DSP_SELECTION = "DSP_SELECTION",
    CONFIRMATION = "CONFIRMATION",
}

export const CAMPAIGN_BRIEF_STEPS = [
    {step: CampaignBriefStep.TARGETING, component: ModalCampaignBriefTargeting, showStep: true},
    {step: CampaignBriefStep.SEGMENT_SELECTION, component: ModalCampaignBriefSegment, showStep: true},
    {step: CampaignBriefStep.DSP_SELECTION, component: ModalCampaignBriefPlatform, showStep: true},
    {step: CampaignBriefStep.CONFIRMATION, component: ModalCampaignBriefConfirmation, showStep: false}
];
