import {Rest} from "../../common/api/rest/Rest";
import {Segment} from "../model/segment/Segment";

export class RestAudiencePortal extends Rest {
    rootPath = "/audience-api/portal";

    getHomeAudiences(): Promise<Segment[]> {
        return this._client.get(`${this.rootPath}/home/audiences`, Segment) as Promise<Segment[]>;
    }
}
