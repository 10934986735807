import React, {FunctionComponent} from "react";
import {TAG_CLOUD_ITEMS} from "../../api/model/category/custom/TagCloudItemType";

type TagCloudItemProps = {
    name: string;
    score: number;
};

const TagCloudItem: FunctionComponent<TagCloudItemProps> = ({name, score}) => {
    const obj = TAG_CLOUD_ITEMS.find(({minValue}) => score > minValue);

    if (!obj) return <></>;
    return (
        <span className={obj.fontClass}>{name}</span>
    );
};

export default TagCloudItem;
