import {pathContextualCustom, pathDataSelection, pathExplore, pathFavorites, pathPartners, pathSearch} from "./constants";
import {pathHome} from "../common/utils/constants";
import {Authorization} from "../api/model/account/Authorization";
import {MenuItem} from "@sirdata/ui-lib";

export enum Menu {
    HOME = "home",
    SEARCH = "search.title",
    EXPLORE = "explore",
    CUSTOM_TAXONOMY = "custom_taxonomy",
    DATA_SELECTION = "data_selection",
    FAVORITES = "favorites",
    PARTNERS = "partners",
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.SEARCH, path: pathSearch, icon: {name: "search"}},
    {label: Menu.EXPLORE, path: pathExplore, icon: {name: "explore"}},
    {label: Menu.CUSTOM_TAXONOMY, path: pathContextualCustom, icon: {name: "edit"}, allowedTo: Authorization.CUSTOM},
    {label: Menu.DATA_SELECTION, path: pathDataSelection, icon: {name: "hotel_class"}},
    {label: Menu.FAVORITES, path: pathFavorites, icon: {name: "favorite"}, includes: true},
    {label: Menu.PARTNERS, path: pathPartners, icon: {name: "partner_exchange"}}
];

export const SHORTCUT_MENU: MenuItem[] = [];
