import {FunctionComponent, useEffect, useState} from "react";
import {Action, FieldBlock, FlexContent, FlexContentDirection, FlexContentJustify, FlexContentSpacing, FormLayoutRows, FormLayoutTitle, Textarea, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {ButtonYesToggle, FavoriteFolderButton, IconButtonClearAll, SearchSegmentAutocomplete, TagUserSegmentType, TagWithIconAction} from "../../snippet";
import {CampaignBrief} from "../../../api/model/campaign-brief/CampaignBrief";
import {Segment} from "../../../api/model/segment/Segment";
import {TranslationPortalFile} from "../../../utils/constants";
import {FolderFavorites} from "../../../api/model/FolderFavorites";
import {session} from "../../../api/ApiSession";
import {TaxonomyElementType} from "../../../api/model/taxonomy/TaxonomyElementType";

type ModalCampaignBriefSegmentProps = {
    campaignBrief: CampaignBrief;
    setCampaignBrief: (campaign: CampaignBrief) => void;
}

const ModalCampaignBriefSegment: FunctionComponent<ModalCampaignBriefSegmentProps> = ({campaignBrief, setCampaignBrief}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);
    const [isCommentNeeded, setCommentNeeded] = useState<boolean>(false);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);

    useEffect(() => {
        if (!campaignBrief.segments.length) {
            setSelectedSegments([]);
            return;
        }

        if (!selectedSegments.length) {
            (async function () {
                try {
                    const elements = await session.restTaxonomy.getElements(TaxonomyElementType.SEGMENT, campaignBrief.segments);
                    const segments = elements.reduce((acc: Segment[], element) => {
                        if (element.audience_segment) acc.push(element.audience_segment);
                        return acc;
                    }, []);
                    setSelectedSegments(segments);
                } catch (e) {
                }
            })();
        }
    }, [campaignBrief.segments, selectedSegments.length]);

    useEffect(() => {
        setCommentNeeded(!!campaignBrief.comment);
    }, [campaignBrief.comment]);

    const updateCampaignBrief = (key: string, value: string | number[]) => {
        let newCampaign = new CampaignBrief({...campaignBrief});
        newCampaign[key] = value;
        setCampaignBrief(newCampaign);
    };

    const updateSegments = (segments: Segment[]) => {
        updateCampaignBrief("segments", segments.map((it) => it.id));
        setSelectedSegments(segments);
    };

    const handleAddSegments = (segment: Segment) => {
        updateSegments([...selectedSegments, segment]);
    };

    const handleAddFavorites = async (folder: FolderFavorites) => {
        const segments = folder.getSegments().filter((it) => !selectedSegments.find((selected) => selected.id === it.id));
        updateSegments([...selectedSegments, ...segments]);
    };

    const handleRemoveSegment = (index: number) => {
        selectedSegments.splice(index, 1);
        updateSegments(selectedSegments);
    };

    const handleToggleComment = () => {
        if (isCommentNeeded && !!campaignBrief.comment) {
            updateCampaignBrief("comment", "");
        }
        setCommentNeeded(!isCommentNeeded);
    };

    return (
        <FormLayoutRows>
            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.SMALL}>
                <FieldBlock label={textCampaignBrief("form.label.segments")}>
                    <SearchSegmentAutocomplete selectedItems={selectedSegments} onSelect={(segment) => handleAddSegments(segment)}/>
                </FieldBlock>
                <div className="modal-targeting-segment__favorite-button">
                    <FavoriteFolderButton onSelect={handleAddFavorites}/>
                </div>
            </FlexContent>
            <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                <div className="modal-targeting-segment__selection">
                    <span>{textCampaignBrief("form.segment_selected", {count: selectedSegments.length})}</span>
                    {!!selectedSegments.length && <IconButtonClearAll onClick={() => updateSegments([])}/>}
                </div>
                <div className="modal-targeting-segment__results">
                    {selectedSegments.length ?
                        selectedSegments.map((segment, index) => (
                            <TagWithIconAction
                                key={segment.id}
                                icon={Action.REMOVE.icon}
                                actionTooltipText={textCommon(Action.REMOVE.labelKey)}
                                action={() => handleRemoveSegment(index)}
                                cssClass="tag-segment-row"
                            >
                                <span className="tag-segment-row__info">
                                    <TagUserSegmentType type={segment.data_type}/>
                                    <span>{segment.name} ({segment.id})</span>
                                </span>
                            </TagWithIconAction>
                        )) :
                        <span className="modal-targeting-segment__results__placeholder">
                            {textCampaignBrief("form.segment_search_result")}
                        </span>
                    }
                </div>
            </FieldBlock>
            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN}>
                    <FormLayoutTitle>{textCampaignBrief("form.title.comment")}</FormLayoutTitle>
                    <ButtonYesToggle name="comment-toggle" checked={isCommentNeeded} onChange={handleToggleComment}/>
                </FlexContent>
                {isCommentNeeded &&
                    <Textarea
                        value={campaignBrief.comment || ""}
                        onChange={(value) => updateCampaignBrief("comment", value)}
                        placeholder={textCampaignBrief("form.placeholder.comment")}
                        rows={10}
                    />
                }
            </FormLayoutRows>
        </FormLayoutRows>
    );
};

export default ModalCampaignBriefSegment;
