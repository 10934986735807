import React, {FunctionComponent} from "react";
import {Color} from "@sirdata/ui-lib";

const SvgFavoritesIndex: FunctionComponent = () => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 377.09 321.74"
            width="406"
            height="306"
            className="illustration styles_illustrationTablet__1DWOa"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="299.79"
                    y1="226.53"
                    x2="287.88"
                    y2="205.51"
                    gradientTransform="matrix(0.99, -0.16, 0.16, 0.99, -97.9, -42)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopOpacity="0"></stop>
                    <stop offset="0.95"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="281.36"
                    y1="211.21"
                    x2="296.99"
                    y2="211.21"
                    xlinkHref="#linear-gradient"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="330.22"
                    y1="199.3"
                    x2="314.44"
                    y2="172.37"
                    gradientTransform="matrix(0.99, -0.16, 0.16, 0.99, -97.9, -42)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.02" stopColor="#fff" stopOpacity="0"></stop>
                    <stop offset="0.32" stopColor="#fff" stopOpacity="0.39"></stop>
                    <stop offset="0.68" stopColor="#fff" stopOpacity="0.68"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="293.41"
                    y1="178.45"
                    x2="271.65"
                    y2="217.35"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="66.67"
                    y1="218.73"
                    x2="274.71"
                    y2="218.73"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="189.87"
                    y1="267.26"
                    x2="104.95"
                    y2="122.3"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="347.38"
                    y1="199.78"
                    x2="344.66"
                    y2="172.04"
                    gradientTransform="matrix(0.99, -0.16, 0.16, 0.99, -127.91, -42.47)"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="347.17"
                    y1="197.69"
                    x2="344.9"
                    y2="174.51"
                    gradientTransform="matrix(0.99, -0.16, 0.16, 0.99, -127.91, -42.47)"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="338.61"
                    y1="223.6"
                    x2="291.78"
                    y2="140.67"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    xlinkHref="#linear-gradient-3"
                >
                </linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="73.23"
                    y1="152.79"
                    x2="124.01"
                    y2="99.9"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.02" stopColor="#fff" stopOpacity="0"></stop>
                    <stop offset="0.32" stopColor="#fff" stopOpacity="0.39"></stop>
                    <stop offset="0.66" stopColor="#fff" stopOpacity="0.68"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                </linearGradient>
            </defs>
            <path
                d="M372.42,312.46c-6.13,17.57-49.15,6.21-61.87,5.35q-17.91-1.21-35.84-1.8c-32.8-1.1-65.67-.69-98.43,1.23q-11.65.67-23.29,1.61c-15.46,1.23-31.12,2.81-46.41.53-1.21-.18-2.43-.38-3.65-.62-13.93-2.67-27.69-9.44-36.26-20.47a42.17,42.17,0,0,1-4.13-6.43c-8.16-15.75-5.41-34.76-9.24-52.07-3.42-15.46-12-29.24-17.27-44.18A107.19,107.19,0,0,1,107.08,57c26.24-7.67,54.69-5.13,80.8-13.18,27.56-8.52,50.51-28.21,77.83-37.5a111.45,111.45,0,0,1,74.07,1.09c27.79,10.35,52.93,39.16,44.05,67.46-3.68,11.7-12.73,21.67-13.93,33.88-2.33,23.82,25.16,40.68,28.9,64.32,4.22,26.69-21.85,46.9-38.13,68.47-4.49,5.93-8.44,12.58-9.62,19.92C347.94,280.89,377,299.36,372.42,312.46Z"
                transform="translate(-30.01 -0.47)"
                fill={Color.YELLOW}
                opacity="0.24"
                style={{isolation: "isolate"}}
            >
            </path>
            <path d="M224.32,123.64s10.11,2.62,9,5.43-5.76,5.68-12.22,9l.23,3.21s17.07-5.17,19.09-12.14a13.77,13.77,0,0,0-2.22-12Z" transform="translate(-30.01 -0.47)" fill={Color.GREEN}></path>
            <path d="M224.32,123.64s10.11,2.62,9,5.43-5.76,5.68-12.22,9l.23,3.21s17.07-5.17,19.09-12.14a13.77,13.77,0,0,0-2.22-12Z" transform="translate(-30.01 -0.47)" fill="url(#linear-gradient)"></path>
            <path d="M221.68,112.9s-6.9,4.08-8,5-1.08,3.92,1.53,7.08c3.93,4.74,7.18,3.53,7.18,3.53l6.65-6.9Z" transform="translate(-30.01 -0.47)" fill={Color.GREEN}></path>
            <path d="M221.68,112.9s-6.9,4.08-8,5-1.08,3.92,1.53,7.08c3.93,4.74,7.18,3.53,7.18,3.53l6.65-6.9Z" transform="translate(-30.01 -0.47)" fill="url(#linear-gradient-2)"></path>
            <path d="M216.21,117.24a90.42,90.42,0,0,1,24.53-26.88c16.72-12.29,41.42-21.4,49.66-18.8s-2.21,22.15-36.5,40.89c-.73.39-5.65,3.1-6.41,3.43l-23.85,10S219.16,125.66,216.21,117.24Z" transform="translate(-30.01 -0.47)" fill={Color.GREEN}></path>
            <path d="M216.21,117.24a90.42,90.42,0,0,1,24.53-26.88c16.72-12.29,41.42-21.4,49.66-18.8s-2.21,22.15-36.5,40.89c-.73.39-5.65,3.1-6.41,3.43l-23.85,10S219.16,125.66,216.21,117.24Z" transform="translate(-30.01 -0.47)" fill="url(#linear-gradient-3)"></path>
            <path d="M230.11,99.53s-7.41-7.35-15.72-3.71-20.24,21.39-20.24,21.39l4.12.23s13.75-13.06,16.23-12,2.68,10.07,2.68,10.07,5.75-.74,9.84-7.45S230.11,99.53,230.11,99.53Z" transform="translate(-30.01 -0.47)" fill={Color.GREEN}></path>
            <path d="M230.11,99.53s-7.41-7.35-15.72-3.71-20.24,21.39-20.24,21.39l4.12.23s13.75-13.06,16.23-12,2.68,10.07,2.68,10.07,5.75-.74,9.84-7.45S230.11,99.53,230.11,99.53Z" transform="translate(-30.01 -0.47)" fill="url(#linear-gradient-4)"></path>
            <path
                d="M274.71,316c-32.8-1.1-65.67-.69-98.43,1.23q-11.65.67-23.29,1.61c-15.46,1.23-31.12,2.81-46.41.53-1.21-.18-2.43-.38-3.65-.62-13.93-2.67-27.69-9.44-36.26-20.47,4.16-47.06,31.05-77.44,42.6-92.63,19.4-25.48,97.61-85.67,105.59-87.94h0c7.91-2.23,5.95,1,5.95,1l1.57,9.8h0c-1.56,1.07-124.4,85.93-67.2,138.91C185.27,295.29,232.46,309.16,274.71,316Z"
                transform="translate(-30.01 -0.47)"
                fill="url(#linear-gradient-5)"
            >
            </path>
            <path
                d="M116.23,242.86c-15.13,37.16,15.62,58.95,60.05,74.38q-11.65.67-23.29,1.61c-15.46,1.23-31.12,2.81-46.41.53-8.4-18.79-12-36-11.51-48.18,2.53-67.07,118-152.16,119.79-153.48h0l7.52,10.77h0C221.07,128.93,152.82,152.93,116.23,242.86Z"
                transform="translate(-30.01 -0.47)"
                fill="url(#linear-gradient-6)"
            >
            </path>
            <circle cx="243.42" cy="87.53" r="5.53" fill="url(#linear-gradient-7)"></circle>
            <circle cx="243.42" cy="87.53" r="4.62" fill="url(#linear-gradient-8)"></circle>
            <path
                d="M314.75,164.3c-10-4.65-21.62-6.14-32.16-3s-19.72,11.32-23.08,21.8c-.93,2.9-1.46,6-3.25,8.5-2.31,3.18-6.25,4.66-9.32,7.13-9.56,7.69-7.89,24.14,1.25,32.33s22.92,9.22,34.78,6.08c12.43,13.95,33.27,19.74,51.12,14.2,3.48,5.27,11.1,6.41,17.05,4.28s10.62-6.74,15.08-11.22l13.61-13.65c7.61-7.63,15.3-15.38,20.69-24.71s8.27-20.6,5.52-31A31,31,0,0,0,358,157.79c-1.74-11.15-11-12.73-21.61-10.52C333.75,147.81,310.55,162.34,314.75,164.3Z"
                transform="translate(-30.01 -0.47)"
                fill="url(#linear-gradient-9)"
            >
            </path>
            <path
                d="M95.48,108.06c5.11-2.81,11-4.82,16.74-3.65s10.87,6.39,10.3,12.2a11.15,11.15,0,0,1,6.8,20.71c6.1,4.57,5.86,14.61.84,20.34s-13.28,7.65-20.88,7.09-14.85-3.25-22.18-5.33a12.62,12.62,0,0,0-6.13-.63c-2.24.52-4,2.16-5.84,3.57a36.34,36.34,0,0,1-29.45,6.77,19.45,19.45,0,0,1-9-4.14c-3.49-3.13-5.08-7.9-5.61-12.56-1.43-12.55,5-26.35,16.79-31,0,0,1.58-6.55,2-7.31a19.82,19.82,0,0,1,5.38-6.42c4-3.11,9.19-4.75,13.94-6.29C78.4,98.39,92.84,94.16,95.48,108.06Z"
                transform="translate(-30.01 -0.47)"
                fill="url(#linear-gradient-10)"
            >
            </path>
        </svg>
    );
};

export default SvgFavoritesIndex;
