import {ApiModel} from "../../../common/api/model/ApiModel";
import {TaxonomyElementType} from "./TaxonomyElementType";
import {SegmentDataType} from "../segment/SegmentDataType";
import {QueryString} from "../../../common/api/model/QueryString";

export const TAXONOMY_QUERY_DEFAULT_PAGE_SIZE = 10;
export const TAXONOMY_QUERY_MAX_PAGE_SIZE = 250;

export class TaxonomyQueryParams extends ApiModel {
    partnerId?: number;
    types?: TaxonomyElementType[];
    link?: string;
    topLink?: string;
    cookieless?: boolean;
    minVolume?: number;
    segmentDataTypes?: SegmentDataType[];
    categoryBrandSafety?: boolean;
    search?: string;
    tags?: string[];
    page: number = 0;
    size: number = TAXONOMY_QUERY_DEFAULT_PAGE_SIZE;
    sortBy?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    toQueryString(): string {
        return QueryString.build({
            "partnerId": this.partnerId,
            "types": this.types?.length !== 3 && this.types?.join(","),
            "link": this.link,
            "topLink": this.topLink,
            "cookieless": this.cookieless !== undefined && this.cookieless.toString(),
            "minVolume": this.minVolume,
            "segmentDataTypes": this.segmentDataTypes?.join(","),
            "categoryBrandSafety": this.categoryBrandSafety && this.categoryBrandSafety.toString(),
            "search": this.search,
            "tags": this.tags?.length && this.tags?.join(","),
            "page": this.page,
            "size": this.size !== TAXONOMY_QUERY_DEFAULT_PAGE_SIZE && this.size,
            "sortBy": this.sortBy
        });
    }
}
