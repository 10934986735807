import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {Link} from "react-scroll";
import {ACTIVATION_PARTNERS, DATA_PARTNERS, MEDIA_PARTNERS} from "../api/model/Partner";
import SvgMediaPartners from "../component/svg/SvgMediaPartners";
import SvgDataPartners from "../component/svg/SvgDataPartners";
import SvgActivationPartners from "../component/svg/SvgActivationPartners";
import {MainContent, Wrapper} from "../common/component/widget";
import {FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, GoToTop, LayoutRows, MainContentPageHeader, MainContentStyle, Paragraph} from "@sirdata/ui-lib";
import {PartnerCard} from "../component/snippet/index";
import {MainHeader} from "../common/component/snippet";

function Partners() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <LayoutRows>
                    <section className="partners">
                        <div className="partners-section">
                            <MainContentPageHeader title={textPartners("title")} icon={{name: "partner_exchange"}}/>
                            <section className="partners-table-of-contents">
                                <div className="table-of-contents-items">
                                    <Link smooth offset={-50} to="media-partners" className="table-of-contents-item">{textPartners("media_partners.title")}</Link>
                                    <Link smooth offset={-50} to="activation-partners" className="table-of-contents-item">{textPartners("activation_partners.title")}</Link>
                                    <Link smooth offset={-50} to="data-partners" className="table-of-contents-item">{textPartners("data_partners.title")}</Link>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section className="partners" id="media-partners">
                        <div className="partners-section">
                            <h2 className="partners-section-title">{textPartners("media_partners.title")}</h2>
                            <div className="partners-section-info">
                                <FlexContent
                                    direction={FlexContentDirection.ROW}
                                    alignment={FlexContentAlignment.CENTER}
                                    layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}
                                    spacing={FlexContentSpacing.MEDIUM}
                                    mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE}
                                >
                                    <div>
                                        <h3>{textPartners("media_partners.question")}</h3>
                                        <Paragraph>{textPartners("media_partners.description")}</Paragraph>
                                    </div>
                                    <SvgMediaPartners/>
                                </FlexContent>
                            </div>
                            <div className="grid-cards">
                                {MEDIA_PARTNERS.map((partner) =>
                                    <PartnerCard key={partner.name} partner={partner}/>
                                )}
                            </div>
                        </div>
                    </section>
                    <section className="partners partners-midnight" id="activation-partners">
                        <div className="partners-section">
                            <h2 className="partners-section-title">{textPartners("activation_partners.title")}</h2>
                            <div className="partners-section-info">
                                <FlexContent
                                    direction={FlexContentDirection.ROW}
                                    alignment={FlexContentAlignment.CENTER}
                                    layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}
                                    spacing={FlexContentSpacing.MEDIUM}
                                    mobileDirection={FlexContentMobileDirection.COLUMN}
                                >
                                    <SvgActivationPartners/>
                                    <div>
                                        <h3>{textPartners("activation_partners.question")}</h3>
                                        <Paragraph>{textPartners("activation_partners.description")}</Paragraph>
                                    </div>
                                </FlexContent>
                            </div>
                            <div className="grid-cards">
                                {ACTIVATION_PARTNERS.map((partner) =>
                                    <PartnerCard key={partner.name} partner={partner}/>
                                )}
                            </div>
                        </div>
                    </section>
                    <section className="partners" id="data-partners">
                        <div className="partners-section">
                            <h2 className="partners-section-title">{textPartners("data_partners.title")}</h2>
                            <div className="partners-section-info">
                                <FlexContent
                                    direction={FlexContentDirection.ROW}
                                    alignment={FlexContentAlignment.CENTER}
                                    layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}
                                    spacing={FlexContentSpacing.MEDIUM}
                                    mobileDirection={FlexContentMobileDirection.COLUMN_REVERSE}
                                >
                                    <div>
                                        {DATA_PARTNERS.map((partner) =>
                                            <div key={partner.name} className="partners-block-with-text">
                                                <PartnerCard key={partner.name} partner={partner}/>
                                                <div className="partners-block-text">{textPartners(`data_partners.texts.${partner.name.replace(" ", "_")}`)}</div>
                                            </div>
                                        )}
                                    </div>
                                    <SvgDataPartners/>
                                </FlexContent>
                            </div>
                        </div>
                    </section>
                </LayoutRows>
            </MainContent>
            <GoToTop/>
        </Wrapper>
    );
}

export default Partners;
