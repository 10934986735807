import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CountOver} from "./KeywordStats";
import {Threshold} from "./Threshold";

export class KeywordsOverview extends ApiModel {
    sample_urls: StatsSampleUrls[] = [];
    count: number = 0;
    count_over: CountOver = {
        [Threshold.THRESHOLD_500]: 0,
        [Threshold.THRESHOLD_600]: 0,
        [Threshold.THRESHOLD_700]: 0,
        [Threshold.THRESHOLD_800]: 0,
        [Threshold.THRESHOLD_900]: 0
    };
}

export interface StatsSampleUrls {
    url: string;
    score: number;
    terms: string[];
}

