import {ApiModel} from "../../../common/api/model/ApiModel";
import {CuratedDealDeviceType} from "./CuratedDealDeviceType";
import {CuratedDealPlatform} from "./CuratedDealPlatform";
import {pathDeal} from "../../../utils/constants";
import {CuratedDealFloorPrice} from "./CuratedDealFloorPrice";
import {CuratedDealTargetingOrigin} from "./CuratedDealTargetingOrigin";
import {Currency} from "../Currency";
import {TaxonomyPathItem} from "../taxonomy/TaxonomyNode";
import {capitalize} from "../../../common/utils/string";
import {CuratedDealRecommendationType} from "./CuratedDealRecommendationType";
import {TaxonomyPlatform} from "../taxonomy/TaxonomyPlatform";
import {TaxonomyElement} from "../taxonomy/TaxonomyElement";
import {TaxonomyElementType} from "../taxonomy/TaxonomyElementType";
import {PathHelper} from "../../../common/utils/PathHelper";

export class CuratedDeal extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    targeting_origin: CuratedDealTargetingOrigin = new CuratedDealTargetingOrigin();
    device_type: CuratedDealDeviceType[] = [];
    inventory_type: string[] = [];
    floor_price: CuratedDealFloorPrice = new CuratedDealFloorPrice();
    viewability: number = 0;
    country: string[] = [];
    path_element: TaxonomyPathItem = {name: "", link: ""};
    top_path_element?: TaxonomyPathItem;
    parent_path_element?: TaxonomyPathItem;
    private _platform: Map<TaxonomyPlatform, CuratedDealPlatform[]> = new Map();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set platform(platform: Map<TaxonomyPlatform, CuratedDealPlatform[]>) {
        if (!platform) return;

        const values = Array.from(Object.entries(platform).length ? new Map(Object.entries(platform)) : platform);
        const mapPlatform: Map<TaxonomyPlatform, CuratedDealPlatform[]> = new Map();
        values.forEach(([curatedPlatform, curatedDealPlatform]) => {
            const items: CuratedDealPlatform[] = Array.from(curatedDealPlatform);
            mapPlatform.set(curatedPlatform as TaxonomyPlatform, items.filter((it) => it.active));

        });
        this._platform = mapPlatform;
    }

    get platform(): Map<TaxonomyPlatform, CuratedDealPlatform[]> {
        return this._platform;
    }

    isContextual(): boolean {
        return !!this.targeting_origin.contextual;
    }

    isUserBased(): boolean {
        return !!this.targeting_origin.user_centric;
    }

    getCountriesAsString(): string {
        return capitalize((this.country.join(", ") || "").toLowerCase());
    }

    getFloorPrice(currency: Currency): number {
        if (currency === Currency.EUR) {
            return this.floor_price.eur;
        } else if (currency === Currency.USD) {
            return this.floor_price.usd;
        } else if (currency === Currency.GBP) {
            return this.floor_price.eur;
        }
        return 0;
    }

    getRecommendations(): CuratedDealRecommendationType[] {
        const platform = this._platform.get(TaxonomyPlatform.XANDR);
        if (platform && platform[0]) {
            return platform[0].recommendation;
        }
        return [];
    }

    getTotalImpressions(): number {
        return this.getRecommendations().reduce((total, {impression}) => {
            return total + impression;
        }, 0);
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathDeal, this.id);
    };

    toTaxonomyElement(): TaxonomyElement {
        const element = new TaxonomyElement();
        element.type = TaxonomyElementType.CURATED_DEAL;
        element.audience_curated_deal = this;
        return element;
    }
}
