export enum TaxonomySortType {
    DEFAULT = "default",
    PRICE = "price",
    VOLUME = "volume",
}

export type TaxonomySortTypeItem = {
    type: TaxonomySortType;
    field: string;
}

export const TAXONOMY_SORT_TYPES: TaxonomySortTypeItem[] = [
    {type: TaxonomySortType.DEFAULT, field: ""},
    {type: TaxonomySortType.PRICE, field: "price"},
    {type: TaxonomySortType.VOLUME, field: "volume"}
];

export const defaultTaxonomySortTypeItem = TAXONOMY_SORT_TYPES[0];

export function getTaxonomySortType(type: TaxonomySortType | string): TaxonomySortTypeItem {
    return TAXONOMY_SORT_TYPES.find((it) => it.type === type) || defaultTaxonomySortTypeItem;
}
