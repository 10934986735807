import {TaxonomyElementType} from "./TaxonomyElementType";

export enum TaxonomyPlatform {
    ADFORM = "Adform",
    ADOBE = "Adobe",
    ADSQUARE = "Adsquare",
    AMAZON = "Amazon_compte_public",
    BEESWAX = "Beeswax",
    BIDSWITCH = "Bidswitch",
    CRITEO = "Criteo",
    DYNADMIC = "DynAdmic",
    FACEBOOK = "Facebook",
    FREEWHEEL = "Freewheel",
    GOOGLE_DV360 = "Google",
    MAGNITE = "Rubicon",
    MEDIAMATH = "MediaMath",
    PROXISTORE = "Proxistore_dv360",
    PUBMATIC = "Pubmatic",
    SIRDATA_API = "Sirdata API",
    SIRDATA_ADS = "Sirdata ADS",
    SIRDATA_PREBID = "Sirdata Prebid",
    EQUATIV = "Smart",
    TABOOLA = "Taboola",
    THE_TRADE_DESK = "TheTradeDesk",
    THE_TRADE_DESK_EUID = "TheTradeDeskEUID",
    XANDR = "Nexus",
    XANDR_COOKIELESS = "XandrCookieless",
    YAHOO = "Yahoo",
    ZEMANTA = "Zemanta",
}

export type TaxonomyPlatformItem = {
    value: string;
    name: string;
    img: string;
    link?: string;
}

export const TAXONOMY_PLATFORMS: TaxonomyPlatformItem[] = [
    {value: TaxonomyPlatform.SIRDATA_ADS, name: "Sirdata ADS", img: "/images/platforms/sirdata-ads.png"},
    {value: TaxonomyPlatform.SIRDATA_API, name: "Sirdata API", img: "/images/platforms/sirdata.png"},
    {value: TaxonomyPlatform.ADFORM, name: "Adform", img: "/images/platforms/adform.png"},
    {value: TaxonomyPlatform.ADOBE, name: "Adobe", img: "/images/platforms/adobe.png"},
    {value: TaxonomyPlatform.ADSQUARE, name: "Adsquare", img: "/images/platforms/adsquare.png"},
    {value: TaxonomyPlatform.AMAZON, name: "Amazon", img: "/images/platforms/amazon.png"},
    {value: TaxonomyPlatform.BEESWAX, name: "Beeswax", img: "/images/platforms/beeswax.png"},
    {value: TaxonomyPlatform.BIDSWITCH, name: "Bidswitch", img: "/images/platforms/bidswitch.png"},
    {value: TaxonomyPlatform.CRITEO, name: "Criteo", img: "/images/platforms/criteo.png"},
    {value: TaxonomyPlatform.DYNADMIC, name: "DynAdmic", img: "/images/platforms/dynadmic.png"},
    {value: TaxonomyPlatform.FACEBOOK, name: "Meta", img: "/images/platforms/meta.png"},
    {value: TaxonomyPlatform.FREEWHEEL, name: "Freewheel", img: "/images/platforms/freewheel.png"},
    {value: TaxonomyPlatform.GOOGLE_DV360, name: "Google DV360/GAM", img: "/images/platforms/google-dv360.png"},
    {value: TaxonomyPlatform.MAGNITE, name: "Magnite", img: "/images/platforms/magnite.png"},
    {value: TaxonomyPlatform.MEDIAMATH, name: "MediaMath", img: "/images/platforms/mediamath.png"},
    {value: TaxonomyPlatform.PROXISTORE, name: "Proxistore", img: "/images/platforms/proxistore.png"},
    {value: TaxonomyPlatform.PUBMATIC, name: "PubMatic", img: "/images/platforms/pubmatic.png"},
    {value: TaxonomyPlatform.SIRDATA_PREBID, name: "Sirdata Prebid", img: "/images/platforms/prebiid.png", link: "https://docs.prebid.org/dev-docs/modules/sirdataRtdProvider.html"},
    {value: TaxonomyPlatform.EQUATIV, name: "Equativ", img: "/images/platforms/equativ.png"},
    {value: TaxonomyPlatform.TABOOLA, name: "Taboola", img: "/images/platforms/taboola.png"},
    {value: TaxonomyPlatform.THE_TRADE_DESK, name: "TheTradeDesk", img: "/images/platforms/the-trade-desk.png"},
    {value: TaxonomyPlatform.THE_TRADE_DESK_EUID, name: "TheTradeDesk EUID", img: "/images/platforms/the-trade-desk-euid.png"},
    {value: TaxonomyPlatform.XANDR, name: "Xandr DSP/SSP", img: "/images/platforms/xandr.png"},
    {value: TaxonomyPlatform.XANDR_COOKIELESS, name: "Xandr Cookieless", img: "/images/platforms/xandr.png"},
    {value: TaxonomyPlatform.YAHOO, name: "Yahoo", img: "/images/platforms/yahoo.png"},
    {value: TaxonomyPlatform.ZEMANTA, name: "Zemanta", img: "/images/platforms/zemanta.png"}
];

export function getTaxonomyPlatform(value: string): TaxonomyPlatformItem {
    return TAXONOMY_PLATFORMS.find((it) => it.value === value) || {value: "", name: "", img: ""};
}

export const SEGMENT_PLATFORMS: TaxonomyPlatformItem[] = [
    getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_API),
    getTaxonomyPlatform(TaxonomyPlatform.ADOBE),
    getTaxonomyPlatform(TaxonomyPlatform.AMAZON),
    getTaxonomyPlatform(TaxonomyPlatform.FACEBOOK),
    getTaxonomyPlatform(TaxonomyPlatform.FREEWHEEL),
    getTaxonomyPlatform(TaxonomyPlatform.GOOGLE_DV360),
    getTaxonomyPlatform(TaxonomyPlatform.MAGNITE),
    getTaxonomyPlatform(TaxonomyPlatform.PROXISTORE),
    getTaxonomyPlatform(TaxonomyPlatform.PUBMATIC),
    getTaxonomyPlatform(TaxonomyPlatform.EQUATIV),
    getTaxonomyPlatform(TaxonomyPlatform.THE_TRADE_DESK),
    getTaxonomyPlatform(TaxonomyPlatform.THE_TRADE_DESK_EUID),
    getTaxonomyPlatform(TaxonomyPlatform.XANDR),
    getTaxonomyPlatform(TaxonomyPlatform.XANDR_COOKIELESS),
    getTaxonomyPlatform(TaxonomyPlatform.YAHOO)
];

export const HOME_PLATFORMS: TaxonomyPlatformItem[] =
    SEGMENT_PLATFORMS.filter((it) => ![TaxonomyPlatform.SIRDATA_API, TaxonomyPlatform.XANDR_COOKIELESS].includes(it.value as TaxonomyPlatform));

export const CATEGORY_PLATFORMS: TaxonomyPlatformItem[] = [
    getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_API),
    getTaxonomyPlatform(TaxonomyPlatform.SIRDATA_PREBID),
    getTaxonomyPlatform(TaxonomyPlatform.EQUATIV),
    getTaxonomyPlatform(TaxonomyPlatform.XANDR)
];

export const CURATED_DEAL_PLATFORMS: TaxonomyPlatformItem[] = [
    getTaxonomyPlatform(TaxonomyPlatform.ADFORM),
    getTaxonomyPlatform(TaxonomyPlatform.ADSQUARE),
    getTaxonomyPlatform(TaxonomyPlatform.AMAZON),
    getTaxonomyPlatform(TaxonomyPlatform.BEESWAX),
    getTaxonomyPlatform(TaxonomyPlatform.BIDSWITCH),
    getTaxonomyPlatform(TaxonomyPlatform.CRITEO),
    getTaxonomyPlatform(TaxonomyPlatform.DYNADMIC),
    getTaxonomyPlatform(TaxonomyPlatform.GOOGLE_DV360),
    getTaxonomyPlatform(TaxonomyPlatform.MEDIAMATH),
    getTaxonomyPlatform(TaxonomyPlatform.TABOOLA),
    getTaxonomyPlatform(TaxonomyPlatform.THE_TRADE_DESK),
    getTaxonomyPlatform(TaxonomyPlatform.XANDR),
    getTaxonomyPlatform(TaxonomyPlatform.YAHOO),
    getTaxonomyPlatform(TaxonomyPlatform.ZEMANTA)
];

export const CURATED_DEAL_SUPPLY_PLATFORMS: TaxonomyPlatformItem[] = [
    getTaxonomyPlatform(TaxonomyPlatform.XANDR),
    getTaxonomyPlatform(TaxonomyPlatform.EQUATIV)
];

export function getTaxonomyPlatformItem(type: TaxonomyElementType, value: string): TaxonomyPlatformItem | undefined {
    switch (type) {
        case TaxonomyElementType.SEGMENT:
            return SEGMENT_PLATFORMS.find((it) => it.value === value);
        case TaxonomyElementType.CATEGORY:
            return CATEGORY_PLATFORMS.find((it) => it.value === value);
        case TaxonomyElementType.CURATED_DEAL:
            return CURATED_DEAL_PLATFORMS.find((it) => it.value === value);
    }
}
