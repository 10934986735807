import React, {FunctionComponent} from "react";

type SvgIabChipProps = {
    currentColor?: string;
}

const SvgIabChip: FunctionComponent<SvgIabChipProps> = ({currentColor}) => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.44 76.38">
            <path fill={currentColor || "#e42616"} d="M10.53,0A10.53,10.53,0,1,0,21.07,10.53,10.54,10.54,0,0,0,10.53,0Z"/>
            <path fill={currentColor || "#e42616"} d="M142.9,55.32a10.53,10.53,0,1,0,10.54,10.53A10.54,10.54,0,0,0,142.9,55.32Z"/>
            <path fill={currentColor || "#2a2522"} d="M17.64,26.48H3.27C2,26.48,2,27.19,2,28.27v45c0,1.23.07,2,1.42,2H17.74c1.33,0,1.51-.86,1.51-1.69V28.27C19.25,26.9,18.87,26.48,17.64,26.48Z"/>
            <path
                fill={currentColor || "#2a2522"}
                d="M73.06,26.52H58.6c-1.41,0-1.41,1.35-1.41,1.79v3.22a15,15,0,0,0-12-5.58c-12.65,0-22.55,10.94-22.55,24.9,0,15,9.17,25.09,22.83,25.09,4.66,0,9.91-1.68,12.58-5.36v3.19a1.47,1.47,0,0,0,1.6,1.51H73q1.41,0,1.41-2V28A1.34,1.34,0,0,0,73.06,26.52ZM48.45,60.34a8.78,8.78,0,0,1-8.92-9c0-5.61,3.59-9.39,8.92-9.39,5.14,0,8.74,3.79,8.74,9.2S53.51,60.34,48.45,60.34Z"
            />
            <path
                fill={currentColor || "#2a2522"}
                d="M108.19,25.87c-5.1,0-9.65,1.91-12.12,4.93V3.14c0-1.33-.37-1.74-1.56-1.74h-14c-1.28,0-1.28.69-1.28,1.74V46.86c0,.16,0,.32,0,.46V73.41c0,1.1,0,1.88,1.41,1.88H92.12c2,0,2.58-1,2.71-1.64l.72-3.12c2.84,3.32,7.78,5.33,13.29,5.33,13.27,0,22.18-10.08,22.18-25.09S121.84,25.87,108.19,25.87Zm-2.92,34.39c-5.06,0-8.73-3.87-8.73-9.21,0-5.5,3.51-9.2,8.73-9.2s8.83,3.79,8.83,9.2C114.1,56.3,110.31,60.26,105.27,60.26Z"
            />
        </svg>
    );
};

export default SvgIabChip;
