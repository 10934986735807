export enum CampaignBriefFormat {
    DISPLAY = "DISPLAY",
    VIDEO = "VIDEO",
    NATIVE = "NATIVE"
}

export const CAMPAIGN_BRIEF_FORMATS = [
    CampaignBriefFormat.DISPLAY,
    CampaignBriefFormat.VIDEO,
    CampaignBriefFormat.NATIVE
];
