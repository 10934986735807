import {Color, IconProps} from "@sirdata/ui-lib";
import {TaxonomyTargetingType} from "./TaxonomyTargetingType";
import {TaxonomyModelingType} from "./TaxonomyModelingType";
import {TaxonomyActivationType} from "./TaxonomyActivationType";

export type TaxonomyTag = {
    name: TaxonomyTargetingType | TaxonomyModelingType | TaxonomyActivationType | string;
    color: { value: string; isReverse?: boolean };
    icon?: IconProps;
}

const TAXONOMY_TAGS: TaxonomyTag[] = [
    {
        name: "all",
        color: {value: Color.MIDNIGHT_LIGHT},
        icon: {name: "filter_alt_off"}
    },
    {
        name: TaxonomyTargetingType.USER,
        color: {value: Color.CYAN},
        icon: {name: "person"}
    },
    {
        name: TaxonomyTargetingType.CONTEXTUAL,
        color: {value: Color.PURPLE},
        icon: {name: "wallpaper"}
    },
    {
        name: TaxonomyModelingType.COOKIE,
        color: {value: Color.MIDNIGHT_LIGHT},
        icon: {name: "cookie"}
    },
    {
        name: TaxonomyModelingType.COOKIELESS,
        color: {value: Color.INFO_MAIN, isReverse: true},
        icon: {name: "block", outlined: true}
    },
    {
        name: TaxonomyActivationType.SELF_SERVICE,
        color: {value: Color.MIDNIGHT_LIGHT, isReverse: true},
        icon: {name: "storefront", outlined: true}
    },
    {
        name: TaxonomyActivationType.DEAL,
        color: {value: Color.MIDNIGHT_LIGHT, isReverse: true},
        icon: {name: "gavel"}
    }
];

export function getTaxonomyTag(tag: TaxonomyTargetingType | TaxonomyModelingType | TaxonomyActivationType | string): TaxonomyTag | undefined {
    return TAXONOMY_TAGS.find((it) => it.name === tag);
}

export function getTaxonomyTagColor(tag: TaxonomyTargetingType | TaxonomyModelingType | TaxonomyActivationType): string {
    return getTaxonomyTag(tag)?.color.value || Color.MIDNIGHT_LIGHT;
}

export function getTaxonomyTagIcon(tag: TaxonomyTargetingType | TaxonomyModelingType | TaxonomyActivationType): IconProps | undefined {
    return getTaxonomyTag(tag)?.icon;
}
