import Hashids from "hashids";
import {NumberLike} from "hashids/cjs/util";

const hashids = new Hashids();

export default class Hash {
    static encode(ids: number[] | string[]): string {
        return hashids.encode(ids);
    }

    static decode(hash: string): NumberLike[] {
        return hashids.decode(hash);
    }
}
