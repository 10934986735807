import {ApiModel} from "../../../common/api/model/ApiModel";
import {PartnerStatsCountry} from "./PartnerStatsCountry";

export class PartnerStats extends ApiModel {
    _print_volumes: Map<PartnerStatsCountry, number> = new Map();

    set print_volumes(print_volumes: Map<string, number> | undefined) {
        if (!print_volumes) return;

        const values = Array.from(Object.entries(print_volumes).length ? new Map(Object.entries(print_volumes)) : print_volumes);
        const printVolumes: Map<PartnerStatsCountry, number> = new Map();
        values.forEach((it) => printVolumes.set(it[0] as PartnerStatsCountry, it[1]));
        this._print_volumes = printVolumes;
    }

    get print_volumes(): Map<PartnerStatsCountry, number> | undefined {
        return this._print_volumes;
    }
}

