import {FunctionComponent, useEffect, useState} from "react";
import clsx from "clsx";
import {CURRENCIES} from "../../../api/model/Currency";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {IconTooltip, Select, Tooltip} from "@sirdata/ui-lib";
import {TAXONOMY_PLATFORMS, TaxonomyPlatform} from "../../../api/model/taxonomy/TaxonomyPlatform";
import {TAXONOMY_SORT_TYPES, TaxonomySortType, TaxonomySortTypeItem} from "../../../api/model/taxonomy/TaxonomySortType";
import {TaxonomyPathItem} from "../../../api/model/taxonomy/TaxonomyNode";

type TaxonomySettingToolbarProps = {
    initialFilters: TaxonomyFilters;
    onChange: (filters: TaxonomyFilters, requireRefresh: boolean) => void;
    verticals?: TaxonomyPathItem[];
    enableSort?: boolean;
};

const TaxonomySettingToolbar: FunctionComponent<TaxonomySettingToolbarProps> = ({initialFilters, onChange, verticals, enableSort}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [filters, setFilters] = useState<TaxonomyFilters>(new TaxonomyFilters());

    const handleChange = (field: string, value: any, requireRefresh: boolean = false) => {
        const newFilters = new TaxonomyFilters({...filters, [field]: value});
        setFilters(newFilters);
        onChange(newFilters, requireRefresh);
    };

    useEffect(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    return (
        <div className="taxonomy-settings">
            <div className="taxonomy-settings__items">
                {!!verticals?.length &&
                    <div className="setting-item">
                        <div className={clsx("setting-item__content", {"setting-item__content--active": !!filters.vertical})}>
                            <span className="setting-item__content__label">{textTaxonomy("filters.vertical.title")}</span>
                            <Select
                                value={filters.vertical}
                                options={[{value: "", label: textTaxonomy("filters.vertical.all")}, ...verticals.map((it) => ({value: it.link, label: it.name}))]}
                                onChange={(option) => handleChange("vertical", option?.value.toString(), true)}
                                small
                            />
                        </div>
                    </div>
                }
                <div className="setting-item">
                    <div className={clsx("setting-item__content", {"setting-item__content--active": !!filters.platform})}>
                        <span className="setting-item__content__label">{textTaxonomy("filters.platform.title")}</span>
                        <Select
                            value={filters.platform}
                            options={[{value: "", label: textTaxonomy("filters.platform.all")}, ...TAXONOMY_PLATFORMS.filter((it) => it.value !== TaxonomyPlatform.SIRDATA_ADS).map((it) => ({value: it.value, label: it.name}))]}
                            onChange={(option) => handleChange("platform", option?.value.toString())}
                            small
                        />
                    </div>
                </div>
                {enableSort &&
                    <div className="setting-item">
                        <div className={clsx("setting-item__content", {"setting-item__content--active": !!filters.sortType.type})}>
                            <span className="setting-item__content__label">{textTaxonomy("filters.sort.title")}</span>
                            <Select
                                value={filters.sortType.type}
                                options={TAXONOMY_SORT_TYPES.map((it) => ({value: it.type, label: textTaxonomy(`filters.sort.${it.type}`)!, sortType: it}))}
                                onChange={(option) => handleChange("sortType", option?.sortType as TaxonomySortTypeItem, true)}
                                small
                            />
                            {filters.sortType.type !== TaxonomySortType.DEFAULT &&
                                <IconTooltip
                                    icon={{name: "sort"}}
                                    text={filters.descendingSortOrder ? textTaxonomy("filters.sort.order_descending") : textTaxonomy("filters.sort.order_ascending")}
                                    cssClass={clsx("setting-item__content__icon", {"setting-item__content__icon--reversed": !filters.descendingSortOrder})}
                                    onClick={() => handleChange("descendingSortOrder", !filters.descendingSortOrder, true)}
                                />
                            }
                        </div>
                    </div>
                }
                <div className="setting-item">
                    <div className="setting-item__content">
                        <span className="setting-item__content__label">{textTaxonomy("filters.currency.title")}</span>
                        <div className="setting-item__content__field">
                            {CURRENCIES.map((currency) =>
                                <Tooltip
                                    key={currency.name}
                                    text={textTaxonomy(`filters.currency.${currency.name}`)}
                                    cssClass={clsx("setting-item__content__field__link", {"setting-item__content__field__link--active": filters.currency === currency})}
                                    onClick={() => handleChange("currency", currency)}
                                >
                                    {currency.symbol}
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaxonomySettingToolbar;
