import i18next from "i18next";
import {Formatter} from "@sirdata/ui-lib";
import {buildAndDownloadCSV} from "../common/utils/portal";
import {getCuratedDealStepFromValue} from "../api/model/curated-deal/CuratedDealStepSize";
import {getSegmentStepFromValue} from "../api/model/segment/SegmentStepSize";
import {TAXONOMY_PLATFORMS, TaxonomyPlatform} from "../api/model/taxonomy/TaxonomyPlatform";
import {TaxonomyElementType} from "../api/model/taxonomy/TaxonomyElementType";
import {TaxonomyActivationType} from "./taxonomy/TaxonomyActivationType";
import {TaxonomyTargetingType} from "./taxonomy/TaxonomyTargetingType";
import {TaxonomyFilters} from "./taxonomy/TaxonomyFilters";
import {TaxonomyElement} from "../api/model/taxonomy/TaxonomyElement";
import {TranslationPortalFile} from "./constants";

export const exportTaxonomyElements = (fileTitle: string, elements: TaxonomyElement[], filters: TaxonomyFilters) => {
    const platforms = TAXONOMY_PLATFORMS.filter((platform) => {
        if (platform.value === TaxonomyPlatform.SIRDATA_API || platform.value === TaxonomyPlatform.SIRDATA_ADS) {
            return false;
        }
        return !filters.platform || platform.value === filters.platform;
    });

    const headers = [
        "ID",
        i18next.t("info.vertical", {ns: TranslationPortalFile.TAXONOMY}),
        i18next.t("info.name", {ns: TranslationPortalFile.TAXONOMY}),
        "Type",
        i18next.t("info.activation", {ns: TranslationPortalFile.TAXONOMY}),
        "Description",
        i18next.t("info.volume", {ns: TranslationPortalFile.TAXONOMY}),
        i18next.t("info.cpm", {ns: TranslationPortalFile.TAXONOMY})
    ].concat(platforms.map((it) => `${it.name} ID`));

    let rows: any[][] = [];

    if (elements.length) {
        elements.forEach((element) => {
            switch (element.type) {
                case TaxonomyElementType.SEGMENT:
                    const segment = element.audience_segment;
                    if (segment) {
                        const platformIds = platforms.map((platform) => segment.getPlatformId(platform));

                        rows.push([
                            segment.id,
                            segment.top_path_element?.name,
                            segment.full_name,
                            segment.data_type.toUpperCase(),
                            i18next.t(`tag.${TaxonomyActivationType.SELF_SERVICE}`, {ns: TranslationPortalFile.TAXONOMY}),
                            segment.description,
                            getSegmentStepFromValue(segment.step_volume),
                            `${segment.getPrice(filters.currency.currency) / 100} ${filters.currency.symbol}`,
                            ...platformIds
                        ]);
                    }
                    break;

                case TaxonomyElementType.CATEGORY:
                    const category = element.audience_category;
                    if (category) {
                        let platformIds = platforms.map((platform) => category.getPlatformId(platform));

                        rows.push([
                            category.id,
                            category.top_path_element?.name,
                            category.name,
                            TaxonomyTargetingType.CONTEXTUAL.toUpperCase(),
                            i18next.t(`tag.${TaxonomyActivationType.SELF_SERVICE}`, {ns: TranslationPortalFile.TAXONOMY}),
                            category.description,
                            "",
                            `${category.getPrice(filters.currency.currency) / 100} ${filters.currency.symbol}`,
                            ...platformIds
                        ]);
                    }
                    break;

                case TaxonomyElementType.CURATED_DEAL:
                    const deal = element.audience_curated_deal;
                    if (deal) {
                        let platformIds = platforms.map((_) => "");

                        rows.push([
                            deal.id,
                            deal.top_path_element?.name,
                            deal.name,
                            deal.isContextual() ? TaxonomyTargetingType.CONTEXTUAL.toUpperCase() : deal.isUserBased() ? TaxonomyTargetingType.USER.toUpperCase() : "",
                            i18next.t(`tag.${TaxonomyActivationType.DEAL}`, {ns: TranslationPortalFile.TAXONOMY}),
                            deal.description,
                            getCuratedDealStepFromValue(deal.getTotalImpressions()),
                            `${Formatter.formatNumber(deal.getFloorPrice(filters.currency.currency) / 100)} ${filters.currency.symbol}`,
                            ...platformIds
                        ]);
                    }
                    break;

                default:
                    rows.push([]);
            }
        });
    }

    buildAndDownloadCSV(`Sirdata Audience_${fileTitle}`, headers, rows);
};
