import React, {FunctionComponent} from "react";
import {Color, Tag, TagSize, TagTextTransform} from "@sirdata/ui-lib";
import {getTaxonomyTag} from "../../../utils/taxonomy/TaxonomyTag";

type TagTaxonomyProps = {
    type: string;
};

const TagTaxonomy: FunctionComponent<TagTaxonomyProps> = ({type}) => {
    const tag = getTaxonomyTag(type);
    if (!tag) return <></>;

    let color = tag.color.isReverse ? tag.color.value : Color.WHITE;
    let backgroundColor = tag.color.isReverse ? Color.WHITE : tag.color.value;

    return (
        <Tag
            size={TagSize.SMALL}
            customColor={{backgroundColor: backgroundColor || Color.WHITE, color: color || Color.MIDNIGHT_LIGHT, borderColor: tag.color.value}}
            label={tag.name}
            textTransform={TagTextTransform.CAPITALIZE}
            icon={tag.icon ? {...tag.icon} : undefined}
        />
    );
};

export default TagTaxonomy;
