import {ApiModel} from "../../../common/api/model/ApiModel";
import {CampaignBriefDistribution} from "./CampaignBriefDistribution";
import {CAMPAIGN_BRIEF_FORMATS, CampaignBriefFormat} from "./CampaignBriefFormat";
import {CampaignBriefObjective, defaultCampaignBriefObjective} from "./CampaignBriefObjective";

export class CampaignBrief extends ApiModel {
    partner_id: number = 0;
    format: CampaignBriefFormat[] = CAMPAIGN_BRIEF_FORMATS;
    objective: CampaignBriefObjective = defaultCampaignBriefObjective;
    start_date: string = "";
    end_date?: string;
    segments: number[] = [];
    comment?: string;
    distributions: CampaignBriefDistribution[] = [];
    limit_date?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            format: this.format,
            objective: this.objective,
            start_date: this.start_date,
            end_date: this.start_date,
            segments: this.segments,
            comment: this.comment,
            distributions: this.distributions,
            limit_date: this.limit_date
        };
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    isDistributionExist(distribution: CampaignBriefDistribution) {
        return !!this.distributions.find((it) => (distribution.platform === it.platform && distribution.seat_id === it.seat_id));
    }

    removeDistribution(distribution: CampaignBriefDistribution) {
        this.distributions = this.distributions
            .filter((it) => !(distribution.platform === it.platform && distribution.seat_id === it.seat_id));
    }
}
