import {ApiModel} from "../../../common/api/model/ApiModel";
import {Taxonomy} from "../Taxonomy";
import {pathContextualCategory} from "../../../utils/constants";
import {MapLink} from "../MapLink";
import {TaxonomyPathItem} from "../taxonomy/TaxonomyNode";
import {TaxonomyElement} from "../taxonomy/TaxonomyElement";
import {TaxonomyElementType} from "../taxonomy/TaxonomyElementType";
import {Currency} from "../Currency";
import {PathHelper} from "../../../common/utils/PathHelper";
import {TaxonomyPlatform, TaxonomyPlatformItem} from "../taxonomy/TaxonomyPlatform";

export class Category extends ApiModel {
    id: number = 0;
    id_external: number = 0;
    name: string = "";
    description: string = "";
    taxonomy: Taxonomy = Taxonomy.SIRDATA;
    price: number = 0;
    price_gbp: number = 0;
    price_usd: number = 0;
    urls_count: number = 0;
    urls: string[] = [];
    keywords: string[] = [];
    links: MapLink[] = [];
    path_element: TaxonomyPathItem = {name: "", link: ""};
    top_path_element?: TaxonomyPathItem;
    parent_path_element?: TaxonomyPathItem;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getPrice(currency: Currency): number {
        if (currency === Currency.EUR) {
            return this.price;
        } else if (currency === Currency.USD) {
            return this.price_usd;
        } else if (currency === Currency.GBP) {
            return this.price_gbp;
        }
        return 0;
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathContextualCategory, this.id);
    }

    toTaxonomyElement(): TaxonomyElement {
        const element = new TaxonomyElement();
        element.type = TaxonomyElementType.CATEGORY;
        element.audience_category = this;
        return element;
    }

    getPlatformId(platform: TaxonomyPlatformItem): string {
        switch (platform.value) {
            case TaxonomyPlatform.SIRDATA_API:
                return this.id.toString();
            case TaxonomyPlatform.SIRDATA_ADS:
                return "";
            default:
                const link = this.links.find((it) => it.platform === platform.value);
                if (link) {
                    return link ? link.external_id : this.id.toString();
                } else {
                    return "";
                }
        }
    }
}
