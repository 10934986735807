import {IconProps} from "@sirdata/ui-lib";

export enum CuratedDealRecommendationFormatType {
    BANNER = "banner",
    NATIVE = "native",
    VIDEO = "video"
}

export function getIconForCuratedDealRecommendationFormatType(type: CuratedDealRecommendationFormatType): IconProps {
    if (type === CuratedDealRecommendationFormatType.BANNER) {
        return {name: "desktop_mac", outlined: true};
    } else if (type === CuratedDealRecommendationFormatType.NATIVE) {
        return {name: "newspaper"};
    } else if (type === CuratedDealRecommendationFormatType.VIDEO) {
        return {name: "live_tv", outlined: true};
    }
    return {name: ""};
}
