export enum Currency {
    EUR = "price",
    GBP = "price_gbp",
    USD = "price_usd"
}

export type CurrencyItem = {
    currency: Currency;
    name: string;
    symbol: string;
}

export const CURRENCIES: CurrencyItem[] = [
    {currency: Currency.EUR, name: "eur", symbol: "€"},
    {currency: Currency.GBP, name: "gbp", symbol: "£"},
    {currency: Currency.USD, name: "usd", symbol: "$"}
];

export const defaultCurrencyItem = CURRENCIES[0];

export function getCurrencyItemFromName(name: string): CurrencyItem {
    return CURRENCIES.find((it) => it.name === name) || defaultCurrencyItem;
}
