import {FunctionComponent, useEffect, useState} from "react";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, FormLayoutButtons, FormLayoutMention, FormLayoutRows, FormLayoutTitle, InputText, ModalContent, ModalNew} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {CustomCategory} from "../../api/model/category/custom/CustomCategory";

type ModalEditCustomCategoryProps = {
    category: CustomCategory | undefined;
    onClose: () => void;
    onSubmit: (category: CustomCategory) => void;
}

type CustomCategoryFields = {
    name: string;
    description: string;
};

const ModalEditCustomCategory: FunctionComponent<ModalEditCustomCategoryProps> = ({category, onClose, onSubmit}) => {
    const {t: textConfig} = useTranslation(TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION);
    const [isLoading, setLoading] = useState(true);
    const [fields, setFields] = useState<CustomCategoryFields>({name: "", description: ""});

    const hasEmptyField = () => !fields.name || !fields.description;

    const handleSubmit = () => {
        let newCustomCategory = new CustomCategory();
        newCustomCategory.name = fields.name;
        newCustomCategory.description = fields.description;
        onSubmit(newCustomCategory);
    };

    useEffect(() => {
        (async function () {
            try {
                if (category) {
                    setFields({
                        name: category.name,
                        description: category.description
                    });
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [category]);

    return (
        <ModalNew onClose={onClose} active={!!category} loading={isLoading}>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutTitle>
                        {textConfig("information.title_edit")}
                    </FormLayoutTitle>
                    <FieldBlock label={textConfig("information.category_name")} required>
                        <InputText
                            value={fields.name}
                            placeholder={textConfig("information.category_name")}
                            onChange={(value) => setFields((prevState) => ({...prevState, name: value}))}
                            autoFocus
                        />
                    </FieldBlock>
                    <FieldBlock label={textConfig("information.description")} required>
                        <InputText
                            value={fields.description}
                            placeholder={textConfig("information.description")}
                            onChange={(value) => setFields((prevState) => ({...prevState, description: value}))}
                        />
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <ButtonValidate onClick={handleSubmit} disabled={hasEmptyField()}/>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalEditCustomCategory;
