import {Formatter} from "../../../common/utils/Formatter";

export enum CategoryStepSize {
    STEP_500K,
    STEP_400K,
    STEP_300K,
    STEP_200K,
    STEP_100K,
    STEP_50K,
    STEP_0
}

export type CategoryStepSizeItem = {
    value: CategoryStepSize;
    label: string;
    minSize: number;
}

export const CATEGORY_STEP_SIZES: CategoryStepSizeItem[] = [
    {value: CategoryStepSize.STEP_0, label: "< 50K", minSize: 0},
    {value: CategoryStepSize.STEP_50K, label: "> 50K", minSize: 50000},
    {value: CategoryStepSize.STEP_100K, label: "> 100K", minSize: 100000},
    {value: CategoryStepSize.STEP_200K, label: "> 200K", minSize: 200000},
    {value: CategoryStepSize.STEP_300K, label: "> 300K", minSize: 300000},
    {value: CategoryStepSize.STEP_400K, label: "> 400K", minSize: 400000},
    {value: CategoryStepSize.STEP_500K, label: "> 500K", minSize: 500000}
];

export function getCategoryStepFromValue(value: number): string {
    if (!value) return "-";
    const stepSize = CATEGORY_STEP_SIZES.filter((step) => value > step.minSize)?.pop();
    return stepSize?.label || Formatter.formatNumber(value);
}
