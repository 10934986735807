export enum TaxonomyView {
    LIST = "list",
    TREE = "tree",
}

export type TaxonomyViewItem = {
    value: TaxonomyView;
    icon: string;
}

export const TAXONOMY_VIEWS: TaxonomyViewItem[] = [
    {value: TaxonomyView.LIST, icon: "reorder"},
    {value: TaxonomyView.TREE, icon: "account_tree"}
];

export const defaultTaxonomyViewItem = TAXONOMY_VIEWS[0];

export function getTaxonomyViewItem(view: TaxonomyView | string): TaxonomyViewItem{
    return TAXONOMY_VIEWS.find(((it) => it.value === view)) || defaultTaxonomyViewItem;
}
