import Hash from "../../utils/Hash";
import {ApiModel} from "../../common/api/model/ApiModel";
import {pathFavoritesFolder, pathFavoritesFolderPublic} from "../../utils/constants";
import {TaxonomyElement} from "./taxonomy/TaxonomyElement";
import {Segment} from "./segment/Segment";
import {PathHelper} from "../../common/utils/PathHelper";

export class FolderFavorites extends ApiModel {
    id: number = 0;
    id_partner_account: number = 0;
    name: string = "My favorites";
    elements: TaxonomyElement[] = [];
    created_at: string = "";
    public: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;
        for (const [key, value] of Object.entries(o)) {
            switch (key) {
                case "elements":
                    if (!value) break;
                    let elements: TaxonomyElement[] = [];
                    (value as object[]).forEach((x: object) => {
                        const element = new TaxonomyElement(x);
                        elements.push(element);
                    });
                    this.elements = elements;
                    break;
                default:
                    this[key] = value;
            }
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            id_partner_account: this.id_partner_account,
            name: this.name,
            elements: this.elements,
            public: this.public
        };
    }

    getRoute(): string {
        return PathHelper.buildPathWithId(pathFavoritesFolder, this.id);
    }

    getShortRoute(): string {
        const hash = Hash.encode([this.id, this.id_partner_account]);
        return PathHelper.buildPathWithId(pathFavoritesFolderPublic, hash);
    }

    hasElement(element: TaxonomyElement): boolean {
        return !!this.elements.find((it) => it.elementId === element.elementId);
    }

    getSegments(): Segment[] {
        return this.elements.reduce((acc: Segment[], element) => {
            const segment = element.audience_segment;
            if (segment) acc.push(segment);
            return acc;
        }, []);
    }
}
