import {FunctionComponent, useEffect, useState} from "react";
import {Action, Button, ButtonSize, ButtonStyle, FieldBlock, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, IconTooltip, InputText, Select, TagSize, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {CampaignBrief} from "../../../api/model/campaign-brief/CampaignBrief";
import {CAMPAIGN_BRIEF_PLATFORMS} from "../../../api/model/campaign-brief/CampaignBriefPlatform";
import {Chip, ChipsInputSeatId, IconButtonClearAll} from "../../snippet";
import {EventCampaignBriefArgs} from "./ModalCampaignBrief";
import {TranslationPortalFile} from "../../../utils/constants";
import clsx from "clsx";

type ModalCampaignBriefPlatformProps = {
    campaignBrief: CampaignBrief;
    setCampaignBrief: (campaign: CampaignBrief) => void;
    campaignBriefArgs?: EventCampaignBriefArgs;
}

const OTHER_PLATFORM_VALUE = "other";

const ModalCampaignBriefPlatform: FunctionComponent<ModalCampaignBriefPlatformProps> = ({campaignBrief, setCampaignBrief, campaignBriefArgs}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);
    const [customPlatform, setCustomPlatform] = useState<string>("");
    const [platformSelection, setPlatformSelection] = useState<string[]>([]);
    const [isShowCustomPlatform, setShowCustomPlatform] = useState<boolean>(false);

    useEffect(() => {
        setPlatformSelection(Array.from(new Set(campaignBrief.distributions.map((it) => it.platform))));
    }, [campaignBrief.distributions]);

    useEffect(() => {
        if (campaignBriefArgs?.platformName) {
            setPlatformSelection([campaignBriefArgs.platformName]);
        }
    }, [campaignBriefArgs]);

    const handlePlatformSelection = (value: string) => {
        if (value === OTHER_PLATFORM_VALUE) {
            setShowCustomPlatform(true);
        } else {
            setPlatformSelection([...platformSelection, value]);
        }
    };

    const handleAddSeatId = (platform: string, seat_id: string) => {
        if (!campaignBrief.isDistributionExist({platform, seat_id})) {
            let newCampaign = new CampaignBrief();
            newCampaign.load(campaignBrief);
            newCampaign.distributions.push({platform, seat_id});
            setCampaignBrief(newCampaign);
        }
    };

    const handleRemoveSeatId = (platform: string, seat_id: string) => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);
        newCampaign.removeDistribution({platform, seat_id});
        setCampaignBrief(newCampaign);
    };

    const handleRemovePlatform = (platform: string) => {
        if (campaignBrief.distributions.find((it) => it.platform === platform)) {
            let newCampaign = new CampaignBrief();
            newCampaign.load(campaignBrief);
            newCampaign.distributions = campaignBrief.distributions.filter((it) => it.platform !== platform);
            setCampaignBrief(newCampaign);
        } else {
            setPlatformSelection(platformSelection.filter((it) => it !== platform));
        }
    };

    const handleClearPlatforms = () => {
        let newCampaign = new CampaignBrief();
        newCampaign.load(campaignBrief);
        newCampaign.distributions = [];
        setCampaignBrief(newCampaign);
    };

    const submitCustomPlatform = () => {
        const cleanPlatforms = Array.from(new Set([...platformSelection, customPlatform]));
        setPlatformSelection(cleanPlatforms);
        setShowCustomPlatform(false);
        setCustomPlatform("");
    };

    const getSeatIds = (platform: string) => {
        const platformSeatIds = campaignBrief.distributions.filter((it) => it.platform === platform);
        return platformSeatIds.map(({seat_id}) =>
            <Chip
                key={platform + "-" + seat_id}
                text={`ID: ${seat_id}`}
                onRemove={() => handleRemoveSeatId(platform, seat_id)}
            />);
    };

    return (
        <FormLayoutRows>
            <FormLayoutColumns columns={2}>
                <FieldBlock content={{spacing: FlexContentSpacing.SMALL}}>
                    <Select
                        value={""}
                        options={[
                            {label: textCampaignBrief("form.placeholder.select_platform"), value: ""},
                            ...CAMPAIGN_BRIEF_PLATFORMS,
                            {label: textCampaignBrief(`form.select.${OTHER_PLATFORM_VALUE}`), value: OTHER_PLATFORM_VALUE}
                        ].filter((platform) => !platformSelection.includes(platform.value))}
                        onChange={(option) => handlePlatformSelection(option!.value as string)}
                        hideSelectedOptions
                    />
                    {!!platformSelection.length && <IconButtonClearAll onClick={handleClearPlatforms}/>}
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                {platformSelection.map((platform) =>
                    <div key={platform} className="modal-platform-selection">
                        <span className={clsx("tag", TagSize.SMALL, "modal-platform-selection__tag-platform")}>
                            <span>{platform}</span>
                            <IconTooltip
                                icon={Action.REMOVE.icon}
                                text={textCommon(Action.REMOVE.labelKey)}
                                onClick={() => handleRemovePlatform(platform)}
                                cssClass="modal-platform-selection__btn-delete"
                            />
                        </span>
                        <div className="modal-platform-selection__chips">
                            {getSeatIds(platform)}
                            <ChipsInputSeatId platform={platform} addSeatId={(seatId) => handleAddSeatId(platform, seatId)} placeholder={CAMPAIGN_BRIEF_PLATFORMS.find((it) => it.value === platform)?.placeholder || ""}/>
                        </div>
                    </div>
                )}
                {isShowCustomPlatform &&
                    <FieldBlock content={{spacing: FlexContentSpacing.SMALL}}>
                        <InputText
                            value={customPlatform}
                            placeholder={textCampaignBrief("form.placeholder.custom_platform")}
                            onChange={setCustomPlatform}
                            autoFocus={isShowCustomPlatform}
                        />
                        <Button
                            disabled={!customPlatform}
                            onClick={submitCustomPlatform}
                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                            size={ButtonSize.SMALL}
                        >
                            {textCampaignBrief("actions.create")}
                        </Button>
                    </FieldBlock>
                }
            </FormLayoutRows>
        </FormLayoutRows>
    );
};

export default ModalCampaignBriefPlatform;
