import {FunctionComponent, memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Alert,
    AlertSeverity,
    Button,
    ButtonBack,
    ButtonNext,
    FormLayoutRows,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    ModalHeader,
    ModalHeaderDirection,
    ModalHeaderTitle,
    ModalNew,
    ModalStepper
} from "@sirdata/ui-lib";
import {CampaignBrief} from "../../../api/model/campaign-brief/CampaignBrief";
import {CAMPAIGN_BRIEF_STEPS, CampaignBriefStep} from "./ModalCampaignBriefSteps";
import {Segment} from "../../../api/model/segment/Segment";
import {TARGETING_FORM_ID} from "./ModalCampaignBriefTargeting";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {session} from "../../../api/ApiSession";

type ModalCampaignBriefProps = {
    onClose?: () => void;
}

export type EventCampaignBriefArgs = {
    platformName: string;
    segment: Segment;
}

const ModalCampaignBrief: FunctionComponent<ModalCampaignBriefProps> = ({onClose}) => {
    const {t: textCampaignBrief} = useTranslation(TranslationPortalFile.CAMPAIGN_BRIEF);
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [campaignBrief, setCampaignBrief] = useState<CampaignBrief>(new CampaignBrief());
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isActive, setActive] = useState<boolean>(false);
    const [hasPreselection, setPreselection] = useState<EventCampaignBriefArgs>();
    const [isShowError, setShowError] = useState<boolean>(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const account = await session.getAccount();
            campaignBrief.partner_id = account.partner_id;
            await session.restCampaignBrief.sendCampaignBriefRequest(campaignBrief);
            handleGoToNextStep();
        } catch (e) {
            setShowError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setCampaignBrief(new CampaignBrief());
        setCurrentStepIndex(0);
        setPreselection(undefined);
        setActive(false);
        onClose && onClose();
    };

    const handleGoToNextStep = () => {
        setCurrentStepIndex((prev) => prev + 1);
        if (hasPreselection) setPreselection(undefined);
    };

    const handleGoToPrevStep = () => setCurrentStepIndex((prev) => prev - 1);

    useEffect(() => {
        UIEventManager.addListener(ModalCampaignBriefUiEvent, (args: EventCampaignBriefArgs) => {
            setActive(true);
            if (args) {
                campaignBrief.segments = [args.segment.id];
                setCampaignBrief(campaignBrief);
                setPreselection(args);
            }
        });

        return () => {
            UIEventManager.removeAllListeners(ModalCampaignBriefUiEvent);
        };
    }, [campaignBrief]);

    return (
        <ModalNew onClose={handleClose} active={isActive} loading={isLoading} keepMounted>
            {CAMPAIGN_BRIEF_STEPS[currentStepIndex].showStep &&
                <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                    <ModalStepper activeStep={currentStepIndex} steps={CAMPAIGN_BRIEF_STEPS.filter((it) => it.showStep).length}/>
                    <ModalHeaderTitle title={textCampaignBrief(`title.${CAMPAIGN_BRIEF_STEPS[currentStepIndex].step}`)}/>
                </ModalHeader>
            }
            <ModalContent>
                <FormLayoutRows>
                    {(hasPreselection && currentStepIndex === 0) &&
                        <Alert
                            severity={AlertSeverity.INFO}
                            text={textCampaignBrief("has_preselection", {
                                segmentName: hasPreselection.segment.name,
                                segmentId: hasPreselection.segment.id,
                                platformName: hasPreselection.platformName
                            })}
                        />
                    }
                    {CAMPAIGN_BRIEF_STEPS.map(({step, component: StepComponent}, index) =>
                        <div key={step} style={{display: `${index === currentStepIndex ? "block" : "none"}`}}>
                            <StepComponent
                                campaignBrief={campaignBrief}
                                setCampaignBrief={setCampaignBrief}
                                campaignBriefArgs={hasPreselection}
                                onGoToNext={handleGoToNextStep}
                                onClose={handleClose}
                            />
                        </div>
                    )}
                    {isShowError &&
                        <FormLayoutMessage message={textCampaignBrief("form.request_error")} severity={AlertSeverity.DANGER}/>
                    }
                </FormLayoutRows>
            </ModalContent>
            {CAMPAIGN_BRIEF_STEPS[currentStepIndex].step === CampaignBriefStep.TARGETING &&
                <ModalActions alignment={ModalActionsAlignment.RIGHT}>
                    <ButtonNext disabled={!campaignBrief.start_date || !campaignBrief.format.length} form={TARGETING_FORM_ID} noIcon/>
                </ModalActions>
            }
            {CAMPAIGN_BRIEF_STEPS[currentStepIndex].step === CampaignBriefStep.SEGMENT_SELECTION &&
                <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                    <ButtonBack onClick={handleGoToPrevStep} noIcon/>
                    <ButtonNext disabled={!campaignBrief.comment && !campaignBrief.segments.length} onClick={handleGoToNextStep} noIcon/>
                </ModalActions>
            }
            {CAMPAIGN_BRIEF_STEPS[currentStepIndex].step === CampaignBriefStep.DSP_SELECTION &&
                <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                    <ButtonBack onClick={handleGoToPrevStep} noIcon/>
                    <Button disabled={!campaignBrief.distributions.length} onClick={handleSubmit}>
                        {textCampaignBrief("actions.submit")}
                    </Button>
                </ModalActions>
            }
        </ModalNew>
    );
};

export default memo(ModalCampaignBrief);
export const ModalCampaignBriefUiEvent = "ModalCampaignBrief";
