export enum CampaignBriefPlatform {
    ADOBE = "Adobe",
    AMAZON = "Amazon",
    FACEBOOK = "Facebook",
    FREEWHEEL = "Freewheel",
    GOOGLE_DV360 = "Google DV360/GAM",
    MAGNITE = "Magnite",
    PROXISTORE = "Proxistore",
    PUBMATIC = "PubMatic",
    SIRDATA = "Sirdata API",
    EQUATIV = "Equativ",
    THE_TRADE_DESK = "TheTradeDesk",
    XANDR = "Xandr DSP/SSP",
    YAHOO = "Yahoo"
}

export type CampaignBriefPlatformItem = {
    label: CampaignBriefPlatform;
    value: CampaignBriefPlatform;
    placeholder: string;
}

export const CAMPAIGN_BRIEF_PLATFORMS: CampaignBriefPlatformItem[] = [
    {label: CampaignBriefPlatform.SIRDATA, value: CampaignBriefPlatform.SIRDATA, placeholder: "Partner ID"},
    {label: CampaignBriefPlatform.ADOBE, value: CampaignBriefPlatform.ADOBE, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.AMAZON, value: CampaignBriefPlatform.AMAZON, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.FACEBOOK, value: CampaignBriefPlatform.FACEBOOK, placeholder: "Ad Account ID"},
    {label: CampaignBriefPlatform.FREEWHEEL, value: CampaignBriefPlatform.FREEWHEEL, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.GOOGLE_DV360, value: CampaignBriefPlatform.GOOGLE_DV360, placeholder: "Seat ID/Audience Link ID"},
    {label: CampaignBriefPlatform.MAGNITE, value: CampaignBriefPlatform.MAGNITE, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.PROXISTORE, value: CampaignBriefPlatform.PROXISTORE, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.PUBMATIC, value: CampaignBriefPlatform.PUBMATIC, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.EQUATIV, value: CampaignBriefPlatform.EQUATIV, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.THE_TRADE_DESK, value: CampaignBriefPlatform.THE_TRADE_DESK, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.XANDR, value: CampaignBriefPlatform.XANDR, placeholder: "Seat ID"},
    {label: CampaignBriefPlatform.YAHOO, value: CampaignBriefPlatform.YAHOO, placeholder: "Seat ID"}
];
